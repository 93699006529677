<template>
  <v-dialog v-model="showDialog" persistent max-width="400">
    <v-card>
      <v-card-title>{{ $t('generic.lang_posCashDrawerOpen') }}</v-card-title>

      <v-card-text class="text-center" v-text="$t('generic.lang_posCashDrawerOpenText')"></v-card-text>

      <v-card-actions>
        <v-btn color="error" @click="close" :disabled="loading" text>{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="open" :disabled="loading" :loading="loading">{{
            $t('generic.lang_opencashier')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import {Events} from "@/plugins/events";
import {ENDPOINTS} from "../../../config";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";

export default {
  name: "ButtonsOpenCashDrawerDialog",

  props: {
    showDialog: Boolean
  },

  data() {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState([
      'pos',
      'api',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },
    open() {
      this.loading = true;

      let printData = [{
        cashDrawer: 1
      }];

      printDataFromPrinter(this.invoicePrinter,printData).then(()=>{
        Events.$emit("showSnackbar", {
          message: this.$t("generic.lang_success"),
          color: "success"
        });
      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{
        this.loading = false;
        this.close();
      })

    }
  }
}
</script>