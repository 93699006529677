<template>

  <div>
    <v-list-item
        v-touch="{
      left: () => selectItemSwiper('left'),
      right: () => selectItemSwiper('right'),
    }"
        class="border-bottom pl-1 pr-1"
        style="height: 58px"
        v-bind:style="{
    'background-color': (item === pos.gastro.selectedOpenItem ? 'rgba(63,106,216,0.2)' : (itemIndex % 2 === 1 ? '' : ''))
  }"

    >

      <v-list-item-content
          class="ml-2"
          @click="selectItem">
        <v-list-item-title

            class="font-weight-bolder text-muted" v-text="this.itemName">
        </v-list-item-title>
        <v-list-item-subtitle>
          <span v-if="this.depositGroup !== null" class="text-wrap">{{ this.depositGroup.name }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-icon class="ma-0 pa-0"
      >

        <button v-if="item.freeText !== null" class="text-light pl-0 pr-0 ma-auto mx-auto btn bg-success btn-square"
                style="width: 30px !important; height: 58px; margin-right: 2px !important;"
                @click="show_freeText_dialog">
          <font-awesome-icon :icon="['fal', 'comment-alt-lines']" class="text-light font-size-xlg"
          />
        </button>
        <button v-if="item.course!==null && item.course!==undefined"
                class="text-light font-size-xlg pl-0 pr-0 ma-auto mx-auto"
                style="width: 30px !important; height: 58px; margin-right: 2px !important;" v-bind:style="{
        'background-color' : this.colors.primary
      }"
                @click="openCourseDialog"
        >
          {{ item.course.position }}
        </button>

        <button v-if="itemHasExtras" class="text-light bg-warning pl-0 pr-0 ma-auto mx-auto"
                style="width: 30px !important; height: 58px"
                @click="showExtrasDialog = true">
          <font-awesome-icon :icon="['fal', 'pepper-hot']" class="text-light font-size-xlg"
          />
        </button>
      </v-list-item-icon>

      <v-list-item-action class="text-center px-0 pa-0 pl-0 ml-2" style="max-width: 80px !important;">
        <v-row align="center" class="ma-0 mx-0" no-gutters>
          <v-col class="text-center" cols="12" md="12" sm="12">
            <strong class="" v-bind:style="{
        color: colors.primary
      }">{{ this.itemSellPrice | currency }}</strong> <strong v-if="this.item.discount > 0"
                                                              class="success--text">({{ this.item.discount }}%)</strong>
          </v-col>
          <br>


          <v-col class="text-left" cols="4">
            <v-btn class="ma-auto pa-1 px-1 bg-grey qty_controls" color="#D3D3D3" fab outlined x-small @click="minus">
              <font-awesome-icon :icon="['fal', 'minus']" class="text-grey"
                                 style="font-size: 12px; color: grey !important;"/>
            </v-btn>
          </v-col>

          <v-col class="text-center" cols="4" style="min-width: 20px !important;">
          <span class="text-muted mx-auto ma-auto">
            {{ item.amount }}
          </span>
          </v-col>

          <v-col class="text-right" cols="4">
            <v-btn class="ma-auto pa-1 px-1 bg-grey qty_controls" color="#D3D3D3" fab outlined x-small @click="plus">
              <font-awesome-icon :icon="['fal', 'plus']" class="text-grey"
                                 style="font-size: 12px; color: grey !important;"/>
            </v-btn>
          </v-col>
        </v-row>
      </v-list-item-action>
      <mobile-course-dialog :pos-type="this.posType" :show-dialog="showCourseDialog" @closeDialog="closeDialog"/>
      <item-edit-extra-dialog
          v-if="item !== null&&showExtrasDialog" :item="item"
          :item-group="itemGroup" :meal-size="mealSize" :show-dialog="showExtrasDialog"
          @closeDialog="showExtrasDialog=false" @selectExtras="selectExtras"
      />

      <ButtonsFreetextDialog :item="selectedItem" :pos-type="posType" :show-dialog="showDialog"
                             @closeDialog="showDialog = false"/>

    </v-list-item>
    <template
        v-if="selectedItem!==null && item.selectedExtras.length > 0&&selectedItem.randomPositionID===item.randomPositionID">
      <v-list-item
          :style="{minHeight:'30px', 'background-color': (item === pos.gastro.selectedOpenItem ? 'rgba(63,106,216,0.2)' : (itemIndex % 2 === 1 ? '#ededed' : ''))}"
          class="pa-0"
      >
        <v-list-item-content class="pa-0">
          <v-row align-self="start" class="ma-0 mx-0" justify="start" no-gutters>
            <v-col cols="12">
              <div v-if="true">
                <div v-for="(extra,i) in item.selectedExtras" :key="extra.name+i" class="text-wrap"
                     style="font-size: 12px ">
                  &#9656; {{ extra.amount }}x {{ extra.name }} {{ extra.sellPrice | currency }}
                </div>
              </div>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>

</template>

<script>
import {mapGetters, mapState} from "vuex";
import ItemEditExtraDialog from "@/components/pos/item/ItemEditExtraDialog";
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import {checkColorLightOrDark} from "@/plugins/checkColorLightOrDark";

import ButtonsFreetextDialog from "@/components/pos/buttons/ButtonsFreetextDialog";


import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {
  faAngleDoubleDown,
  faAngleDoubleUp,
  faChevronLeft,
  faCommentAltLines,
  faExchange,
  faMinus,
  faPlus,
  faShoppingBag,
  faShoppingCart
} from '@fortawesome/pro-light-svg-icons'

import {library} from '@fortawesome/fontawesome-svg-core';
import MobileCourseDialog from "@/components/pos/mobile/wedgets/MobileCourseDialog";

library.add(
    faChevronLeft, faAngleDoubleUp, faAngleDoubleDown, faShoppingBag, faShoppingCart, faExchange, faPlus, faMinus, faCommentAltLines
);
export default {
  name: "MobileInvoiceOpenItem",
  components: {
    MobileCourseDialog,
    ItemEditExtraDialog,
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsFreetextDialog
  },
  props: {
    item: {
      type: Object,
      default: undefined
    },
    itemId: {
      type: Number,
      require: true
    },
    itemIndex: Number,
    posType: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      require: true
    }
  },
  async beforeCreate() {

    this.$nextTick(async () => {
      this.baseItem = await this.$store.dispatch("items/getItemByID", this.itemId);
      this.itemGroup = await this.$store.dispatch("itemgroups/getItemgroupByID", this.item.itemgroupID);
    })


  },
  async mounted() {
    this.imageUrl = "https://img.3pos.de/unsafe/" + Math.round(this.gridWidth) + "x" + Math.round(this.gridHeight) + "/billsys_" + this.api.auth.posID + "/original/" + this.item.productImage;
    this.$forceUpdate();
  },

  data() {
    return {
      showExtras: false,
      showExtrasDialog: false,
      imageUrl: "",
      showCourseDialog: false,
      showDialog: false,
      baseItem: null,
      itemGroup: null
    }
  },

  computed: {
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
    }),
    openItems() {
      if (this.posType === "gastro") {
        return this.gastroOpenItems;
      }

      return [];
    },
    backgroundColor: function () {
      if (this.item.backgroundColor === "") {
        return "#FFFFFF";
      }

      return this.item.backgroundColor;
    },
    depositGroup() {
      if (this.item.depositgroupID === 0)
        return null;

      //FIND DEPOSIT GROUP
      let depositGroup = this.deposit.deposits.find((depositGroup) => {
        return depositGroup.id === this.item.depositgroupID;
      });

      if (!depositGroup)
        return null;

      return depositGroup;
    },
    ...mapState([
      'pos',
      'api',
      'mealsizes',
      'deposit',
      'extras'
    ]),
    color: {
      get() {
        return this.colors
      }
    },
    mealSize() {
      if (this.baseItem === null)
        return null;

      if (!this.baseItem)
        return null;

      let mealSize = this.baseItem.mealSizes.find(size => this.item.mealSizeID === size.id);

      if (!mealSize)
        return null;

      return mealSize;
    },
    itemName() {
      if (this.posType === "retail") {
        if (this.item.isVariation) {
          return this.item.name + " " + this.item.variationSize + " " + this.item.variationColor;
        }
      }

      //CHECK IF ITEM HAS MEALSIZE ID
      if (this.item.mealSizeID > 0) {
        //FIND MEALSIZE
        let mealsize = this.mealsizes.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

        if (mealsize)
          return this.item.name + " " + mealsize.name;
      }

      return this.item.name;
    },
    itemSellPrice() {
      let sellPrice = this.item.sellPrice;

      //CHECK MEALSIZE PRICE
      if (this.item.mealSizeID > 0) {
        //GET ITEM
        let item = this.baseItem;

        if (item) {
          //THEN GET MEALSIZE FROM REAL ITEM(VUEX STORE)
          let itemMealsize = item.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

          if (itemMealsize)
            return itemMealsize.price;
        }
      }
      //CHECK DEPOSIT
      if (this.depositGroup !== null) {
        sellPrice += this.depositGroup.priceTotal;
      }
      return sellPrice;
    },
    selectedItem() {
      if (this.posType === "retail") {
        return this.pos.retail.selectedItem;
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    },
    itemHasExtras() {
      if (this.baseItem === null || this.baseItem === undefined)
        return false;

      if (this.item.randomPositionID.includes('$deliveryCost'))
        return false;

      let availableExtras = [];

      if (this.posType === "gastro" && this.itemGroup && this.itemGroup.extras) {
        if (Object.keys(this.itemGroup.extras).length > 0 && this.itemGroup.extras.constructor === Object) {
          availableExtras = this.itemGroup.extras;
        }
      }

      if (this.posType === "gastro" && this.baseItem.extras && !this.baseItem.ean.includes("V$") && !this.baseItem.ean.includes("GF$") && !this.baseItem.ean.includes("ZMYLE$") && !this.baseItem.ean.includes("MYWORLD$")) {
        if (Object.keys(this.baseItem.extras).length > 0 && this.baseItem.extras.constructor === Object) {
          availableExtras = this.baseItem.extras;
        }
      }

      if (this.posType === "gastro" && (Object.keys(availableExtras).length > 0 && availableExtras.constructor === Object) && !this.selectedExtras) {
        //CHECK

        let counter = 0;

        //LOOP GARNISHTABS
        for (let tabID in availableExtras) {
          if (!availableExtras.hasOwnProperty(tabID)) {
            return false;
          }

          //LOOP GARNISH ITEMS
          for (let garnishID in availableExtras[tabID]) {
            if (!availableExtras[tabID].hasOwnProperty(garnishID)) {
              return false;
            }
            let extra = Object.assign({}, this.extras.extras.find(item => item.id === parseInt(garnishID)));

            if (Object.keys(extra).length < 1) {

              break;
            }
            //LOOP GARNISH SIZES
            for (let garnishSizeID in availableExtras[tabID][garnishID]) {
              if (!availableExtras[tabID][garnishID].hasOwnProperty(garnishSizeID)) {
                return false;
              }
              if (parseInt(availableExtras[tabID][garnishID][garnishSizeID][1]) < 1) {

                break;
              }

              //CHECK
              if (this.mealSize !== null) {
                if (this.mealSize.id > 0 && parseInt(garnishSizeID) === this.mealSize.id) {
                  counter++;
                } else if (this.mealSize.id === 0 && parseInt(garnishSizeID) === 0) {
                  counter++;
                }
              } else {
                if (parseInt(garnishSizeID) === 0) {
                  counter++;
                }
              }
            }
          }
        }

        if (counter > 0) {
          return true
        }
      }
      return false;
    }
  },
  methods: {
    show_freeText_dialog() {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
      this.showDialog = true;
    },
    openCourseDialog() {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
      this.showCourseDialog = true;
    },
    closeDialog() {
      this.showCourseDialog = false;
    },
    plus() {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.selectItem();


      if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/numpadQTY", (this.item.amount + 1).toString());
      }

      if (this.posType === "gastro") {
        this.$store.dispatch("pos/gastro/numpadQTY", (this.item.amount + 1).toString());
      }
    },
    minus() {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.selectItem();


      if (this.posType === "retail" && (this.item.amount - 1) > 0) {
        this.$store.dispatch("pos/retail/numpadQTY", (this.item.amount - 1).toString());
      }

      if (this.posType === "gastro" && (this.item.amount - 1) > 0) {
        this.$store.dispatch("pos/gastro/numpadQTY", (this.item.amount - 1).toString());
      }
    },
    checkColorLightOrDark,
    selectItemSwiper(direction = '') {
      if (direction === 'right')
        this.$emit('closeLeftReveal', this.item)
      else if (direction === 'left')
        this.$emit('closeRightReveal', this.item)

      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
    },
    selectItem() {
      this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
    },
    selectExtras(extras) {
      let extrasTotal = 0;
      let newPrice = 0
      extras.forEach((extra) => {
        extrasTotal += (parseInt(extra.amount) * parseFloat(extra.sellPrice));
      });
      newPrice = extrasTotal + this.item.originalSellPrice;
      this.$store.dispatch("pos/gastro/setItemExtras", {
        index: this.itemIndex,
        extras: extras,
        newPrice: newPrice,
      })
      this.showExtrasDialog = false;
    },
    openEditExtrasDialog() {
      this.showExtrasDialog = true;
    }
  }
}
</script>


<style>
.qty_controls {
  background-color: lightgray;
  height: 20px !important;
  width: 20px !important;
}

</style>
