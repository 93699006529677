<template>
  <div>
    <v-btn v-if="this.$vuetify.breakpoint.smAndDown" class="ma-0 swipeout-action" small style="width: 20px !important"
           @click="showDialog = true">
      <span v-if="showButtonText">{{ $t('accounting.lang_ZAbschluss') }}</span>
      <span class="btnIconSize" style="font-size: 28px;">Z</span>
    </v-btn>
    <div v-else>
      <button class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-primary" @click="toggleDialog">
        <span class="btnIconSize" style="font-size: 28px;">Z</span><br>
        <span v-if="showButtonText">{{ $t('accounting.lang_nav_accounting_dailyab') }}</span>
      </button>
    </div>

    <EndShiftCounting :show-dialog="showDialog" @closeDialog="showDialog = false" dont-redirect/>
  </div>
</template>

<script>
import EndShiftCounting from "@/components/pos/EndShiftCounting";
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";

export default {
  name: "ButtonsPrintDailyReport",

  components: {
    EndShiftCounting,
  },

  props: {
    posType: String,
    showButtonText: Boolean
  },

  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    toggleDialog(){
      if(Number(this.$store.getters['settings/getSettingValue']('displayOpenTableWarning'))>0&&this.$store.getters['permissions/checkModule'](2)&&this.posType==='gastro'){
        this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then(res=>{
          if(res.data.success){
            if(res.data.tables && res.data.tables.length>0){
              this.$swal({
                title: this.$t('generic.lang_warning'),
                text: this.$t('generic.lang_dailyReportOpenTablesWarningMSG'),
                icon: "warning",
                showCancelButton: true,
                showLoaderOnConfirm: true,
                confirmButtonText: this.$t('generic.lang_next'),
                cancelButtonText: this.$t('generic.lang_cancel'),
                preConfirm: () => {
                  this.showDialog = true
                },
                allowOutsideClick: () => !this.$swal.isLoading,
              });
            }else{
              this.showDialog = true
            }
          }
        }).catch(err=>{
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
            color: "error"
          });
        })
      }else{
        this.showDialog = true
      }
    }
  }
}
</script>

<style scoped>
.btn-icon-vertical {
  padding: 7px !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
