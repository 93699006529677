<template>
  <v-tabs v-model="activePartyTab" height="74">
    <v-tab v-for="(party, index) in filteredPartyTabs" :key="index" ripple>
            <span v-if="party.name !== 'add'">
                {{ party.name }}
            </span>

      <v-icon v-else-if="!ordering">
        mdi-plus
      </v-icon>
    </v-tab>
    <PartiesAddDialog :pos-type="posType" :show-dialog="showPartyAddDialog"
                      @closeDialog="closeCreatePartyDialog"/>
  </v-tabs>
</template>

<script>
import {mapState} from "vuex";
import PartiesAddDialog from "./PartiesAddDialog";

export default {
  name: "Parties",

  components: {
    PartiesAddDialog
  },

  props: {
    posType: String,
    ordering: Boolean,
  },

  data() {
    return {
      showPartyAddDialog: false,
      activePartyTab: 0,
      lastActivePartyTab: null,
      activeParty: null
    }
  },

  watch: {
    activePartyTab: function () {
      if (this.activePartyTab < this.pos.gastro.table.parties.length) {
        if (this.activePartyTab !== this.lastActivePartyTab) {
          this.lastActivePartyTab = this.activePartyTab;
          this.changeParty();
        }
      } else {
        if (this.filteredPartyTabs[this.activePartyTab].name === "add") {
          this.showPartyAddDialog = true;
        }
      }
    },
    showPartyAddDialog(val) {
      if (!val) {
        //CHECK WHEN CLOSING ADD PARTY DIALOG -> CHANGE BACK TO PREVIOUS PARTY
        this.activePartyTab = this.lastActivePartyTab;
      }
    },
    gastroTableName() {
      this.activePartyTab = 0;
      this.lastActivePartyTab = 0;
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    parties() {
      if (this.pos.gastro.table === null) {
        return [];
      }
      return this.pos.gastro.table.parties;
    },
    filteredPartyTabs() {
      let tabs = this._.cloneDeep(this.parties);
      if (!this.pos.gastro.isDeliveryService && !(this.pos.gastro.table && this.pos.gastro.table.hasOwnProperty("name") && this.pos.gastro.table.name === 0)) {
        tabs.push({
          name: "add"
        });
      }
      return tabs;
    },
    gastroTableName() {
      return this.pos.gastro.table?.name||'';
    }
  },

  mounted() {
    this.$root.$on("mergedTableParties", () => {
      this.activePartyTab = 0;
    });
  },

  methods: {
    changeParty() {
      ////console.log(this.pos.gastro.table.parties[this.activePartyTab], this.activePartyTab)
      this.$store.dispatch("pos/gastro/changeParty", {
        posType: this.posType,
        party: this.pos.gastro.table.parties[this.activePartyTab]
      }).then(() => {
        this.$store.commit('pos/gastro/selectInvoiceOrderedItem', null)
      })
    },
    closeCreatePartyDialog(partyName) {
      this.showPartyAddDialog = false;

      if (partyName.length > 0) {
        this.$nextTick().then(() => {
          this.activePartyTab = this.filteredPartyTabs.findIndex((party) => {
            return party.name === partyName;
          });
        });
      } else {
        this.activePartyTab = this.lastActivePartyTab;
      }
    }
  }
}
</script>
