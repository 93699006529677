<template>
  <v-dialog max-width="700px" scrollable persistent v-model="showDialog">
    <v-card>
      <v-img
          height="70"
          src="@/assets/images/ibelsa_large.png"
          contain
          style="margin-top: 10px; margin-bottom: 15px;"
      ></v-img>

      <v-toolbar color="#424242" dark dense flat>
        <v-toolbar-title>ibelsa {{ $t('datatables.lang_search') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn @click="closeDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- Search Input -->
        <v-row>
          <v-col cols="12">

            <v-menu v-model="focus"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scroll-y-transition"
                    offset-y style="width:100%;"
                    bottom nudge-top="10"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                    v-model="searchInput"
                    v-on="on"
                    style="margin-top: 20px;"
                    outlined
                    dense
                    :data-layout="KEYBOARD.KEYSETS.NORMAL"
                    @blur="focus=false;"
                    :loading="loading"
                    aria-autocomplete="none"
                    @focus="showTouchKeyboard">
                </v-text-field>
              </template>
              <v-card max-height="300" width="100%" max-width="100%">
                <v-list>
                  <template v-if="rooms && rooms.length>0">
                    <v-list-item v-for="(room,i)  in rooms" :key="i" @click="selectSearchedRoom(room)"
                                 two-line>
                      <v-list-item-content>
                        <v-list-item-title>{{ room.guestname }}</v-list-item-title>
                        <v-list-item-subtitle>{{ room.arrival }} - {{ room.departure }}</v-list-item-subtitle>
                        <v-list-item-subtitle>{{$t('erp.lang_room')+' '}}{{ room.name }}</v-list-item-subtitle>
                      </v-list-item-content>

                    </v-list-item>
                  </template>

                  <template v-else>
                    <v-list-item>
                      <v-list-item-content>
                        {{ $t('generic.lang_noEntriesFound') }}
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                </v-list>
              </v-card>
            </v-menu>

          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text style="padding: 0;" v-if="selectedRoom !== null">
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <div v-else>
          <v-tabs background-color="#424242" dark centered>
            <v-tab>{{ $t('generic.lang_booking') }}</v-tab>

            <!-- Occupation Details -->
            <v-tab-item>
              <v-simple-table class="occupationTable" v-if="selectedRoom !== null">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>{{ $t('generic.lang_bookingNo') }}</td>
                    <td>{{ selectedRoom.id }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('accounting.lang_zimmer') }}</td>
                    <td>{{ selectedRoom.name }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_from') }}</td>
                    <td>{{ selectedRoom.arrival }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_to') }}</td>
                    <td>{{ selectedRoom.departure }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_guest') }}</td>
                    <td>{{ selectedRoom.guestname }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn @click="closeDialog" color="error" text>{{$t("generic.lang_cancel")}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading || selectedRoom === null" :loading="loading" color="success" @click="selectRoom">
          {{ $t('generic.lang_bookToRoom') }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard v-if="touchKeyboardIbelsa.visible" id="onScreenKeyboard"
                          :accept="hidetouchKeyboardIbelsa"
                          :cancel="hidetouchKeyboardIbelsa" :defaultKeySet="touchKeyboardIbelsa.keySet"
                          :input="touchKeyboardIbelsa.input" :layout="touchKeyboardIbelsa.layout"
                          :options="touchKeyboardIbelsa.options" class="internalWidthExpanded"/>
    </div>
  </v-dialog>
</template>

<style scoped>
.occupationTable tr td:first-child {
  font-weight: bold;
}
</style>

<script>
import {ENDPOINTS, KEYBOARD} from "@/config";
import mixin from "../../../mixins/KeyboardMixIns";
import {mapState} from "vuex";

export default {
  name: "ButtonsIbelsaSearchDialog",

  props: {
    showDialog: Boolean
  },
  mixins: [mixin],
  data() {
    return {
      loading: false,
      focus: false,
      searchInput: "",
      search: "",
      rooms: [],
      selectedRoom: null,
      awaitingSearch: null,
      KEYBOARD,
      touchKeyboardIbelsa: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
    }
  },
  computed:{
    ...mapState([
      'touchkeyboard',
    ]),
  },
  watch: {
    showDialog(val) {
      if (!val) {
        this.selectedRoom = null;
        this.rooms = [];
        this.loading = false;
        this.searchInput = "";
      }
    },
    searchInput(val) {
      if (val !== null) {
        if (val.length > 0) {
          this.loading = true;

          clearTimeout(this.awaitingSearch);

          this.awaitingSearch = setTimeout(() => {
            this.searchForRoom(val);
          }, 1000);
        }
      }
    },
  },

  methods: {
    selectSearchedRoom(room){
      this.selectedRoom=room
      this.focus = false;
    },
    showTouchKeyboard(e) {
      this.focus = true;
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardIbelsa.input = e.target;
      this.touchKeyboardIbelsa.layout = e.target.dataset.layout;

      if (!this.touchKeyboardIbelsa.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardIbelsa.visible = true;
        }, 150);
      }
    },
    hidetouchKeyboardIbelsa() {

      this.focus = false;
      if (this.touchKeyboardIbelsa.visible) {
        this.touchKeyboardIbelsa.visible = false;
      }
    },
    dismissOnScreenKeyboardIbelsa: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivIbelsa) {
        if (!this.$refs.onScreenKeyboardDivIbelsa.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hidetouchKeyboardIbelsa();
          }
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    searchForRoom(searchValue) {
      this.loading = true;

      this.axios.get(ENDPOINTS.IBELSA.SEARCH, {
        params: {
          search: searchValue
        }
      }).then((res) => {
        if (res.data.success) {
          this.rooms = res.data.rooms;
        }
        this.focus = true;
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      })
    },
    selectRoom() {
      this.$emit("roomSelected", this.selectedRoom);
    }
  },
  mounted() {
    document.addEventListener('click', this.dismissOnScreenKeyboardIbelsa);
  },
  destroyed() {
    document.removeEventListener('click', this.dismissOnScreenKeyboardIbelsa);
  }
}
</script>
