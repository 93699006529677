<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        color="#424242"
        dark
        dense
        flat
      >
        <v-toolbar-title>{{ $t('erp.lang_pos_weightInput') }}</v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="cancelDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-layout>
          <v-flex md12>
            <v-text-field
              autofocus
              v-model="weight"
              :label="$t('erp.lang_pos_weightInput')"
              outlined
              prepend-icon="label"
              @keypress="inputTyped"
              style="margin-top: 30px"
              suffix="kg"
              value="0"
              @keyup.enter="acceptWeight"
            />

            <NumpadOnly
              ref="numpad"
              @resetField="numpadResetField"
              @updateValue="numpadUpdateValue"
            ></NumpadOnly>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="cancelDialog"
          >{{ $t('generic.lang_cancel') }}</v-btn
        >
        <v-spacer />
        <v-btn
          :disabled="!this.canAcceptWeight"
          color="success"
          @click="acceptWeight"
        >
          {{ $t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import NumpadOnly from '../NumpadOnly';

export default {
  name: 'ItemWeightInputDialog',

  components: {
    NumpadOnly,
  },

  props: {
    showDialog: Boolean,
  },

  data() {
    return {
      weight: 0,
    };
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.$refs.numpad.resetField();
      }else{
        this.weight = '0.000';
      }
    },
  },
  computed: {
    canAcceptWeight() {
      return ('' + this.weight).length > 0 && parseFloat(this.weight) !== 0;
    },
  },

  methods: {
    cancelDialog() {
      this.$emit('cancelDialog');
    },
    closeDialog() {
      this.$emit('closeDialog');
      this.weight = 0;
    },
    acceptWeight() {
      if (!this.canAcceptWeight) return;
      if (this.weight !== 0) {
        if (this.weight.includes(',')) {
          this.weight = this.weight.replace(',', '.');
        }
      }

      this.$emit('acceptWeight', parseFloat(this.weight));
    },
    numpadUpdateValue(value) {
      if(isNaN(value))
        return;

      value = value.toString().slice(-1)

      this.weight = this.weight.toString().replace(',', '.');
      let tmp = this.weight.toString().split('.')
      if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
        this.weight = (Number(value) / 1000).toFixed(3);
      } else if (value === '00') {
        this.weight = (Number(this.weight) * 1000).toFixed(3)
      } else {
        tmp = Number(this.weight) * 10
        tmp += Number(value) / 1000
        this.weight = tmp.toFixed(3)
      }
    },
    inputTyped(event) {

      let charCode = (event.which) ? event.which : event.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        event.preventDefault();
      } else {
        if (charCode === 13) {
          event.preventDefault()
        } else {
          event.preventDefault()
          this.weight = this.weight.toString().replace(',', '.');
          let tmp = this.weight.toString().split('.')

          if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
            this.weight = (Number(event.key) / 1000).toFixed(3);
          } else if (Number(tmp[0])!==0 && tmp.length===1){
            this.weight = Number(this.weight.toString()+event.key).toFixed(3)
          }else if (event.key === '00') {
            this.weight = (Number(this.weight) * 1000).toFixed(3)
          } else {
            tmp = Number(this.weight) * 10
            tmp += Number(event.key) / 1000
            this.weight = tmp.toFixed(3)
          }
        }
        return true;
      }

    },
    numpadResetField() {
      this.weight = '';
    },
  },
};
</script>
