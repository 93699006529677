<template>
  <swipe-list
      ref="closed_list"
      :disabled="false"
      :items="orderedItemsOnly"
      :revealed.sync="revealed"
      item-key="id"
  >
    <template v-slot="{ item, index}">
      <mobile-invoice-ordered-item
          :colors="colors"
          :item="item"
          :item-index="index"
          :pos-type="posType"
          @closeLeftReveal="closeLeftReveal"
          @closeRightReveal="closeRightReveal"
      />
    </template>


    <template v-slot:right="{item}">
      <div style="width: auto; display: flex !important;">
        <buttons-freetext  :pos-type="posType"/>
        <more-options-button :item="item" :pos-type="posType"/>
      </div>
    </template>

    <template v-slot:left="{item}">
      <div v-if="!item.isVoid && $store.getters['permissions/checkPermission']('allowVoidItems')" class="px-0 pa-0"
           style="width: 100%">
        <buttons-storno :item="item" :pos-type="posType" :show-button-text="true" ordered/>
      </div>
    </template>
  </swipe-list>
</template>

<script>

import {SwipeList} from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import MobileInvoiceOrderedItem from "@/components/pos/mobile/wedgets/MobileInvoiceOrderedItem";

import ButtonsStorno from "@/components/pos/buttons/ButtonsStorno";
import {mapGetters} from "vuex";
import ButtonsFreetext from "@/components/pos/buttons/ButtonsFreetext";
import MoreOptionsButton from "@/components/pos/mobile/wedgets/buttons/MoreOptionsButton";

export default {
  name: "MobileOrderedItems",
  components: {
    MoreOptionsButton,
    SwipeList, ButtonsStorno, MobileInvoiceOrderedItem, ButtonsFreetext
  },
  computed: {
    ...mapGetters('pos/gastro', {
      selectedOrderedItem: 'selectedOrderedItem'
    }),
  },
  methods: {
    closeLeftReveal(item) {
      let index_orderedItemsOnly = this.orderedItemsOnly.indexOf(item)
      if (this.$refs.closed_list.isRevealed(index_orderedItemsOnly) === false) {
        if (index_orderedItemsOnly > -1) {
          for (let i = 0; i < this.orderedItemsOnly.length; i++) {
            if (i == index_orderedItemsOnly) continue;
            if (this.$refs.closed_list.isRevealed(i) === 'left')
              this.$refs.closed_list.closeActions(i);
          }
        }

        let index_orderedVouchers = this.orderedVouchers.indexOf(item)
        if (index_orderedVouchers > -1) {
          for (let i = this.orderedItemsOnly.length; i < this.orderedVouchers.length; i++) {
            if (i == index_orderedVouchers) continue;
            if (this.$refs.closed_list.isRevealed(i) === 'left')
              this.$refs.closed_list.closeActions(i);
          }
        }
      }
    },
    closeRightReveal(item) {
      let index_orderedItemsOnly = this.orderedItemsOnly.indexOf(item)
      if (this.$refs.closed_list.isRevealed(index_orderedItemsOnly) === false) {
        if (index_orderedItemsOnly > -1) {
          for (let i = 0; i < this.orderedItemsOnly.length; i++) {
            if (i == index_orderedItemsOnly) continue;
            if (this.$refs.closed_list.isRevealed(i) === 'right')
              this.$refs.closed_list.closeActions(i);
          }
        }

        let index_orderedVouchers = this.orderedVouchers.indexOf(item)
        if (index_orderedVouchers > -1) {
          for (let i = this.orderedItemsOnly.length; i < this.orderedVouchers.length; i++) {
            if (i == index_orderedVouchers) continue;
            if (this.$refs.closed_list.isRevealed(i) === 'right')
              this.$refs.closed_list.closeActions(i);
          }
        }
      }
    },
  },
  props: {
    orderedItemsOnly: {
      type: Array,
      required: true
    },
    orderedVouchers: {
      type: Array,
      required: true
    },
    colors: {
      type: Object,
      require: true
    },
    posType: String,
  },
  watch: {
    selectedOrderedItem(val) {
      if (val === null)
        this.$refs.closed_list.closeActions();
    }
  },
  data: () => {
    return {
      revealed: null
    }
  }
}
</script>

<style scoped>

</style>