<template>
  <v-dialog max-width="700px" scrollable persistent v-model="showDialog">
    <v-card>
      <v-img
          height="70"
          src="@/assets/images/sihot.png"
          contain
          style="margin-top: 10px; margin-bottom: 15px;"
      ></v-img>

      <v-tabs dark centered>
        <v-tab>{{ $t("generic.lang_bookToRoom") }}</v-tab>
        <v-tab>{{ $t("generic.lang_payAsGuest") }}</v-tab>

        <v-tab-item>
          <v-card-text>
            <!-- Search Input -->
            <v-row>
              <v-col cols="12">
                <v-radio-group
                    :label="$t('generic.lang_searchFor')"
                    v-model="searchMode"
                >
                  <v-radio value="NORMAL" :label="$t('accounting.lang_zimmer')"></v-radio>
                  <v-radio value="CARD" :label="$t('generic.lang_card')"></v-radio>
                  <v-radio value="TRANSPONDER" :label="$t('generic.lang_transponder')"></v-radio>
                </v-radio-group>

                <!-- Error Message -->
                <v-alert v-if="errorMessage.length > 0" color="error" dark>{{ convertErrorMessage }}</v-alert>

                <v-row>
                  <v-col :class="this.$vuetify.breakpoint.smAndDown? 'pa-1' : ''" cols="9">
                    <v-text-field dense outlined :label="$t('datatables.lang_search')" @focus="showTouchKeyboard" :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="searchInput"></v-text-field>
                  </v-col>

                  <v-col :class="this.$vuetify.breakpoint.smAndDown? 'pa-1' : ''" cols="3">
                    <v-btn class="mx-auto"  color="primary" @click="searchForItem" :disabled="loading" :loading="loading">{{ $t("datatables.lang_search") }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>

          <!-- Accounts List -->
          <v-card-text v-if="accounts.length > 0 && selectedRoom === null">
            <p><b>{{ $t('generic.lang_pleaseSelectAccount') }}:</b></p>

            <v-list>
              <v-list-item @click="selectedRoom = account" v-for="account in accounts" :key="account.uniqueid">
                <v-list-item-title>{{ account.firstname }} {{ account.lastname }}</v-list-item-title>
                <v-list-item-subtitle>{{ account.accountno }}</v-list-item-subtitle>
              </v-list-item>
            </v-list>
          </v-card-text>

          <v-card-text style="padding: 0;" v-if="selectedRoom !== null">
            <div v-if="loading" class="text-center">
              <v-progress-circular indeterminate color="primary"></v-progress-circular>
            </div>

            <div v-else>
              <v-tabs background-color="#424242" dark centered>
                <v-tab>{{ $t('generic.lang_booking') }}</v-tab>

                <!-- Occupation Details -->
                <v-tab-item>
                  <v-simple-table class="occupationTable" v-if="selectedRoom !== null">
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <td>{{ $t('generic.lang_guest') }}</td>
                        <td>{{ selectedRoom.firstname }} {{ selectedRoom.lastname }}</td>
                      </tr>
                      <tr :style="{backgroundColor: (selectedRoom.limit > 0 && invoiceTotal > selectedRoom.limit) ? 'red' : ''}">
                        <td>{{ $t('generic.lang_creditLimit') }}</td>
                        <td>{{ selectedRoom.limit | currency }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('generic.lang_bookingNo') }}</td>
                        <td>{{ selectedRoom.resNo }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('accounting.lang_zimmer') }}</td>
                        <td>{{ selectedRoom.roomno }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('generic.lang_from') }}</td>
                        <td>{{ selectedRoom.from }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('generic.lang_to') }}</td>
                        <td>{{ selectedRoom.to }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('generic.lang_amountOfGuests') }}</td>
                        <td>{{ selectedRoom.noPax }}</td>
                      </tr>
                      <tr>
                        <td>{{ $t('generic.lang_board') }}</td>
                        <td>
                          <ul>
                            <li v-for="board in selectedRoom.board" :key="board">{{ board }}</li>
                          </ul>
                        </td>
                      </tr>
                      <tr>
                        <td>{{ $t('generic.lang_alcoholAllowed') }}</td>
                        <td v-if="selectedRoom.drink !== null">{{ selectedRoom.drink }}</td>
                        <td v-else>
                          <v-chip label small color="warning">{{ $t('generic.lang_notSpecified') }}</v-chip>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-tab-item>
              </v-tabs>
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn :small="this.$vuetify.breakpoint.smAndDown" @click="closeDialog" color="error" text>{{$t("generic.lang_cancel")}}</v-btn>
            <v-spacer></v-spacer>
            <v-btn :small="this.$vuetify.breakpoint.smAndDown" :disabled="loading || selectedRoom === null" :loading="loading" @click="selectRoom" color="success">{{ $t("generic.lang_bookToRoom") }}</v-btn>
            <v-btn :small="this.$vuetify.breakpoint.smAndDown" v-show="false" :disabled="loading || selectedRoom === null" :loading="loading" @click="selectPaymentMethod" color="primary">{{ $t("accounting.lang_payment_method_") }}</v-btn>
          </v-card-actions>
        </v-tab-item>

        <v-tab-item>
          <v-card-text style="min-height: 300px;" class="text-center">
            <v-btn style="margin-top: 50px;" color="primary" @click="payAsGuest">{{ $t("generic.lang_payAsGuest") }}</v-btn>
          </v-card-text>
        </v-tab-item>
      </v-tabs>
    </v-card>

    <!-- Error Dialog -->
    <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="600">
      <v-card>
        <v-card-text class="text-center" style="padding-top: 30px; font-size: 20px;">{{ errorDialogMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="errorDialogMessage = ''">{{ $t("generic.lang_understand") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div ref="onScreenKeyboardDivSihotSearch" style="z-index: 299 !important;">
      <vue-touch-keyboard v-if="touchKeyboardSihotSearch.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboardSihotSearch"
                          :cancel="hideTouchKeyboardSihotSearch" :defaultKeySet="touchKeyboardSihotSearch.keySet"
                          :input="touchKeyboardSihotSearch.input" :layout="touchKeyboardSihotSearch.layout"
                          :options="touchKeyboardSihotSearch.options" class="internalWidthExpanded"/>
    </div>

  </v-dialog>
</template>

<style scoped>
.occupationTable tr td:first-child {
  font-weight: bold;
}

.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>

<script>
import {ENDPOINTS} from "@/config";
import {KEYBOARD} from "@/config";
import {mapState} from "vuex";

export default {
  name: "ButtonsSIHOTSearchDialog",

  props: {
    showDialog: Boolean,
    invoiceTotal: Number
  },

  data() {
    return {
      loading: false,
      accounts: [],
      selectedRoom: null,
      searchInput: "",

      // KEYBOARD
      KEYBOARD,
      touchKeyboardSihotSearch: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      awaitingSearch: null,
      showPaymentMethodsDialog: false,
      searchMode: "NORMAL",
      errorMessage: "",
      errorDialogMessage: "",
      randomTransNo: ""
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.selectedRoom = null;
        this.accounts = [];
        this.loading = false;
        this.searchInput = "";
        this.searchMode = "NORMAL";
        this.errorMessage = "";
        this.randomTransNo = "";
      }
      else {
        this.randomTransNo = this.generateTransNo(6);
      }
    },
    searchInput() {
      this.errorMessage = "";
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
    convertErrorMessage() {
      if(this.errorMessage === "roomnotfound") return this.$t("generic.lang_roomNotFound");
      if(this.errorMessage === "roomnotoccupied") return this.$t("generic.lang_roomIsNotOccupied");
      if(this.errorMessage === "cardnotfound") return this.$t("generic.lang_mapNotFound");

      return this.errorMessage;
    }
  },

  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardSihotSearch);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardSihotSearch);
  },

  methods: {
    generateTransNo(length) {
      let result           = '';
      const characters       = '0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem() {
      this.selectedRoom = null; // RESET
      this.loading = true;
      this.errorMessage = "";
      this.accounts = [];

      //AXIOS CALL
      this.axios.post(ENDPOINTS.SIHOT.SEARCH, {
        query: this.searchInput,
        searchMode: this.searchMode,
        randomTransNo: this.randomTransNo
      }).then((res) => {
        this.loading = false;

        if (res.status === 200) {
          if (res.data.success) {
            if(res.data.response.length === 1) {
              // SELECT FIRST ACCOUNT AS ROOM
              this.selectedRoom = res.data.response[0];
            } else if(res.data.response.length > 1) {
              // SHOW LIST WITH ACCOUNTS
              this.accounts = res.data.response;
            }
            else {
              // NO SEARCH RESULTS
              this.accounts = [];
              this.showNoResults = true;
            }
          }
          else {
            this.errorMessage = res.data.error.code;
          }
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    selectRoom() {
      // CHECK IF CREDIT LIMIT EXCEEDED
      /*
      if(this.selectedRoom.limit > 0 && (this.invoiceTotal > this.selectedRoom.limit)) {
        return this.errorDialogMessage = "Kredit Limit erschöpft";
      }
      
       */

      this.$emit("roomSelected", {
        room: this.selectedRoom,
        selectPayment: false
      });
    },
    selectPaymentMethod() {
      this.$emit("roomSelected", {
        room: this.selectedRoom,
        selectPayment: true
      });
    },
    payAsGuest() {
      this.$emit("payAsGuest");
    },

    // ---- KEYBOARD ----
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardSihotSearch.input = e.target;
      this.touchKeyboardSihotSearch.layout = e.target.dataset.layout;

      if (!this.touchKeyboardSihotSearch.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardSihotSearch.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardSihotSearch() {
      if (this.touchKeyboardSihotSearch.visible) {
        this.touchKeyboardSihotSearch.visible = false;
      }
    },
    dismissOnScreenKeyboardSihotSearch: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivSihotSearch) {
        if (!this.$refs.onScreenKeyboardDivSihotSearch.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardSihotSearch();
          }
        }
      }
    },

  }
}
</script>
