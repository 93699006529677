<template>
    <v-layout wrap style="position:fixed;bottom:0px;width:100%;">
        <v-card color="#FAFBFC" class="flex" flat>
            <v-card-text style="padding: 0 !important;">
                <v-container fluid style="padding: 0 !important;">
                    <v-row wrap no-gutters>
                      <v-col cols="4"
                             sm="4"
                             md="4"
                             xs="4">
                        <b-dropdown dropup no-flip text="Optionen"  class="btn-icon-vertical btn-square btn-transition btn btn-outline-secondary active"
                                style="text-transform: uppercase;height: 75px;width:100%;">
                          <template slot="button-content">
                                                <font-awesome-icon icon="cog" size="2x"/>
                                                <br>
                          </template>

                          <div class="dropdown-menu-header">
                            <div class="dropdown-menu-header-inner bg-asteroid">
                              <div class="menu-header-image dd-header-bg-1"></div>
                              <div class="menu-header-content"><h5 class="menu-header-title">{{ $t('generic.lang_Options') }}</h5></div>
                            </div>
                          </div>
                          <div class="grid-menu grid-menu-3col">
                            <div class="no-gutters row">

                              <ButtonsTableMerge v-if="posType === 'gastro'" :pos-type="posType" show-button-text/>
                                <ButtonsTableSplit v-if="posType === 'gastro'" :pos-type="posType" show-button-text></ButtonsTableSplit>
                              <ButtonsVoucher v-if="user.userID !== 101" :pos-type="posType" show-button-text></ButtonsVoucher>
                              <ButtonsGiftCard v-if="user.userID !== 101" :pos-type="posType" show-button-text></ButtonsGiftCard>
                              <ButtonsPrintDailyReport v-if="user.userID !== 101" :pos-type="posType" show-button-text></ButtonsPrintDailyReport>
                              <ButtonsPrintUsersReport v-if="user.userID !== 101" :pos-type="posType" show-button-text></ButtonsPrintUsersReport>
                            </div>
                          </div>
                        </b-dropdown>
                      </v-col>
                      <v-col cols="4"
                             sm="4"
                             md="4"
                             xs="4">
                        <button v-if="showCashoutButton" @click="showCashoutDialog = true" class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary active"
                                style="text-transform: uppercase;height: 75px;width:100%;">
                                            <span v-if="!showCashoutDialog">
                                                <font-awesome-icon icon="euro-sign" size="2x"/>
                                                <br>
                                            </span>

                          <span v-else>
                                                <v-progress-circular indeterminate color="primary"/>
                                            </span>
                        </button>
                      </v-col>
                      <v-col cols="4"
                             sm="4"
                             md="4"
                             xs="4">
                        <FooterButtonOrder :enabled="showOrderButton || canOrder" :pos-type="posType"/>
                      </v-col>
                      <!---<v-col cols="4"
                             sm="4"
                             md="4"
                             xs="4">
                        <button v-if="posType !== 'retail' && showOrderButton"  @click="$router.push('/pos/gastro/tables')" class="btn-icon-vertical btn-square btn-transition btn btn-outline-danger active"
                                style="text-transform: uppercase;height: 75px;width:100%;">
                                            <span>
                                                <font-awesome-icon :icon="['fal', 'concierge-bell']" size="2x"/>
                                                <br>
                                            </span>
                        </button>
                      </v-col> --->


                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>

        <CashoutDialog :show-dialog="showCashoutDialog" :pos-type="posType"
                       @closeDialog="showCashoutDialog = false"/>

    </v-layout>
</template>



<script>
    import {library} from '@fortawesome/fontawesome-svg-core'
    import {ENDPOINTS} from "../../config";

    import {
        faReceipt,
        faEuroSign,
        faCog
    } from '@fortawesome/free-solid-svg-icons'

    import {
        faConciergeBell
    } from '@fortawesome/pro-light-svg-icons'

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faReceipt,
        faCog,
        faEuroSign,
        faConciergeBell
    );

    import FooterButtonOrder from "./FooterButtonOrder";
    import CashoutDialog from "./cashout/CashoutDialog";
    import Parties from "./Parties";
    import PartiesAddDialog from "./PartiesAddDialog";
    import {mapGetters, mapState} from "vuex";
    import ButtonsTableSplit from "./buttons/ButtonsTableSplit";
    import ButtonsTableMerge from "./buttons/ButtonsTableMerge";
    import ButtonsVoucher from "./buttons/ButtonsVoucher";
    import ButtonsGiftCard from "./buttons/ButtonsGiftCard";
    import ButtonsPrintDailyReport from "./buttons/ButtonsPrintDailyReport";
    import ButtonsPrintUsersReport from "./buttons/ButtonsPrintUsersReport";
    export default {
        name: "Footer",

        props: {
            posType: String,
          canOrder:{
              type:Boolean,
            default:false,
          }
        },

        components: {
            ButtonsVoucher,
            ButtonsGiftCard,
            ButtonsTableMerge,
            ButtonsTableSplit,
            CashoutDialog,
            FooterButtonOrder,
            ButtonsPrintDailyReport,
            ButtonsPrintUsersReport,
            'font-awesome-icon': FontAwesomeIcon
        },

        data() {
            return {
                showCashoutDialog: false,
                showPartyAddDialog: false,
                table: {},
            }
        },

        computed: {
            ...mapGetters({
                gastroTable:'pos/gastro/tableName',
                user:'user/user',
            }),
            ...mapState({
                pos: 'pos'
            }),
            showOrderButton() {
                if(this.posType !== "gastro") {
                    return false;
                }

                if(this.pos.gastro.table === null) {
                    return true;
                }

              if (this.pos.gastro.table.name !== 0) {
                if(this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)){
                  if(this.pos.gastro.openItems[this.pos.gastro.party.name].length>0){
                    return true;
                  }
                }
              }

                return false;
            },
            showCashoutButton() {
                if(this.posType === "retail") {
                    if(this.pos.retail.orderedItems.length > 0) {
                        return true;
                    }
                }

              if (this.posType === "gastro") {
                //DIRECT SALE
                if (this.pos.gastro.table !== null) {
                  if (this.pos.gastro.table.name === 0) {
                    if (this.pos.gastro.party !== null) {
                      if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                        if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                          //return (this.table.soldBy === this.user.userID);
                          return true;
                        }
                      }
                    }
                  }
                  //TABLEPLAN
                  else {
                    if (this.pos.gastro.party !== null) {
                      if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                        if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                          //return (this.table.soldBy === this.user.userID);
                          return false;
                        }
                      }
                      if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                        if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                          //return (this.table.soldBy === this.user.userID);
                          return true;
                        }
                      }
                    }
                  }
                }
              }

                return false;
            },
        },

        methods: {

            getTableOrders(){
                if(this.posType==="gastro"){
                    if(this.gastroTable!==null){
                        if(this.gastroTable!==0){
                            this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GET).then((res) => {
                                if(res.status===200) {
                                    this.table = this.getTable(this.gastroTable,res.data.tables);
                                }
                            }).catch((err) => {

                            });
                        }
                    }

                }
            },
            getTable(name,from){
                for(let i=0,l=from.length;i<l;i++){
                    if(from[i].name.toString()===name.toString()){
                        return from[i];
                    }
                }
                return null;
            }
        },

        mounted() {
            this.getTableOrders();
        }
    }
</script>
