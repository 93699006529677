<template>
  <div>
    <!-- Kundensuche -->
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn @click="dialogOpen = true" icon v-on="on" class="ma-0" style="background-color:#FF6969;">
          <font-awesome-icon :icon="['fal', 'user-friends']" style="font-size: 20px; color:white;"/>
        </v-btn>
      </template>
      <span>{{ $t('customers.lang_cust_search') }}</span>
    </v-tooltip>

    <v-dialog max-width="700px" scrollable v-model="dialogOpen">
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ $t('customers.lang_cust_search') }}</v-toolbar-title>

          <v-spacer/>

          <v-btn @click="closeDialog" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0"/>

          <v-text-field
              style="margin-top: 35px;"
              outlined
              :label="$t('generic.lang_custSearchLabel')"
              :placeholder="$t('generic.lang_custSearchLabel')"
              v-model="searchInput"
              :loading="loading"
              @focus="showTouchKeyboard"
              autofocus
              :data-layout="KEYBOARD.KEYSETS.NORMAL"
              ref="customerSearch"
          ></v-text-field>


        <!-- Show Search results -->
        <v-card-text style="margin-top: 0px; padding: 0;">
          <v-list v-if="!loading && selectedCustomer === null">
            <v-list-item @click="loadCustomer(customerItem)" v-for="customerItem in showCustomers"
                         :key="customerItem.id">
              <v-list-item-content>
                <v-list-item-title>{{ customerItem.text }}</v-list-item-title>
                <v-list-item-subtitle>{{ customerItem.company }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ customerItem.street }} {{ customerItem.streetNumber }} {{ customerItem.zip }} {{ customerItem.city }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-text v-if="selectedCustomer !== null">
          <v-row>
            <v-col cols="12">
              <button
                  :class="selectedProject === p.projectUUID? 'btn mr-2 mb-2 btn-transition btn-hover-shine hovering-zoom btn-primary border-none text-light' : 'btn mr-2 mb-2 btn-transition btn-hover-shine hovering-zoom'"
                  :key="i"
                  @click="setProject(p)"
                  v-bind:style="{
                border: '1px solid ' + p.projectColor,
                color: p.projectColor
              }"
                  v-for="(p , i) in this.projects">{{ p.projectName }}
              </button>
            </v-col>
          </v-row>

          <!-- Loading -->
          <v-col v-if="loadingCustomerDetails" cols="12" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </v-col>
        </v-card-text>

        <v-card-text>
          <v-form lazy-validation ref="projectFrom" style="margin-top: 10px;">
            <users :rules="[v => !!v]" dense filter="isServiceProvider=1" :label="$t('generic.lang_selectServiceProvider')" outlined
                   v-bind:user="this.user" v-if="this.showUsers" v-model="user"/>
          </v-form>
        </v-card-text>

        <v-divider/>

        <v-card-actions>
          <v-btn @click="closeDialog" color="error" text class="fontTextSize">{{ $t('generic.lang_cancel') }}</v-btn>

          <v-btn @click="showCreateCustomerDialog = true"
                 color="primary" text v-if="this.$store.getters['permissions/checkPermission']('create_cust')"
                 class="fontTextSize">{{ $t('customers.lang_create_NewCust') }}
          </v-btn>
          <div v-if="$vuetify.breakpoint.smAndDown"><br><br></div>

          <v-btn :disabled="loading || selectedCustomer === null" :loading="loading"
                 @click="setCustomer"
                 color="success" class="fontTextSize">{{ $t('generic.lang_selectCustomer') }}
          </v-btn>

        </v-card-actions>

        <div id="onScreenKeyboardDivCustomerSearch">
          <vue-touch-keyboard :accept="hideTouchKeyboardCustomerSearch" :cancel="hideTouchKeyboardCustomerSearch"
                              :defaultKeySet="touchKeyboardCustomerSearch.keySet"
                              :input="touchKeyboardCustomerSearch.input" :layout="touchKeyboardCustomerSearch.layout"
                              :options="touchKeyboardCustomerSearch.options" class="internalWidthExpanded"
                              id="onScreenKeyboard" v-if="touchKeyboardCustomerSearch.visible"/>
        </div>
      </v-card>

      <CustomerCreateDialog :pos-type="posType"
                            :show-dialog="showCreateCustomerDialog" @closeDialog="showCreateCustomerDialog = false"
                            @customerCreated="customerCreated"
                            v-if="this.$store.getters['permissions/checkPermission']('create_cust')"/>
    </v-dialog>
  </div>
</template>

<style scoped>
.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}

@media (max-width: 1024px) {
  .fontTextSize {
    font-size: 0.480rem !important;
    margin: 0px !important;
  }
}

@media (min-width: 1025px) {
  .fontTextSize {
    font-size: 0.875rem !important;
  }
}
</style>

<script>
import {ENDPOINTS} from "../../../config";
import {Events} from "../../../plugins/events";
import {KEYBOARD} from "../../../config";
import {mapState} from "vuex";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faUserFriends} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import CustomerCreateDialog from "../CustomerCreateDialog";
import Users from "@/components/common/Users";
import mixin from "../../../mixins/KeyboardMixIns";

library.add(
    faUserFriends
);

export default {
  name: "InvoiceCustomerSearch",

  props: {
    posType: String
  },

  mixins: [mixin],

  components: {
    Users,
    CustomerCreateDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },

  data() {
    return {
      dialogOpen: false,
      searchInput: "",
      awaitingSearch: null,
      projects: [],
      loading: false,
      showCustomers: [],
      showCreateCustomerDialog: false,
      selectedCustomer: null,
      showUsers: false,
      selectedProject: "",
      user: null,
      KEYBOARD,
      touchKeyboardCustomerSearch: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
      loadingCustomerDetails: false,
      customerDetails: null,
      ignoreSearchInputWatcher: false
    }
  },

  watch: {
    showDialog(val) {
      this.dialogOpen = val;
    },
    dialogOpen(val) {
      if (!val) {
        this.closeDialog();
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    searchInput(val) {
      if (this.ignoreSearchInputWatcher)
        return;

      // RESET CUSTOMER
      this.selectedCustomer = null;

      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
        val && val !== this.select && this.searchForItem(val)
      }, 1000);
    },
    showCreateCustomerDialog(val) {
      if (val) {
        this.$refs.customerSearch.blur();
      }
    }
  },
  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
  },
  methods: {
    setProject(p) {
      this.showUsers = true;
      this.selectedProject = p;
    },
    loadCustomer(customer) {
      this.selectedCustomer = customer.id;

      this.ignoreSearchInputWatcher = true;

      this.$nextTick(() => {
        this.searchInput = customer.text;

        this.$nextTick(() => {
          this.ignoreSearchInputWatcher = false;
        });
      });

      //GET PROJECTS
      this.axios.post(ENDPOINTS.CUSTOMERS.PROJECTS.GETALL, {
        ascCustomerId: customer.id
      }).then((res) => {
        if (res.data.success) {
          this.projects = res.data.projects;
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      });
    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardCustomerSearch.input = e.target;
      this.touchKeyboardCustomerSearch.layout = e.target.dataset.layout;

      if (!this.touchKeyboardCustomerSearch.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardCustomerSearch.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardCustomerSearch() {
      if (this.touchKeyboardCustomerSearch.visible) {
        this.touchKeyboardCustomerSearch.visible = false;
      }
    },
    dismissOnScreenKeyboardSearchCustomer: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivCustomerSearch')) {
        if (!document.getElementById('onScreenKeyboardDivCustomerSearch').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardCustomerSearch();
          }
        }
      }
    },

    closeDialog() {
      //RESET DATA
      this.resetData();

      this.dialogOpen = false;
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem(searchValue) {
      this.loading = true;
      //this.loadingCustomerDetails = false;

      //AXIOS CALL
      this.axios.get(ENDPOINTS.ERP.CUSTOMER.GET, {
        params: {
          search: searchValue
        }
      }).then((res) => {
        this.loading = false;

        if (res.status === 200) {
          if (res.data.results.length > 0) {
            this.showCustomers = res.data.results;
          } else {
            this.showCustomers = [];
          }
        }
      }).catch(() => {

      });
    },
    setCustomer() {
      if (!this.$refs.projectFrom.validate()) return;
      this.loading = true;

      // GET CUSTOMER
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: this.selectedCustomer
      }).then((res) => {
        if (res.data.success) {
          let customer = res.data.customer;

          //ADD CUSTOMER TO INVOICE
          this.$store.dispatch("pos/setCustomer", {
            posType: this.posType,
            customer: customer
          }).then(() => {
            this.closeDialog();
            this.resetData();
          });

        } else {
          this.loading = false;

          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        this.loading = false;

        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      });
    },
    customerCreated(customerID) {
      this.selectedCustomer = customerID;

      this.showCreateCustomerDialog = false;
      this.setCustomer();
    },
    resetData() {
      this.loading = false;
      this.searchInput = "";
      this.selectedCustomer = null;
      this.showCustomers = [];
      this.projects = [];
      this.showUsers = false;
      this.selectedProject = "";
    }
  },
  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardSearchCustomer);
  },

  destroyed() {
    document.removeEventListener('click', this.dismissOnScreenKeyboardSearchCustomer);
  },
}
</script>

<style scoped>
.outlined {
  outline-color: #1F9BCF !important;
}

</style>