<template>
    <v-dialog persistent scrollable width="500" v-model="showDialog">
        <v-card>
            <div style="background-color: red;">
                <v-img height="150" src="../../../assets/images/prison.jpg" style="opacity: 70%">
                    <p class="text-white text-center" style="margin-top: 60px; font-weight: bold; font-size: 28px; background-color: #333333;">{{$t('erp.lang_ageVerification')}}</p>
                </v-img>
            </div>

            <v-card-text v-if="minAge !== 9999" class="text-center" style="font-size: 19px; margin-top: 40px;">
                {{$t('generic.lang_bornBeforeOn')}} <b>{{ birthday }}</b>?
            </v-card-text>

            <v-divider></v-divider>
            <v-card-actions>
                <v-btn color="error" text @click="cancel">{{$t('generic.lang_cancel')}}</v-btn>
                <v-spacer></v-spacer>
                <v-btn color="success" text @click="acceptAge">{{$t('generic.lang_sale')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ItemAgeVerificationDialog",

    props: {
        showDialog: Boolean,
        item: Object,
        minAge: Number
    },

    data() {
        return {}
    },

    computed: {
        birthday() {
            let date = new Date();
            date.setFullYear(date.getFullYear() - parseInt(this.minAge));

            let dd = date.getDate();
            let mm = date.getMonth() + 1;

            if (dd < 10) {
                dd = '0' + dd
            }

            if (mm < 10) {
                mm = '0' + mm
            }

            //return date.getFullYear()+"/"+mm+"/"+dd;
          return dd+"."+mm+"."+date.getFullYear();

        }
    },

    methods: {
        cancel() {
            this.$emit("closeDialog");
        },
        acceptAge() {
            this.$store.dispatch("pos/setVerifiedAge", parseInt(this.minAge)).then(() => {
                this.$emit("acceptAge")
            });
        }
    }
}
</script>

<style scoped>
.red-highlight {

    text-shadow: 3px 0px 3px red,
    -3px 0px 3px red,
    6px 0px 6px red,
    -6px 0px 6px red;

}
</style>