<template>
  <div class="pa-0 ma-0 mx-0 px-0">

    <v-app-bar

        app
        :class="[this.$vuetify.theme.dark? 'dark' : 'app-header-mini-themed']"
        class="app-bar-bottom app-header-mini pl-0 pr-0 "
        clipped-left
        elevation="0"
    >

      <v-row align="center" class="transparent pa-0 px-0 mx-auto pt-0 pb-0" justify="center"
             style="background-color: #f1f4f6">

        <v-col class="transparent  mx-0 ma-0 text-left pt-3 pl-3" cols="4" style="height: 56px !important">
          <font-awesome-icon :icon="['fal', 'bars']" class="mx-auto font-weight-lighter" size="2x"
                             style="font-size: 30px; color:#FF6969"
                             @click.stop="drawer = !drawer"/>
          <!--          <span class="text-muted mx-auto ml-0 font-size-lg font-weight-light pb-0 ma-auto mr-5"
                          style="padding-bottom: 2px; position: absolute !important; top:14px; left: 47px;">{{
                        (user.name.substring(0, 1).toUpperCase() + '.' + user.last_name).substring(0, 6)
                      }}</span>-->
          <v-chip color="#FF6969" style="position: absolute" class="d-inline-flex pl-1 ml-2" v-if="showCustomerChip"
                  v-model="showCustomerChip" close
                  @click:close="removeCustomer"
                  @click="showCustomerDialog = true">

            <span class="text-white" style="margin-left: 5px;">{{
                (this.pos[this.posType].customer.first_name + this.pos[this.posType].customer.last_name).substring(0, 6)
              }} </span>
          </v-chip>
        </v-col>

        <v-col id="cliped" class="pa-0 px-0 mx-0 ma-0 text-center transparent" cols="4">
          <div id="shadow"></div>
        </v-col>


        <v-col class="mx-0 ma-0 py-1 top-bar-not  pl-0 pr-2 " cols="4" align="right" align-self="center"
               style="height: 56px !important">
          <!--
                    <v-badge
                        bottom
                        class="header-badges  mr-2"
                        color="#FF6969"
                        content="6"
                    >
                      <font-awesome-icon :icon="['fal', 'bell']" class="text-muted" size="2x"
                                         style="font-size: 20px"
                      />
                    </v-badge>

                    <v-badge
                        bottom
                        class="header-badges ml-2 mr-2"
                        color="#FF6969"
                        content="6"
                    >
                      <font-awesome-icon :icon="['fal', 'comment']" class="text-muted" size="2x"
                                         style="font-size: 20px; transform: rotateY(180deg)"
                      />
                    </v-badge>-->

          <TablebeeNotificationsComponent
              v-if="$store.getters['permissions/checkModule'](54)&&Number($store.getters['settings/getSettingValue']('showTablebeeNotifications'))"/>
          <v-chip small color="#FF6969" class=" pl-0 pr-1 ml-0 mr-2 mb-3"
                  v-if="pos.gastro.table && pos.gastro.table.name!==0 && tab!=='home'">

            <span class="text-white" style="margin-left: 5px;">{{
                pos.gastro.table.name.toString().substring(0, 6)
              }} </span>
          </v-chip>

          <header-printers/>
          <font-awesome-icon :icon="['fal', 'power-off']" class="ml-2 mb-1 d-inline-flex" size="2x"
                             style="font-size: 20px;color: #FF6969"
                             @click="logout"
          />

        </v-col>

      </v-row>

      <parties-button :pos-type="this.posType"/>
    </v-app-bar>
    <InvoiceCustomerInfoDialog v-if="showCustomerChip" :show-dialog="showCustomerDialog"
                               :customer="this.pos[this.posType].customer"
                               @close="showCustomerDialog = false"></InvoiceCustomerInfoDialog>
    <v-navigation-drawer
        v-model="drawer"
        app
        class="mx-auto"
        clipped
        style="overflow-y: hidden;"
        v-bind:width="325"
    >
      <vue-custom-scrollbar class="scroll-area">
        <v-list dense>
          <div v-for="(entry, index) in menuEntries" :key="index">
            <v-list-item
                v-if="entry.groups.length === 0 && entry.childs.length === 0 && !entry.meta.hideInNavigation && !excluded(entry)"
                :to="entryPathLookUp(entry)" color="primary" link
                @click="selectNavEntry(entry)">
              <v-list-item-icon>
                <v-icon class="listMenuIcon">{{ entry.meta.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-title>{{ $t(entry.meta.menuTitle) }}</v-list-item-title>
            </v-list-item>

            <v-list-group v-if="entry.groups.length > 0 || entry.childs.length > 0" :prepend-icon="entry.meta.icon"
                          :value="isActive(entry)"
                          color="primary">
              <template v-slot:activator>
                <v-list-item-title>{{ $t(entry.meta.menuTitle) }}</v-list-item-title>
              </template>


              <div v-for="(child, k) in entry.childs" :key="k+'child'">

                <v-list-item v-if="entry.childs.length > 0 && !entry.meta.hideInNavigation"
                             :to="child.path" color="primary"
                             link>
                  <v-list-item-icon>
                    <v-icon class="listMenuIcon">{{ child.meta.icon }}</v-icon>
                  </v-list-item-icon>

                  <v-list-item-title>{{ $t(child.meta.menuTitle) }}</v-list-item-title>
                </v-list-item>
              </div>
              <!--                                    :value="isActive(group.childs)"-->
              <v-list-group
                  v-for="(group, i) in entry.groups"
                  :key="i"
                  :value="isActive(group.childs)"
                  no-action
                  sub-group
              >
                <template v-slot:activator>
                                    <span>
                                            <v-icon class="listMenuIcon" v-text="group.meta.icon"></v-icon>
                                        <v-list-item-title
                                            class="ml-8">{{ $t(group.meta.menuTitle) }}</v-list-item-title>
                                    </span>
                </template>

                <v-list-item v-for="(child, i) in group.childs"
                             :key="i"
                             :to="child.path"
                             link
                             no-action
                >
                  <v-list-item-title>{{ $t(child.meta.menuTitle) }}</v-list-item-title>
                </v-list-item>
              </v-list-group>
            </v-list-group>
          </div>
        </v-list>

        <v-footer class="px-0">
          <!--theme switcher-->
          <v-list-item
              key="theme-switcher-btn"
              color="primary" @click="switchTheme">
            <v-list-item-icon class="pt-3">
              <v-icon class="listMenuIcon">{{ this.$vuetify.theme.dark ? 'pe-7s-sun' : 'pe-7s-moon' }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{
                this.$t(this.$vuetify.theme.dark ? 'generic.lang_switch_to_light' : 'generic.lang_switch_to_dark')
              }}
            </v-list-item-title>
          </v-list-item>

        </v-footer>
      </vue-custom-scrollbar>
    </v-navigation-drawer>
  </div>
</template>

<script>

import vueCustomScrollbar from 'vue-custom-scrollbar'
import {ROUTER} from "@/config/routes";
import {library} from '@fortawesome/fontawesome-svg-core'
import {faEllipsisV,} from '@fortawesome/free-solid-svg-icons'
import {faBars, faComment, faPowerOff, faSignalAlt3} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {mapGetters, mapState} from "vuex";
import PartiesButton from "@/components/pos/mobile/wedgets/PartiesButton";
import {Events} from "@/plugins/events";


import InvoiceCustomerInfoDialog from "@/components/pos/invoice/InvoiceCustomerInfoDialog";
import HeaderPrinters from "@/Layout/Components/Header/HeaderPrinters";
import TablebeeNotificationsComponent from "../../../../Layout/Components/Header/TablebeeNotificationsComponent";

library.add(
    faEllipsisV, faBars, faComment, faPowerOff, faSignalAlt3
);
export default {
  components: {
    TablebeeNotificationsComponent,
    HeaderPrinters,
    PartiesButton,
    'font-awesome-icon': FontAwesomeIcon,
    InvoiceCustomerInfoDialog,
    vueCustomScrollbar,
  },

  data() {
    return {
      ROUTER,
      customerNoAvatar: require("@/assets/images/no_avatar.png"),
      drawer: this.drawerSetter,
      room_id: 0,
      showCustomerDialog: false
    }
  },

  props: {
    sidebarbg: String,
    layoutType: String,
    drawerSetter: Boolean,
    tab: String,
    posType: {
      type: String,
      required: true
    }
  },

  mounted() {
    //CHECK IF WE NEED TO CLOSE SIDEBAR
    this.$nextTick(() => {
      if (this.currentRouteName === "pos.retail" || this.currentRouteName === "pos.gastro") {
        this.drawer = false;
      }
    });
  },

  computed: {
    showCustomerChip() {
      if (this.pos[this.posType].customer !== null) {
        return true;
      }
      return false;
    },
    ...mapState([
      'user',
    ]),
    currentgroup: function () {
      return this.$route.fullPath.split('/')[1]
    },
    ...mapState([
      'backgroundSync',
      'pos'
    ]),
    ...mapGetters({
      checkRoutePermission: 'permissions/checkRoutePermission'
    }),
    currentRouteName() {
      return this.$route.name;
    },
    menuEntries() {
      let entries = [];
      let clonedEntries = JSON.parse(JSON.stringify(ROUTER.ENTRIES))

      /* BUILD MENU ENTRIED FROm config/routes.js with main entries, childs and groups */
      clonedEntries.forEach((entry) => {
        if (entry.meta.hideInNavigation)
          return;

        //CHECK MODULE + PERMISSION
        if (!this.checkRoutePermission(entry))
          return;

        let groups = [];
        let childs = [];

        //MAIN CHILDS
        if (entry.childs.length > 0) {
          entry.childs.forEach((mainChild) => {
            if (mainChild.meta.hideInNavigation)
              return;

            //CHECK MODULE + PERMISSION
            if (!this.checkRoutePermission(mainChild))
              return;

            childs.push(mainChild);
          });

          entry.childs = childs;
        }

        //GROUPS
        if (entry.groups.length > 0) {
          entry.groups.forEach((group) => {
            if (group.meta.hideInNavigation)
              return;

            //CHECK MODULE + PERMISSION
            if (!this.checkRoutePermission(group))
              return;

            let groupChilds = [];

            group.childs.forEach((groupChild) => {
              if (groupChild.meta.hideInNavigation)
                return;

              //CHECK MODULE + PERMISSION
              if (!this.checkRoutePermission(groupChild))
                return;

              groupChilds.push(groupChild);
            });

            group.childs = groupChilds;

            groups.push(group);
          });

          entry.groups = groups;
        }

        entries.push(entry);
      });

      return entries;
    },
  },

  watch: {
    currentRouteName(val) {
      if (val === "pos.retail" || val === "pos.gastro") {
        this.drawer = false;
      }
    },
    room_id(val) {
      this.$emit('changeRoom', val)
    }
  },

  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$store.commit('app/switchTheme');
    },
    removeCustomer() {
      this.$store.dispatch("pos/setCustomer", {
        posType: this.posType,
        customer: null
      });
    },
    logout() {
      this.axios.post('get/logout/').then((res) => {
        if (res.data.success) {
          this.finishLogout();
        } else {
          if (res.data.status === "already_loggedOut") {
            this.finishLogout();
          }
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_errorYouCouldNotBeLoggedOut')
        });
      });
    },
    finishLogout() {
      this.$store.dispatch("user/logout", {vm: this}).then(() => {
        Events.$emit("showSnackbar", {
          color: "success",
          message: this.$t('generic.lang_youHaveBeenSuccessfullyLoggedOut')
        });

        //REDIRECT
        this.$router.replace({
          name: "init"
        });
      });
    },
    selectNavEntry(entry) {
      /*this.$router.push({
          name: entry.name
      });*/
    },
    isActive(entry) {
      let active = false;

      if (entry.childs == undefined && entry.groups == undefined && entry != undefined && entry.length > 0)
        entry.forEach(entr => {
          if (entr.path === this.$route.fullPath) active = true;
        })
      if (entry.childs !== undefined)
        if (entry.childs.length > 0) {
          entry.childs.forEach(child => {
            if (child.path === this.$route.fullPath) active = true;
          })
        }

      if (entry.groups !== undefined)
        if (!active && entry.groups.length > 0) {
          entry.groups.forEach(group => {
            if (group.childs.length > 0) {
              group.childs.forEach(childGroup => {
                if (childGroup.path === this.$route.fullPath) active = true;
              })
            }
          })
        }


      return active;
    },
    entryPathLookUp(entry) {
      if (entry.hasOwnProperty("path")) {
        return entry.path.includes(':') ? entry.path.split(':')[0] : entry.path;
      } else {
        return "/dashboards/analytics";
      }
    },

    // EXTEND THIS METHOD TO EXCLUDE
    excluded(entry) {
      if (entry.name === 'pos.gastro.tables') {
        return this.$vuetify.breakpoint.smAndDown
      }

      return false;
    }
  }
}
</script>

<style>
.ps-container > .ps-scrollbar-x-rail,
.ps-container > .ps-scrollbar-y-rail {
  opacity: 0.6;
}

.scroll-area {

  position: relative;
  margin: auto;
  width: 300px;
  height: calc(100% - 20px);
  font-size: 14px;
  font-family: Montserrat, Helvetica Neue, Arial, sans-serif;
}

.v-navigation-drawer--fixed {
  z-index: 1000;
}

.listMenuText {

  line-height: 2.4rem;
  color: #495057;
  text-decoration: none;
}

.listMenuIcon {
  text-align: center;
  width: 34px;
  height: 34px;
  line-height: 34px;
  position: absolute;
  top: 50%;
  margin-top: -17px;
  font-size: 1.5rem;
  opacity: .3;
}

.v-list-item--link:before {
  content: "";
  height: 100%;
  opacity: 1;
  width: 3px;
  background: #e0f3ff;
  position: absolute;
  left: 10px;
  top: 0;
  border-radius: 15px;
}


.top-bar-not .v-badge__badge {
  max-width: 15px !important;
  min-width: 15px !important;
  width: 10px !important;
  padding: 3px 1px !important;
  max-height: 15px !important;
  font-size: 10px !important;
  bottom: calc(100% - 20px) !important;
  left: calc(100% - 26px) !important;
  top: 6px !important;
}


.app-header-mini .v-toolbar__content {
  padding: 0 !important;
}


.app-header-mini {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 56px;
}

.v-toolbar__content {

}

.app-header-mini-themed:before {
  background-color: white;
}

.app-header-mini:before {
  border-bottom: 3px solid #cccccc;
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  clip-path: polygon(0 0,
  100% 0,
  100% 100%,
  calc(50% + 38px) 100%,
  calc(50% + 37.487665px) calc(100% - 0.628287px),
  calc(50% + 36.975331px) calc(100% - 1.256382px),
  calc(50% + 36.462997px) calc(100% - 1.884094px),
  calc(50% + 35.912306px) calc(100% - 2.511233px),
  calc(50% + 35.863009px) calc(100% - 3.137607px),
  calc(50% + 35.802788px) calc(100% - 3.763025px),
  calc(50% + 35.731661px) calc(100% - 4.387296px),
  calc(50% + 35.64965px) calc(100% - 5.010232px),
  calc(50% + 35.55678px) calc(100% - 5.631641px),
  calc(50% + 35.453079px) calc(100% - 6.251334px),
  calc(50% + 35.338579px) calc(100% - 6.869124px),
  calc(50% + 35.213314px) calc(100% - 7.484821px),
  calc(50% + 35.077322px) calc(100% - 8.098238px),
  calc(50% + 34.930646px) calc(100% - 8.709188px),
  calc(50% + 34.77333px) calc(100% - 9.317486px),
  calc(50% + 34.605421px) calc(100% - 9.922945px),
  calc(50% + 34.426971px) calc(100% - 10.525381px),
  calc(50% + 34.238035px) calc(100% - 11.124612px),
  calc(50% + 34.038669px) calc(100% - 11.720454px),
  calc(50% + 33.828934px) calc(100% - 12.312725px),
  calc(50% + 33.608895px) calc(100% - 12.901246px),
  calc(50% + 33.378619px) calc(100% - 13.485837px),
  calc(50% + 33.138175px) calc(100% - 14.066321px),
  calc(50% + 32.887636px) calc(100% - 14.642519px),
  calc(50% + 32.62708px) calc(100% - 15.214257px),
  calc(50% + 32.356586px) calc(100% - 15.781361px),
  calc(50% + 32.076235px) calc(100% - 16.343658px),
  calc(50% + 31.786113px) calc(100% - 16.900976px),
  calc(50% + 31.486309px) calc(100% - 17.453146px),
  calc(50% + 31.176915px) calc(100% - 18px),
  calc(50% + 30.858023px) calc(100% - 18.541371px),
  calc(50% + 30.529731px) calc(100% - 19.077094px),
  calc(50% + 30.19214px) calc(100% - 19.607005px),
  calc(50% + 29.845353px) calc(100% - 20.130945px),
  calc(50% + 29.489474px) calc(100% - 20.648752px),
  calc(50% + 29.124612px) calc(100% - 21.160269px),
  calc(50% + 28.750878px) calc(100% - 21.665341px),
  calc(50% + 28.368387px) calc(100% - 22.163813px),
  calc(50% + 27.977255px) calc(100% - 22.655534px),
  calc(50% + 27.5776px) calc(100% - 23.140354px),
  calc(50% + 27.169545px) calc(100% - 23.618125px),
  calc(50% + 26.753214px) calc(100% - 24.088702px),
  calc(50% + 26.328733px) calc(100% - 24.551941px),
  calc(50% + 25.896233px) calc(100% - 25.007701px),
  calc(50% + 25.455844px) calc(100% - 25.455844px),
  calc(50% + 25.007701px) calc(100% - 25.896233px),
  calc(50% + 24.551941px) calc(100% - 26.328733px),
  calc(50% + 24.088702px) calc(100% - 26.753214px),
  calc(50% + 23.618125px) calc(100% - 27.169545px),
  calc(50% + 23.140354px) calc(100% - 27.5776px),
  calc(50% + 22.655534px) calc(100% - 27.977255px),
  calc(50% + 22.163813px) calc(100% - 28.368387px),
  calc(50% + 21.665341px) calc(100% - 28.750878px),
  calc(50% + 21.160269px) calc(100% - 29.124612px),
  calc(50% + 20.648752px) calc(100% - 29.489474px),
  calc(50% + 20.130945px) calc(100% - 29.845353px),
  calc(50% + 19.607005px) calc(100% - 30.19214px),
  calc(50% + 19.077094px) calc(100% - 30.529731px),
  calc(50% + 18.541371px) calc(100% - 30.858023px),
  calc(50% + 18px) calc(100% - 31.176915px),
  calc(50% + 17.453146px) calc(100% - 31.486309px),
  calc(50% + 16.900976px) calc(100% - 31.786113px),
  calc(50% + 16.343658px) calc(100% - 32.076235px),
  calc(50% + 15.781361px) calc(100% - 32.356586px),
  calc(50% + 15.214257px) calc(100% - 32.62708px),
  calc(50% + 14.642519px) calc(100% - 32.887636px),
  calc(50% + 14.066321px) calc(100% - 33.138175px),
  calc(50% + 13.485837px) calc(100% - 33.378619px),
  calc(50% + 12.901246px) calc(100% - 33.608895px),
  calc(50% + 12.312725px) calc(100% - 33.828934px),
  calc(50% + 11.720454px) calc(100% - 34.038669px),
  calc(50% + 11.124612px) calc(100% - 34.238035px),
  calc(50% + 10.525381px) calc(100% - 34.426971px),
  calc(50% + 9.922945px) calc(100% - 34.605421px),
  calc(50% + 9.317486px) calc(100% - 34.77333px),
  calc(50% + 8.709188px) calc(100% - 34.930646px),
  calc(50% + 8.098238px) calc(100% - 35.077322px),
  calc(50% + 7.484821px) calc(100% - 35.213314px),
  calc(50% + 6.869124px) calc(100% - 35.338579px),
  calc(50% + 6.251334px) calc(100% - 35.453079px),
  calc(50% + 5.631641px) calc(100% - 35.55678px),
  calc(50% + 5.010232px) calc(100% - 35.64965px),
  calc(50% + 4.387296px) calc(100% - 35.731661px),
  calc(50% + 3.763025px) calc(100% - 35.802788px),
  calc(50% + 3.137607px) calc(100% - 35.863009px),
  calc(50% + 2.511233px) calc(100% - 35.912306px),
  calc(50% + 1.884094px) calc(100% - 35.950663px),
  calc(50% + 1.256382px) calc(100% - 35.97807px),
  calc(50% + 0.628287px) calc(100% - 35.994517px),
  50% calc(100% - 36px),
  calc(50% - 0.628287px) calc(100% - 35.994517px),
  calc(50% - 1.256382px) calc(100% - 35.97807px),
  calc(50% - 1.884094px) calc(100% - 35.950663px),
  calc(50% - 2.511233px) calc(100% - 35.912306px),
  calc(50% - 3.137607px) calc(100% - 35.863009px),
  calc(50% - 3.763025px) calc(100% - 35.802788px),
  calc(50% - 4.387296px) calc(100% - 35.731661px),
  calc(50% - 5.010232px) calc(100% - 35.64965px),
  calc(50% - 5.631641px) calc(100% - 35.55678px),
  calc(50% - 6.251334px) calc(100% - 35.453079px),
  calc(50% - 6.869124px) calc(100% - 35.338579px),
  calc(50% - 7.484821px) calc(100% - 35.213314px),
  calc(50% - 8.098238px) calc(100% - 35.077322px),
  calc(50% - 8.709188px) calc(100% - 34.930646px),
  calc(50% - 9.317486px) calc(100% - 34.77333px),
  calc(50% - 9.922945px) calc(100% - 34.605421px),
  calc(50% - 10.525381px) calc(100% - 34.426971px),
  calc(50% - 11.124612px) calc(100% - 34.238035px),
  calc(50% - 11.720454px) calc(100% - 34.038669px),
  calc(50% - 12.312725px) calc(100% - 33.828934px),
  calc(50% - 12.901246px) calc(100% - 33.608895px),
  calc(50% - 13.485837px) calc(100% - 33.378619px),
  calc(50% - 14.066321px) calc(100% - 33.138175px),
  calc(50% - 14.642519px) calc(100% - 32.887636px),
  calc(50% - 15.214257px) calc(100% - 32.62708px),
  calc(50% - 15.781361px) calc(100% - 32.356586px),
  calc(50% - 16.343658px) calc(100% - 32.076235px),
  calc(50% - 16.900976px) calc(100% - 31.786113px),
  calc(50% - 17.453146px) calc(100% - 31.486309px),
  calc(50% - 18px) calc(100% - 31.176915px),
  calc(50% - 18.541371px) calc(100% - 30.858023px),
  calc(50% - 19.077094px) calc(100% - 30.529731px),
  calc(50% - 19.607005px) calc(100% - 30.19214px),
  calc(50% - 20.130945px) calc(100% - 29.845353px),
  calc(50% - 20.648752px) calc(100% - 29.489474px),
  calc(50% - 21.160269px) calc(100% - 29.124612px),
  calc(50% - 21.665341px) calc(100% - 28.750878px),
  calc(50% - 22.163813px) calc(100% - 28.368387px),
  calc(50% - 22.655534px) calc(100% - 27.977255px),
  calc(50% - 23.140354px) calc(100% - 27.5776px),
  calc(50% - 23.618125px) calc(100% - 27.169545px),
  calc(50% - 24.088702px) calc(100% - 26.753214px),
  calc(50% - 24.551941px) calc(100% - 26.328733px),
  calc(50% - 25.007701px) calc(100% - 25.896233px),
  calc(50% - 25.455844px) calc(100% - 25.455844px),
  calc(50% - 25.896233px) calc(100% - 25.007701px),
  calc(50% - 26.328733px) calc(100% - 24.551941px),
  calc(50% - 26.753214px) calc(100% - 24.088702px),
  calc(50% - 27.169545px) calc(100% - 23.618125px),
  calc(50% - 27.5776px) calc(100% - 23.140354px),
  calc(50% - 27.977255px) calc(100% - 22.655534px),
  calc(50% - 28.368387px) calc(100% - 22.163813px),
  calc(50% - 28.750878px) calc(100% - 21.665341px),
  calc(50% - 29.124612px) calc(100% - 21.160269px),
  calc(50% - 29.489474px) calc(100% - 20.648752px),
  calc(50% - 29.845353px) calc(100% - 20.130945px),
  calc(50% - 30.19214px) calc(100% - 19.607005px),
  calc(50% - 30.529731px) calc(100% - 19.077094px),
  calc(50% - 30.858023px) calc(100% - 18.541371px),
  calc(50% - 31.176915px) calc(100% - 18px),
  calc(50% - 31.486309px) calc(100% - 17.453146px),
  calc(50% - 31.786113px) calc(100% - 16.900976px),
  calc(50% - 32.076235px) calc(100% - 16.343658px),
  calc(50% - 32.356586px) calc(100% - 15.781361px),
  calc(50% - 32.62708px) calc(100% - 15.214257px),
  calc(50% - 32.887636px) calc(100% - 14.642519px),
  calc(50% - 33.138175px) calc(100% - 14.066321px),
  calc(50% - 33.378619px) calc(100% - 13.485837px),
  calc(50% - 33.608895px) calc(100% - 12.901246px),
  calc(50% - 33.828934px) calc(100% - 12.312725px),
  calc(50% - 34.038669px) calc(100% - 11.720454px),
  calc(50% - 34.238035px) calc(100% - 11.124612px),
  calc(50% - 34.426971px) calc(100% - 10.525381px),
  calc(50% - 34.605421px) calc(100% - 9.922945px),
  calc(50% - 34.77333px) calc(100% - 9.317486px),
  calc(50% - 34.930646px) calc(100% - 8.709188px),
  calc(50% - 35.077322px) calc(100% - 8.098238px),
  calc(50% - 35.213314px) calc(100% - 7.484821px),
  calc(50% - 35.338579px) calc(100% - 6.869124px),
  calc(50% - 35.453079px) calc(100% - 6.251334px),
  calc(50% - 35.55678px) calc(100% - 5.631641px),
  calc(50% - 35.64965px) calc(100% - 5.010232px),
  calc(50% - 35.731661px) calc(100% - 4.387296px),
  calc(50% - 35.802788px) calc(100% - 3.763025px),
  calc(50% - 35.863009px) calc(100% - 3.137607px),
  calc(50% - 35.912306px) calc(100% - 2.511233px),
  calc(50% - 36.462997px) calc(100% - 1.884094px),
  calc(50% - 36.975331px) calc(100% - 1.256382px),
  calc(50% - 37.487665px) calc(100% - 0.628287px),
  calc(50% - 38px) 100%,
  0 100%);


  -webkit-clip-path: polygon(0 0,
  100% 0,
  100% 100%,
  calc(50% + 38px) 100%,
  calc(50% + 37.487665px) calc(100% - 0.628287px),
  calc(50% + 36.975331px) calc(100% - 1.256382px),
  calc(50% + 36.462997px) calc(100% - 1.884094px),
  calc(50% + 35.912306px) calc(100% - 2.511233px),
  calc(50% + 35.863009px) calc(100% - 3.137607px),
  calc(50% + 35.802788px) calc(100% - 3.763025px),
  calc(50% + 35.731661px) calc(100% - 4.387296px),
  calc(50% + 35.64965px) calc(100% - 5.010232px),
  calc(50% + 35.55678px) calc(100% - 5.631641px),
  calc(50% + 35.453079px) calc(100% - 6.251334px),
  calc(50% + 35.338579px) calc(100% - 6.869124px),
  calc(50% + 35.213314px) calc(100% - 7.484821px),
  calc(50% + 35.077322px) calc(100% - 8.098238px),
  calc(50% + 34.930646px) calc(100% - 8.709188px),
  calc(50% + 34.77333px) calc(100% - 9.317486px),
  calc(50% + 34.605421px) calc(100% - 9.922945px),
  calc(50% + 34.426971px) calc(100% - 10.525381px),
  calc(50% + 34.238035px) calc(100% - 11.124612px),
  calc(50% + 34.038669px) calc(100% - 11.720454px),
  calc(50% + 33.828934px) calc(100% - 12.312725px),
  calc(50% + 33.608895px) calc(100% - 12.901246px),
  calc(50% + 33.378619px) calc(100% - 13.485837px),
  calc(50% + 33.138175px) calc(100% - 14.066321px),
  calc(50% + 32.887636px) calc(100% - 14.642519px),
  calc(50% + 32.62708px) calc(100% - 15.214257px),
  calc(50% + 32.356586px) calc(100% - 15.781361px),
  calc(50% + 32.076235px) calc(100% - 16.343658px),
  calc(50% + 31.786113px) calc(100% - 16.900976px),
  calc(50% + 31.486309px) calc(100% - 17.453146px),
  calc(50% + 31.176915px) calc(100% - 18px),
  calc(50% + 30.858023px) calc(100% - 18.541371px),
  calc(50% + 30.529731px) calc(100% - 19.077094px),
  calc(50% + 30.19214px) calc(100% - 19.607005px),
  calc(50% + 29.845353px) calc(100% - 20.130945px),
  calc(50% + 29.489474px) calc(100% - 20.648752px),
  calc(50% + 29.124612px) calc(100% - 21.160269px),
  calc(50% + 28.750878px) calc(100% - 21.665341px),
  calc(50% + 28.368387px) calc(100% - 22.163813px),
  calc(50% + 27.977255px) calc(100% - 22.655534px),
  calc(50% + 27.5776px) calc(100% - 23.140354px),
  calc(50% + 27.169545px) calc(100% - 23.618125px),
  calc(50% + 26.753214px) calc(100% - 24.088702px),
  calc(50% + 26.328733px) calc(100% - 24.551941px),
  calc(50% + 25.896233px) calc(100% - 25.007701px),
  calc(50% + 25.455844px) calc(100% - 25.455844px),
  calc(50% + 25.007701px) calc(100% - 25.896233px),
  calc(50% + 24.551941px) calc(100% - 26.328733px),
  calc(50% + 24.088702px) calc(100% - 26.753214px),
  calc(50% + 23.618125px) calc(100% - 27.169545px),
  calc(50% + 23.140354px) calc(100% - 27.5776px),
  calc(50% + 22.655534px) calc(100% - 27.977255px),
  calc(50% + 22.163813px) calc(100% - 28.368387px),
  calc(50% + 21.665341px) calc(100% - 28.750878px),
  calc(50% + 21.160269px) calc(100% - 29.124612px),
  calc(50% + 20.648752px) calc(100% - 29.489474px),
  calc(50% + 20.130945px) calc(100% - 29.845353px),
  calc(50% + 19.607005px) calc(100% - 30.19214px),
  calc(50% + 19.077094px) calc(100% - 30.529731px),
  calc(50% + 18.541371px) calc(100% - 30.858023px),
  calc(50% + 18px) calc(100% - 31.176915px),
  calc(50% + 17.453146px) calc(100% - 31.486309px),
  calc(50% + 16.900976px) calc(100% - 31.786113px),
  calc(50% + 16.343658px) calc(100% - 32.076235px),
  calc(50% + 15.781361px) calc(100% - 32.356586px),
  calc(50% + 15.214257px) calc(100% - 32.62708px),
  calc(50% + 14.642519px) calc(100% - 32.887636px),
  calc(50% + 14.066321px) calc(100% - 33.138175px),
  calc(50% + 13.485837px) calc(100% - 33.378619px),
  calc(50% + 12.901246px) calc(100% - 33.608895px),
  calc(50% + 12.312725px) calc(100% - 33.828934px),
  calc(50% + 11.720454px) calc(100% - 34.038669px),
  calc(50% + 11.124612px) calc(100% - 34.238035px),
  calc(50% + 10.525381px) calc(100% - 34.426971px),
  calc(50% + 9.922945px) calc(100% - 34.605421px),
  calc(50% + 9.317486px) calc(100% - 34.77333px),
  calc(50% + 8.709188px) calc(100% - 34.930646px),
  calc(50% + 8.098238px) calc(100% - 35.077322px),
  calc(50% + 7.484821px) calc(100% - 35.213314px),
  calc(50% + 6.869124px) calc(100% - 35.338579px),
  calc(50% + 6.251334px) calc(100% - 35.453079px),
  calc(50% + 5.631641px) calc(100% - 35.55678px),
  calc(50% + 5.010232px) calc(100% - 35.64965px),
  calc(50% + 4.387296px) calc(100% - 35.731661px),
  calc(50% + 3.763025px) calc(100% - 35.802788px),
  calc(50% + 3.137607px) calc(100% - 35.863009px),
  calc(50% + 2.511233px) calc(100% - 35.912306px),
  calc(50% + 1.884094px) calc(100% - 35.950663px),
  calc(50% + 1.256382px) calc(100% - 35.97807px),
  calc(50% + 0.628287px) calc(100% - 35.994517px),
  50% calc(100% - 36px),
  calc(50% - 0.628287px) calc(100% - 35.994517px),
  calc(50% - 1.256382px) calc(100% - 35.97807px),
  calc(50% - 1.884094px) calc(100% - 35.950663px),
  calc(50% - 2.511233px) calc(100% - 35.912306px),
  calc(50% - 3.137607px) calc(100% - 35.863009px),
  calc(50% - 3.763025px) calc(100% - 35.802788px),
  calc(50% - 4.387296px) calc(100% - 35.731661px),
  calc(50% - 5.010232px) calc(100% - 35.64965px),
  calc(50% - 5.631641px) calc(100% - 35.55678px),
  calc(50% - 6.251334px) calc(100% - 35.453079px),
  calc(50% - 6.869124px) calc(100% - 35.338579px),
  calc(50% - 7.484821px) calc(100% - 35.213314px),
  calc(50% - 8.098238px) calc(100% - 35.077322px),
  calc(50% - 8.709188px) calc(100% - 34.930646px),
  calc(50% - 9.317486px) calc(100% - 34.77333px),
  calc(50% - 9.922945px) calc(100% - 34.605421px),
  calc(50% - 10.525381px) calc(100% - 34.426971px),
  calc(50% - 11.124612px) calc(100% - 34.238035px),
  calc(50% - 11.720454px) calc(100% - 34.038669px),
  calc(50% - 12.312725px) calc(100% - 33.828934px),
  calc(50% - 12.901246px) calc(100% - 33.608895px),
  calc(50% - 13.485837px) calc(100% - 33.378619px),
  calc(50% - 14.066321px) calc(100% - 33.138175px),
  calc(50% - 14.642519px) calc(100% - 32.887636px),
  calc(50% - 15.214257px) calc(100% - 32.62708px),
  calc(50% - 15.781361px) calc(100% - 32.356586px),
  calc(50% - 16.343658px) calc(100% - 32.076235px),
  calc(50% - 16.900976px) calc(100% - 31.786113px),
  calc(50% - 17.453146px) calc(100% - 31.486309px),
  calc(50% - 18px) calc(100% - 31.176915px),
  calc(50% - 18.541371px) calc(100% - 30.858023px),
  calc(50% - 19.077094px) calc(100% - 30.529731px),
  calc(50% - 19.607005px) calc(100% - 30.19214px),
  calc(50% - 20.130945px) calc(100% - 29.845353px),
  calc(50% - 20.648752px) calc(100% - 29.489474px),
  calc(50% - 21.160269px) calc(100% - 29.124612px),
  calc(50% - 21.665341px) calc(100% - 28.750878px),
  calc(50% - 22.163813px) calc(100% - 28.368387px),
  calc(50% - 22.655534px) calc(100% - 27.977255px),
  calc(50% - 23.140354px) calc(100% - 27.5776px),
  calc(50% - 23.618125px) calc(100% - 27.169545px),
  calc(50% - 24.088702px) calc(100% - 26.753214px),
  calc(50% - 24.551941px) calc(100% - 26.328733px),
  calc(50% - 25.007701px) calc(100% - 25.896233px),
  calc(50% - 25.455844px) calc(100% - 25.455844px),
  calc(50% - 25.896233px) calc(100% - 25.007701px),
  calc(50% - 26.328733px) calc(100% - 24.551941px),
  calc(50% - 26.753214px) calc(100% - 24.088702px),
  calc(50% - 27.169545px) calc(100% - 23.618125px),
  calc(50% - 27.5776px) calc(100% - 23.140354px),
  calc(50% - 27.977255px) calc(100% - 22.655534px),
  calc(50% - 28.368387px) calc(100% - 22.163813px),
  calc(50% - 28.750878px) calc(100% - 21.665341px),
  calc(50% - 29.124612px) calc(100% - 21.160269px),
  calc(50% - 29.489474px) calc(100% - 20.648752px),
  calc(50% - 29.845353px) calc(100% - 20.130945px),
  calc(50% - 30.19214px) calc(100% - 19.607005px),
  calc(50% - 30.529731px) calc(100% - 19.077094px),
  calc(50% - 30.858023px) calc(100% - 18.541371px),
  calc(50% - 31.176915px) calc(100% - 18px),
  calc(50% - 31.486309px) calc(100% - 17.453146px),
  calc(50% - 31.786113px) calc(100% - 16.900976px),
  calc(50% - 32.076235px) calc(100% - 16.343658px),
  calc(50% - 32.356586px) calc(100% - 15.781361px),
  calc(50% - 32.62708px) calc(100% - 15.214257px),
  calc(50% - 32.887636px) calc(100% - 14.642519px),
  calc(50% - 33.138175px) calc(100% - 14.066321px),
  calc(50% - 33.378619px) calc(100% - 13.485837px),
  calc(50% - 33.608895px) calc(100% - 12.901246px),
  calc(50% - 33.828934px) calc(100% - 12.312725px),
  calc(50% - 34.038669px) calc(100% - 11.720454px),
  calc(50% - 34.238035px) calc(100% - 11.124612px),
  calc(50% - 34.426971px) calc(100% - 10.525381px),
  calc(50% - 34.605421px) calc(100% - 9.922945px),
  calc(50% - 34.77333px) calc(100% - 9.317486px),
  calc(50% - 34.930646px) calc(100% - 8.709188px),
  calc(50% - 35.077322px) calc(100% - 8.098238px),
  calc(50% - 35.213314px) calc(100% - 7.484821px),
  calc(50% - 35.338579px) calc(100% - 6.869124px),
  calc(50% - 35.453079px) calc(100% - 6.251334px),
  calc(50% - 35.55678px) calc(100% - 5.631641px),
  calc(50% - 35.64965px) calc(100% - 5.010232px),
  calc(50% - 35.731661px) calc(100% - 4.387296px),
  calc(50% - 35.802788px) calc(100% - 3.763025px),
  calc(50% - 35.863009px) calc(100% - 3.137607px),
  calc(50% - 35.912306px) calc(100% - 2.511233px),
  calc(50% - 36.462997px) calc(100% - 1.884094px),
  calc(50% - 36.975331px) calc(100% - 1.256382px),
  calc(50% - 37.487665px) calc(100% - 0.628287px),
  calc(50% - 38px) 100%,
  0 100%);

}


#cliped:before {
  -webkit-box-shadow: -1px 26px 65px 30px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 26px 65px 30px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 26px 65px 30px rgba(0, 0, 0, 0.75);
  clip-path: polygon(0 0,
  100% 0,
  100% 100%,
  calc(50% + 38px) 100%,
  calc(50% + 37.487665px) calc(100% - 0.628287px),
  calc(50% + 36.975331px) calc(100% - 1.256382px),
  calc(50% + 36.462997px) calc(100% - 1.884094px),
  calc(50% + 35.912306px) calc(100% - 2.511233px),
  calc(50% + 35.863009px) calc(100% - 3.137607px),
  calc(50% + 35.802788px) calc(100% - 3.763025px),
  calc(50% + 35.731661px) calc(100% - 4.387296px),
  calc(50% + 35.64965px) calc(100% - 5.010232px),
  calc(50% + 35.55678px) calc(100% - 5.631641px),
  calc(50% + 35.453079px) calc(100% - 6.251334px),
  calc(50% + 35.338579px) calc(100% - 6.869124px),
  calc(50% + 35.213314px) calc(100% - 7.484821px),
  calc(50% + 35.077322px) calc(100% - 8.098238px),
  calc(50% + 34.930646px) calc(100% - 8.709188px),
  calc(50% + 34.77333px) calc(100% - 9.317486px),
  calc(50% + 34.605421px) calc(100% - 9.922945px),
  calc(50% + 34.426971px) calc(100% - 10.525381px),
  calc(50% + 34.238035px) calc(100% - 11.124612px),
  calc(50% + 34.038669px) calc(100% - 11.720454px),
  calc(50% + 33.828934px) calc(100% - 12.312725px),
  calc(50% + 33.608895px) calc(100% - 12.901246px),
  calc(50% + 33.378619px) calc(100% - 13.485837px),
  calc(50% + 33.138175px) calc(100% - 14.066321px),
  calc(50% + 32.887636px) calc(100% - 14.642519px),
  calc(50% + 32.62708px) calc(100% - 15.214257px),
  calc(50% + 32.356586px) calc(100% - 15.781361px),
  calc(50% + 32.076235px) calc(100% - 16.343658px),
  calc(50% + 31.786113px) calc(100% - 16.900976px),
  calc(50% + 31.486309px) calc(100% - 17.453146px),
  calc(50% + 31.176915px) calc(100% - 18px),
  calc(50% + 30.858023px) calc(100% - 18.541371px),
  calc(50% + 30.529731px) calc(100% - 19.077094px),
  calc(50% + 30.19214px) calc(100% - 19.607005px),
  calc(50% + 29.845353px) calc(100% - 20.130945px),
  calc(50% + 29.489474px) calc(100% - 20.648752px),
  calc(50% + 29.124612px) calc(100% - 21.160269px),
  calc(50% + 28.750878px) calc(100% - 21.665341px),
  calc(50% + 28.368387px) calc(100% - 22.163813px),
  calc(50% + 27.977255px) calc(100% - 22.655534px),
  calc(50% + 27.5776px) calc(100% - 23.140354px),
  calc(50% + 27.169545px) calc(100% - 23.618125px),
  calc(50% + 26.753214px) calc(100% - 24.088702px),
  calc(50% + 26.328733px) calc(100% - 24.551941px),
  calc(50% + 25.896233px) calc(100% - 25.007701px),
  calc(50% + 25.455844px) calc(100% - 25.455844px),
  calc(50% + 25.007701px) calc(100% - 25.896233px),
  calc(50% + 24.551941px) calc(100% - 26.328733px),
  calc(50% + 24.088702px) calc(100% - 26.753214px),
  calc(50% + 23.618125px) calc(100% - 27.169545px),
  calc(50% + 23.140354px) calc(100% - 27.5776px),
  calc(50% + 22.655534px) calc(100% - 27.977255px),
  calc(50% + 22.163813px) calc(100% - 28.368387px),
  calc(50% + 21.665341px) calc(100% - 28.750878px),
  calc(50% + 21.160269px) calc(100% - 29.124612px),
  calc(50% + 20.648752px) calc(100% - 29.489474px),
  calc(50% + 20.130945px) calc(100% - 29.845353px),
  calc(50% + 19.607005px) calc(100% - 30.19214px),
  calc(50% + 19.077094px) calc(100% - 30.529731px),
  calc(50% + 18.541371px) calc(100% - 30.858023px),
  calc(50% + 18px) calc(100% - 31.176915px),
  calc(50% + 17.453146px) calc(100% - 31.486309px),
  calc(50% + 16.900976px) calc(100% - 31.786113px),
  calc(50% + 16.343658px) calc(100% - 32.076235px),
  calc(50% + 15.781361px) calc(100% - 32.356586px),
  calc(50% + 15.214257px) calc(100% - 32.62708px),
  calc(50% + 14.642519px) calc(100% - 32.887636px),
  calc(50% + 14.066321px) calc(100% - 33.138175px),
  calc(50% + 13.485837px) calc(100% - 33.378619px),
  calc(50% + 12.901246px) calc(100% - 33.608895px),
  calc(50% + 12.312725px) calc(100% - 33.828934px),
  calc(50% + 11.720454px) calc(100% - 34.038669px),
  calc(50% + 11.124612px) calc(100% - 34.238035px),
  calc(50% + 10.525381px) calc(100% - 34.426971px),
  calc(50% + 9.922945px) calc(100% - 34.605421px),
  calc(50% + 9.317486px) calc(100% - 34.77333px),
  calc(50% + 8.709188px) calc(100% - 34.930646px),
  calc(50% + 8.098238px) calc(100% - 35.077322px),
  calc(50% + 7.484821px) calc(100% - 35.213314px),
  calc(50% + 6.869124px) calc(100% - 35.338579px),
  calc(50% + 6.251334px) calc(100% - 35.453079px),
  calc(50% + 5.631641px) calc(100% - 35.55678px),
  calc(50% + 5.010232px) calc(100% - 35.64965px),
  calc(50% + 4.387296px) calc(100% - 35.731661px),
  calc(50% + 3.763025px) calc(100% - 35.802788px),
  calc(50% + 3.137607px) calc(100% - 35.863009px),
  calc(50% + 2.511233px) calc(100% - 35.912306px),
  calc(50% + 1.884094px) calc(100% - 35.950663px),
  calc(50% + 1.256382px) calc(100% - 35.97807px),
  calc(50% + 0.628287px) calc(100% - 35.994517px),
  50% calc(100% - 36px),
  calc(50% - 0.628287px) calc(100% - 35.994517px),
  calc(50% - 1.256382px) calc(100% - 35.97807px),
  calc(50% - 1.884094px) calc(100% - 35.950663px),
  calc(50% - 2.511233px) calc(100% - 35.912306px),
  calc(50% - 3.137607px) calc(100% - 35.863009px),
  calc(50% - 3.763025px) calc(100% - 35.802788px),
  calc(50% - 4.387296px) calc(100% - 35.731661px),
  calc(50% - 5.010232px) calc(100% - 35.64965px),
  calc(50% - 5.631641px) calc(100% - 35.55678px),
  calc(50% - 6.251334px) calc(100% - 35.453079px),
  calc(50% - 6.869124px) calc(100% - 35.338579px),
  calc(50% - 7.484821px) calc(100% - 35.213314px),
  calc(50% - 8.098238px) calc(100% - 35.077322px),
  calc(50% - 8.709188px) calc(100% - 34.930646px),
  calc(50% - 9.317486px) calc(100% - 34.77333px),
  calc(50% - 9.922945px) calc(100% - 34.605421px),
  calc(50% - 10.525381px) calc(100% - 34.426971px),
  calc(50% - 11.124612px) calc(100% - 34.238035px),
  calc(50% - 11.720454px) calc(100% - 34.038669px),
  calc(50% - 12.312725px) calc(100% - 33.828934px),
  calc(50% - 12.901246px) calc(100% - 33.608895px),
  calc(50% - 13.485837px) calc(100% - 33.378619px),
  calc(50% - 14.066321px) calc(100% - 33.138175px),
  calc(50% - 14.642519px) calc(100% - 32.887636px),
  calc(50% - 15.214257px) calc(100% - 32.62708px),
  calc(50% - 15.781361px) calc(100% - 32.356586px),
  calc(50% - 16.343658px) calc(100% - 32.076235px),
  calc(50% - 16.900976px) calc(100% - 31.786113px),
  calc(50% - 17.453146px) calc(100% - 31.486309px),
  calc(50% - 18px) calc(100% - 31.176915px),
  calc(50% - 18.541371px) calc(100% - 30.858023px),
  calc(50% - 19.077094px) calc(100% - 30.529731px),
  calc(50% - 19.607005px) calc(100% - 30.19214px),
  calc(50% - 20.130945px) calc(100% - 29.845353px),
  calc(50% - 20.648752px) calc(100% - 29.489474px),
  calc(50% - 21.160269px) calc(100% - 29.124612px),
  calc(50% - 21.665341px) calc(100% - 28.750878px),
  calc(50% - 22.163813px) calc(100% - 28.368387px),
  calc(50% - 22.655534px) calc(100% - 27.977255px),
  calc(50% - 23.140354px) calc(100% - 27.5776px),
  calc(50% - 23.618125px) calc(100% - 27.169545px),
  calc(50% - 24.088702px) calc(100% - 26.753214px),
  calc(50% - 24.551941px) calc(100% - 26.328733px),
  calc(50% - 25.007701px) calc(100% - 25.896233px),
  calc(50% - 25.455844px) calc(100% - 25.455844px),
  calc(50% - 25.896233px) calc(100% - 25.007701px),
  calc(50% - 26.328733px) calc(100% - 24.551941px),
  calc(50% - 26.753214px) calc(100% - 24.088702px),
  calc(50% - 27.169545px) calc(100% - 23.618125px),
  calc(50% - 27.5776px) calc(100% - 23.140354px),
  calc(50% - 27.977255px) calc(100% - 22.655534px),
  calc(50% - 28.368387px) calc(100% - 22.163813px),
  calc(50% - 28.750878px) calc(100% - 21.665341px),
  calc(50% - 29.124612px) calc(100% - 21.160269px),
  calc(50% - 29.489474px) calc(100% - 20.648752px),
  calc(50% - 29.845353px) calc(100% - 20.130945px),
  calc(50% - 30.19214px) calc(100% - 19.607005px),
  calc(50% - 30.529731px) calc(100% - 19.077094px),
  calc(50% - 30.858023px) calc(100% - 18.541371px),
  calc(50% - 31.176915px) calc(100% - 18px),
  calc(50% - 31.486309px) calc(100% - 17.453146px),
  calc(50% - 31.786113px) calc(100% - 16.900976px),
  calc(50% - 32.076235px) calc(100% - 16.343658px),
  calc(50% - 32.356586px) calc(100% - 15.781361px),
  calc(50% - 32.62708px) calc(100% - 15.214257px),
  calc(50% - 32.887636px) calc(100% - 14.642519px),
  calc(50% - 33.138175px) calc(100% - 14.066321px),
  calc(50% - 33.378619px) calc(100% - 13.485837px),
  calc(50% - 33.608895px) calc(100% - 12.901246px),
  calc(50% - 33.828934px) calc(100% - 12.312725px),
  calc(50% - 34.038669px) calc(100% - 11.720454px),
  calc(50% - 34.238035px) calc(100% - 11.124612px),
  calc(50% - 34.426971px) calc(100% - 10.525381px),
  calc(50% - 34.605421px) calc(100% - 9.922945px),
  calc(50% - 34.77333px) calc(100% - 9.317486px),
  calc(50% - 34.930646px) calc(100% - 8.709188px),
  calc(50% - 35.077322px) calc(100% - 8.098238px),
  calc(50% - 35.213314px) calc(100% - 7.484821px),
  calc(50% - 35.338579px) calc(100% - 6.869124px),
  calc(50% - 35.453079px) calc(100% - 6.251334px),
  calc(50% - 35.55678px) calc(100% - 5.631641px),
  calc(50% - 35.64965px) calc(100% - 5.010232px),
  calc(50% - 35.731661px) calc(100% - 4.387296px),
  calc(50% - 35.802788px) calc(100% - 3.763025px),
  calc(50% - 35.863009px) calc(100% - 3.137607px),
  calc(50% - 35.912306px) calc(100% - 2.511233px),
  calc(50% - 36.462997px) calc(100% - 1.884094px),
  calc(50% - 36.975331px) calc(100% - 1.256382px),
  calc(50% - 37.487665px) calc(100% - 0.628287px),
  calc(50% - 38px) 100%,
  0 100%);


  -webkit-clip-path: polygon(0 0,
  100% 0,
  100% 100%,
  calc(50% + 38px) 100%,
  calc(50% + 37.487665px) calc(100% - 0.628287px),
  calc(50% + 36.975331px) calc(100% - 1.256382px),
  calc(50% + 36.462997px) calc(100% - 1.884094px),
  calc(50% + 35.912306px) calc(100% - 2.511233px),
  calc(50% + 35.863009px) calc(100% - 3.137607px),
  calc(50% + 35.802788px) calc(100% - 3.763025px),
  calc(50% + 35.731661px) calc(100% - 4.387296px),
  calc(50% + 35.64965px) calc(100% - 5.010232px),
  calc(50% + 35.55678px) calc(100% - 5.631641px),
  calc(50% + 35.453079px) calc(100% - 6.251334px),
  calc(50% + 35.338579px) calc(100% - 6.869124px),
  calc(50% + 35.213314px) calc(100% - 7.484821px),
  calc(50% + 35.077322px) calc(100% - 8.098238px),
  calc(50% + 34.930646px) calc(100% - 8.709188px),
  calc(50% + 34.77333px) calc(100% - 9.317486px),
  calc(50% + 34.605421px) calc(100% - 9.922945px),
  calc(50% + 34.426971px) calc(100% - 10.525381px),
  calc(50% + 34.238035px) calc(100% - 11.124612px),
  calc(50% + 34.038669px) calc(100% - 11.720454px),
  calc(50% + 33.828934px) calc(100% - 12.312725px),
  calc(50% + 33.608895px) calc(100% - 12.901246px),
  calc(50% + 33.378619px) calc(100% - 13.485837px),
  calc(50% + 33.138175px) calc(100% - 14.066321px),
  calc(50% + 32.887636px) calc(100% - 14.642519px),
  calc(50% + 32.62708px) calc(100% - 15.214257px),
  calc(50% + 32.356586px) calc(100% - 15.781361px),
  calc(50% + 32.076235px) calc(100% - 16.343658px),
  calc(50% + 31.786113px) calc(100% - 16.900976px),
  calc(50% + 31.486309px) calc(100% - 17.453146px),
  calc(50% + 31.176915px) calc(100% - 18px),
  calc(50% + 30.858023px) calc(100% - 18.541371px),
  calc(50% + 30.529731px) calc(100% - 19.077094px),
  calc(50% + 30.19214px) calc(100% - 19.607005px),
  calc(50% + 29.845353px) calc(100% - 20.130945px),
  calc(50% + 29.489474px) calc(100% - 20.648752px),
  calc(50% + 29.124612px) calc(100% - 21.160269px),
  calc(50% + 28.750878px) calc(100% - 21.665341px),
  calc(50% + 28.368387px) calc(100% - 22.163813px),
  calc(50% + 27.977255px) calc(100% - 22.655534px),
  calc(50% + 27.5776px) calc(100% - 23.140354px),
  calc(50% + 27.169545px) calc(100% - 23.618125px),
  calc(50% + 26.753214px) calc(100% - 24.088702px),
  calc(50% + 26.328733px) calc(100% - 24.551941px),
  calc(50% + 25.896233px) calc(100% - 25.007701px),
  calc(50% + 25.455844px) calc(100% - 25.455844px),
  calc(50% + 25.007701px) calc(100% - 25.896233px),
  calc(50% + 24.551941px) calc(100% - 26.328733px),
  calc(50% + 24.088702px) calc(100% - 26.753214px),
  calc(50% + 23.618125px) calc(100% - 27.169545px),
  calc(50% + 23.140354px) calc(100% - 27.5776px),
  calc(50% + 22.655534px) calc(100% - 27.977255px),
  calc(50% + 22.163813px) calc(100% - 28.368387px),
  calc(50% + 21.665341px) calc(100% - 28.750878px),
  calc(50% + 21.160269px) calc(100% - 29.124612px),
  calc(50% + 20.648752px) calc(100% - 29.489474px),
  calc(50% + 20.130945px) calc(100% - 29.845353px),
  calc(50% + 19.607005px) calc(100% - 30.19214px),
  calc(50% + 19.077094px) calc(100% - 30.529731px),
  calc(50% + 18.541371px) calc(100% - 30.858023px),
  calc(50% + 18px) calc(100% - 31.176915px),
  calc(50% + 17.453146px) calc(100% - 31.486309px),
  calc(50% + 16.900976px) calc(100% - 31.786113px),
  calc(50% + 16.343658px) calc(100% - 32.076235px),
  calc(50% + 15.781361px) calc(100% - 32.356586px),
  calc(50% + 15.214257px) calc(100% - 32.62708px),
  calc(50% + 14.642519px) calc(100% - 32.887636px),
  calc(50% + 14.066321px) calc(100% - 33.138175px),
  calc(50% + 13.485837px) calc(100% - 33.378619px),
  calc(50% + 12.901246px) calc(100% - 33.608895px),
  calc(50% + 12.312725px) calc(100% - 33.828934px),
  calc(50% + 11.720454px) calc(100% - 34.038669px),
  calc(50% + 11.124612px) calc(100% - 34.238035px),
  calc(50% + 10.525381px) calc(100% - 34.426971px),
  calc(50% + 9.922945px) calc(100% - 34.605421px),
  calc(50% + 9.317486px) calc(100% - 34.77333px),
  calc(50% + 8.709188px) calc(100% - 34.930646px),
  calc(50% + 8.098238px) calc(100% - 35.077322px),
  calc(50% + 7.484821px) calc(100% - 35.213314px),
  calc(50% + 6.869124px) calc(100% - 35.338579px),
  calc(50% + 6.251334px) calc(100% - 35.453079px),
  calc(50% + 5.631641px) calc(100% - 35.55678px),
  calc(50% + 5.010232px) calc(100% - 35.64965px),
  calc(50% + 4.387296px) calc(100% - 35.731661px),
  calc(50% + 3.763025px) calc(100% - 35.802788px),
  calc(50% + 3.137607px) calc(100% - 35.863009px),
  calc(50% + 2.511233px) calc(100% - 35.912306px),
  calc(50% + 1.884094px) calc(100% - 35.950663px),
  calc(50% + 1.256382px) calc(100% - 35.97807px),
  calc(50% + 0.628287px) calc(100% - 35.994517px),
  50% calc(100% - 36px),
  calc(50% - 0.628287px) calc(100% - 35.994517px),
  calc(50% - 1.256382px) calc(100% - 35.97807px),
  calc(50% - 1.884094px) calc(100% - 35.950663px),
  calc(50% - 2.511233px) calc(100% - 35.912306px),
  calc(50% - 3.137607px) calc(100% - 35.863009px),
  calc(50% - 3.763025px) calc(100% - 35.802788px),
  calc(50% - 4.387296px) calc(100% - 35.731661px),
  calc(50% - 5.010232px) calc(100% - 35.64965px),
  calc(50% - 5.631641px) calc(100% - 35.55678px),
  calc(50% - 6.251334px) calc(100% - 35.453079px),
  calc(50% - 6.869124px) calc(100% - 35.338579px),
  calc(50% - 7.484821px) calc(100% - 35.213314px),
  calc(50% - 8.098238px) calc(100% - 35.077322px),
  calc(50% - 8.709188px) calc(100% - 34.930646px),
  calc(50% - 9.317486px) calc(100% - 34.77333px),
  calc(50% - 9.922945px) calc(100% - 34.605421px),
  calc(50% - 10.525381px) calc(100% - 34.426971px),
  calc(50% - 11.124612px) calc(100% - 34.238035px),
  calc(50% - 11.720454px) calc(100% - 34.038669px),
  calc(50% - 12.312725px) calc(100% - 33.828934px),
  calc(50% - 12.901246px) calc(100% - 33.608895px),
  calc(50% - 13.485837px) calc(100% - 33.378619px),
  calc(50% - 14.066321px) calc(100% - 33.138175px),
  calc(50% - 14.642519px) calc(100% - 32.887636px),
  calc(50% - 15.214257px) calc(100% - 32.62708px),
  calc(50% - 15.781361px) calc(100% - 32.356586px),
  calc(50% - 16.343658px) calc(100% - 32.076235px),
  calc(50% - 16.900976px) calc(100% - 31.786113px),
  calc(50% - 17.453146px) calc(100% - 31.486309px),
  calc(50% - 18px) calc(100% - 31.176915px),
  calc(50% - 18.541371px) calc(100% - 30.858023px),
  calc(50% - 19.077094px) calc(100% - 30.529731px),
  calc(50% - 19.607005px) calc(100% - 30.19214px),
  calc(50% - 20.130945px) calc(100% - 29.845353px),
  calc(50% - 20.648752px) calc(100% - 29.489474px),
  calc(50% - 21.160269px) calc(100% - 29.124612px),
  calc(50% - 21.665341px) calc(100% - 28.750878px),
  calc(50% - 22.163813px) calc(100% - 28.368387px),
  calc(50% - 22.655534px) calc(100% - 27.977255px),
  calc(50% - 23.140354px) calc(100% - 27.5776px),
  calc(50% - 23.618125px) calc(100% - 27.169545px),
  calc(50% - 24.088702px) calc(100% - 26.753214px),
  calc(50% - 24.551941px) calc(100% - 26.328733px),
  calc(50% - 25.007701px) calc(100% - 25.896233px),
  calc(50% - 25.455844px) calc(100% - 25.455844px),
  calc(50% - 25.896233px) calc(100% - 25.007701px),
  calc(50% - 26.328733px) calc(100% - 24.551941px),
  calc(50% - 26.753214px) calc(100% - 24.088702px),
  calc(50% - 27.169545px) calc(100% - 23.618125px),
  calc(50% - 27.5776px) calc(100% - 23.140354px),
  calc(50% - 27.977255px) calc(100% - 22.655534px),
  calc(50% - 28.368387px) calc(100% - 22.163813px),
  calc(50% - 28.750878px) calc(100% - 21.665341px),
  calc(50% - 29.124612px) calc(100% - 21.160269px),
  calc(50% - 29.489474px) calc(100% - 20.648752px),
  calc(50% - 29.845353px) calc(100% - 20.130945px),
  calc(50% - 30.19214px) calc(100% - 19.607005px),
  calc(50% - 30.529731px) calc(100% - 19.077094px),
  calc(50% - 30.858023px) calc(100% - 18.541371px),
  calc(50% - 31.176915px) calc(100% - 18px),
  calc(50% - 31.486309px) calc(100% - 17.453146px),
  calc(50% - 31.786113px) calc(100% - 16.900976px),
  calc(50% - 32.076235px) calc(100% - 16.343658px),
  calc(50% - 32.356586px) calc(100% - 15.781361px),
  calc(50% - 32.62708px) calc(100% - 15.214257px),
  calc(50% - 32.887636px) calc(100% - 14.642519px),
  calc(50% - 33.138175px) calc(100% - 14.066321px),
  calc(50% - 33.378619px) calc(100% - 13.485837px),
  calc(50% - 33.608895px) calc(100% - 12.901246px),
  calc(50% - 33.828934px) calc(100% - 12.312725px),
  calc(50% - 34.038669px) calc(100% - 11.720454px),
  calc(50% - 34.238035px) calc(100% - 11.124612px),
  calc(50% - 34.426971px) calc(100% - 10.525381px),
  calc(50% - 34.605421px) calc(100% - 9.922945px),
  calc(50% - 34.77333px) calc(100% - 9.317486px),
  calc(50% - 34.930646px) calc(100% - 8.709188px),
  calc(50% - 35.077322px) calc(100% - 8.098238px),
  calc(50% - 35.213314px) calc(100% - 7.484821px),
  calc(50% - 35.338579px) calc(100% - 6.869124px),
  calc(50% - 35.453079px) calc(100% - 6.251334px),
  calc(50% - 35.55678px) calc(100% - 5.631641px),
  calc(50% - 35.64965px) calc(100% - 5.010232px),
  calc(50% - 35.731661px) calc(100% - 4.387296px),
  calc(50% - 35.802788px) calc(100% - 3.763025px),
  calc(50% - 35.863009px) calc(100% - 3.137607px),
  calc(50% - 35.912306px) calc(100% - 2.511233px),
  calc(50% - 36.462997px) calc(100% - 1.884094px),
  calc(50% - 36.975331px) calc(100% - 1.256382px),
  calc(50% - 37.487665px) calc(100% - 0.628287px),
  calc(50% - 38px) 100%,
  0 100%);
}


#shadow {
  position: absolute;
  z-index: -1;
  content: "";
  background: rgba(0, 0, 0, .5);
  width: 100%;
  height: 100%;
  left: 0px;
  top: 10px;
  filter: blur(5px);
}

#shadow:after {

}


.v-app-bar--hide-shadow {
  transform: translateY(-100px) !important;
}

</style>
