<template>
  <div>
    <v-dialog
        v-model="dialogOpen"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        scrollable
        persistent
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" max-height="64">
          <v-btn icon dark @click="closeDialog">
            <v-icon size="40px" color="warning">close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text class="pa-1" :style="this.$vuetify.theme.dark? 'background-color: #1E1E1E;' : 'background-color: #f1f4f6;'">
          <v-container class="pa-0">
            <v-row no-gutters justify="end">
              <v-col cols="6" sm="6" md="6" lg="4" xl="4" class="pa-1">
                <v-card tile elevation="0" class="pa-2" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                  <div class="grid-menu grid-menu-3col">
                    <div class="no-gutters row">
                      <div v-for="(payment,key) in filteredPayments" :key="key" class="col-sm-6 col-xl-4">
                        <button @click="payment.paymentID===7?toggleSplitDialog():selectPayment(payment.paymentID)"
                                :disabled="loading"
                                class="btn-icon-vertical btn-square btn-transition payment-btn btn h-100">
                          <i v-if="payment.paymentID===3 && (payments.ecPaymentType === 1 || payments.ecPaymentType === 2)" class="btn-icon-wrapper">
                            <img :src="(payments.ecPaymentType===1)?zvt:adyen" width="60%" :alt="payment.paymentName">
                          </i>
                          <i v-else class="btn-icon-wrapper">
                            <font-awesome-icon style="font-size: 40px;"
                                               :icon="['fal', (paymentIcons.hasOwnProperty(payment.paymentID))?paymentIcons[payment.paymentID]:paymentIcons[0]]"/>
                          </i>

                          <span v-if="payment.paymentID===3 && (payments.ecPaymentType === 1 || payments.ecPaymentType === 2)">{{ ' ' }} </span>
                          <span v-else class="text-uppercase font-weight-bolder"
                                style="color: #515C6F !important;"> {{ $t(payment.paymentNameTranslate) }} </span>
                        </button>
                      </div>

                      <!-- ---------------------- HOTEL PMS BUTTONS ------------------- -->
                      <div v-if="$store.getters['permissions/checkModule'](43)" class="col-sm-6 col-xl-4">
                        <ButtonsIbelsaSearch @roomSelected="ibelsaRoomSelected"></ButtonsIbelsaSearch>
                      </div>

                      <div v-if="$store.getters['permissions/checkModule'](44)" class="col-sm-6 col-xl-4">
                        <ButtonsHS3Search @occupationSelected="hs3OccupationSelected"
                                          @occupationGuestSelected="hs3OccupationGuestSelected"></ButtonsHS3Search>
                      </div>

                      <div v-if="$store.getters['permissions/checkModule'](45)" class="col-sm-6 col-xl-4">
                        <ButtonsSuite8Search @roomSelected="suite8RoomSelected"></ButtonsSuite8Search>
                      </div>

                      <div v-if="$store.getters['permissions/checkModule'](46)" class="col-sm-6 col-xl-4">
                        <ButtonsSIHOTSearch :invoiceTotal="total" @payAsGuest="sihotPayAsGuest"
                                            @roomSelected="sihotRoomSelected"></ButtonsSIHOTSearch>

                      </div>
                      <!-- --------------------------------------------------------------- -->

                      <!-- ---------------------- VOFFICE BUTTON ------------------- -->
                      <div v-if="$store.getters['permissions/checkModule'](51) && this.customer !== null"
                           class="col-sm-6 col-xl-4">
                        <button @click="selectPayment(104)"
                                class="btn-icon-vertical btn-square btn-transition payment-btn btn h-100">
                          <i class="btn-icon-wrapper">
                            <img src="@/assets/images/voffice.png" width="60%" alt="Voffice">
                          </i>
                        </button>
                      </div>

                    </div>
                  </div>
                </v-card>

                <div v-if="posType === 'gastro'">
                  <v-switch v-model="bewirtungsbeleg" color="#ff6969" :label="$t('generic.lang_Bewirtungsbeleg')"/>
                  <v-switch v-model="offSite" :false-value="0" :true-value="1" color="#ff6969"
                            :label="$t('erp.lang_ausserHausTax')"/>
                </div>
              </v-col>
              <v-col cols="6" sm="6" md="6" lg="8" xl="8">
                <v-row no-gutters>
                  <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="pa-1">
                    <v-card :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" tile elevation="0" width="100%">
                      <v-card-text class="pa-0">
                        <v-container class="pa-1">
                          <v-row>
                            <v-col cols="12" align="center">
                              <p class="font-weight-light mb-1 fsize-3" style="color: grey;">
                                {{ $t('generic.lang_totalSum') }} </p>
                            </v-col>
                            <v-col cols="12" align="center">
                              <p class="font-weight-light mb-1 fsize-4" style="font-family: Anton;color: #515C6F;">
                                {{ totalWithVoucher | currency }}
                              </p>
                            </v-col>
                            <v-col cols="12" align="center">
                              <v-btn class="bg-white px-1 ma-0" elevation="0" outlined rounded large
                                     @click="$refs.ics.dialogOpen=true">
                                <InvoiceCustomerSearch v-if="customer===null" ref="ics" :pos-type="posType"
                                                       class="ma-0"/>

                                <v-chip v-if="customer !== null" @click:close="removeCustomer" close>
                                  <v-avatar style="margin-right: 5px;">
                                    <img :src="customerNoAvatar" alt="trevor">
                                  </v-avatar>
                                  {{ customer.first_name }} {{ customer.last_name }}
                                </v-chip>
                                <span class="text-muted" v-else>
                                  {{ $t('generic.lang_selectCustomer') }}
                                </span>
                              </v-btn>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="4" style="font-size: 18px">
                              <br>
                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col cols="4" style="font-size: 18px">
                              <p class="font-weight-light mb-1" style="color: grey;">
                                {{ $t('generic.lang_receipt_gegeben') }} </p>
                              <p class="font-weight-light mb-1" style="font-family: Anton;color: #515C6F;">{{
                                  moneyGiven | currency
                                }}
                              </p>
                            </v-col>
                            <v-col cols="4" style="font-size: 18px">
                              <p class="font-weight-light mb-1 text-uppercase" style="color: #ff6969;">
                                {{ $t('erp.lang_rest') }}</p>
                              <p class="font-weight-light mb-1" style="color: #ff6969;font-family: Anton;">
                                {{ moneyBack | currency }}
                              </p>
                            </v-col>
                            <v-col cols="4" style="font-size: 14px" v-if="this.settings.settings.disableTip !== '1'">
                              <p class="font-weight-light ma-0" style="color: grey;">
                                {{ $t('generic.lang_restAsTip') }}
                              </p>
                              <v-switch v-model="restAsTip" class="ma-0 pa-0" hide-details color="#ff6969"></v-switch>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <CashoutNumpad :double-zero="true" @change="typed($event)" @del="del"/>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12"><br>
                        <v-textarea
                            clearable
                            outlined
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            v-model="freetext"
                            :label="$t('accounting.lang_InvoiceNotes')"
                            @focus="showTouchKeyboard"
                            @blur="savefreeTextCashout"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" sm="12" md="12" lg="6" xl="6" class="pa-1">
                    <v-card tile elevation="0" :class="[this.$vuetify.theme.dark? 'dark-bg' : '']">
                      <v-card-text class="pa-0">
                        <v-container class="pa-1">
                          <v-row no-gutters>
                            <v-col class="pa-2" cols="4" align="center"
                                   v-for="(currency, index) in currency[currencyIMG].muenzen" :key="index">
                              <v-card flat @click="giveMoney(currency.value)" ripple>
                                <v-img height="50" width="50" contain :src="currency.image"/>
                              </v-card>

                            </v-col>
                          </v-row>
                          <v-row no-gutters>
                            <v-col class="pa-2" cols="6" v-for="(currency, index) in currency[currencyIMG].scheine" :key="index"
                                   align="center">
                              <v-card width="80%" @click="giveMoney(currency.value)" elevation="1">
                                <v-img height="100%" width="100%" :src="currency.image"/>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                    </v-card>
                    <v-card class="mt-2" elevation="0">
                      <div class="no-gutters row">
                        <div class="col-sm-12 col-xl-12">
                          <button @click="selectPayment(1)" :disabled="loading" style="background-color: #ff6969;"
                                  class="btn-icon-vertical btn-square btn-transition payment-btn btn h-100 w-100">
                            <i class="btn-icon-wrapper">
                              <font-awesome-icon style="font-size: 40px;color:white; "
                                                 :icon="['fal', paymentIcons[1]]"/>
                            </i>
                            <span class="text-uppercase font-weight-bolder" style="color: white !important;"> 
                              {{ $t('generic.lang_cash') }}
                            </span>
                          </button>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <div id="onScreenKeyboardDivShift" >
        <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded"
                            :options="touchKeyboardShift.options"
                            :defaultKeySet="touchKeyboardShift.keySet" v-if="touchKeyboardShift.visible"
                            :layout="touchKeyboardShift.layout" :cancel="hideTouchKeyboardShift"
                            :accept="hideTouchKeyboardShift" :input="touchKeyboardShift.input"/>
      </div>
    </v-dialog>

    <!-- Dialog: SPLIT PAYMENT-->
    <v-dialog v-model="splitDialog" fullscreen
              hide-overlay
              transition="dialog-bottom-transition"
              scrollable
              persistent
    >
      <v-card tile>
        <v-toolbar flat dense :color="this.$vuetify.theme.dark? '#1E1E1E;' : '#f1f4f6'" class="ma-0 pa-0" max-height="50" elevation="1">
          <v-row no-gutters>
            <v-btn class="ma-0" fab color="warning" text @click="toggleSplitDialog()">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
            <v-col class="" cols="3">
              <v-list-item-content class="">
                <v-list-item-subtitle class="text-muted">
                  {{ $t('generic.lang_receipt_gegeben') }}
                </v-list-item-subtitle>
                <v-list-item-title class="pt-1 font-size-xlg">
                <span class="text-color text-font">
                  {{ givenSplit | currency }}
                </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>

            <v-col class="" cols="3">
              <v-list-item-content class="">
                <v-list-item-subtitle class="text-muted text-uppercase">
                  {{ $t('erp.lang_rest') }}
                </v-list-item-subtitle>
                <v-list-item-title class="pt-1 font-size-xlg">
                <span class="text-color text-font">
                  {{ remaining | currency }}
                </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>

            <v-col class="text-right" cols="5">
              <v-list-item-content class="">
                <v-list-item-subtitle class="text-right text-muted">
                  {{ $t('generic.lang_total') }}
                </v-list-item-subtitle>
                <v-list-item-title class="pt-1 font-size-xlg text-right">
                <span class="text-font" style="color: #FF6969;">
                  {{ total | currency }}
                </span>
                </v-list-item-title>
              </v-list-item-content>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="6">
              <v-row no-gutters v-for="(payment,key) in splitPayments" :key="key">
                <v-col cols="12" class="">
                  <v-text-field min="0" :suffix="$store.getters['settings/currencyData']('symbol')" dense type="number"
                                v-model="payment.amount" height="51px"
                                outlined @keypress="inputTypedSplit($event,payment)"
                                class="pr-0" @focus="remember($event,payment)"
                                :label="payment.label" value="0.00"
                                :rules="[rules.canSplit]" :ref="'payment'+payment.id" color="rgb(255, 105, 105)"
                  >
                    <template v-slot:prepend-inner>
                      <v-row no-gutters style="width:50px">
                        <v-col align="start">
                          <i class="btn-icon-wrapper my-auto">
                            <font-awesome-icon style="color:rgb(255, 105, 105);" size="2x" :icon="['fal', (paymentIcons.hasOwnProperty(payment.id))?
                                                        paymentIcons[payment.id]:paymentIcons[0]]"/>
                          </i>
                        </v-col>
                      </v-row>
                    </template>

                    <template v-slot:append>
                      <v-btn
                          depressed
                          elevation="0"
                          large
                          @click="rest(payment)"
                          class="ma-0 text-light"
                          light
                          style="border-color: rgb(255, 105, 105) !important;margin-top: -4.5px !important;margin-right: -7px !important;"
                          color="rgb(255, 105, 105)"
                      >
                        {{ $t('erp.lang_rest') }}
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" align-self="center">
              <CashoutNumpad :double-zero="true" @change="typedSplit" @del="clearSplit()"/>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="12" sm="10" align="end">
              <v-btn
                  :class="['ma-0','pa-0']"
                  class="mx-0 ma-0"
                  depressed
                  block
                  large
                  style="border-color: rgb(255, 105, 105) !important;font-family: Anton;color:white;"
                  color="rgb(255, 105, 105)"
                  :loading="loading" tile elevation="0"
                  :disabled="!canPayWithSplit" @click="selectPayment(7)"
              >{{ $t('generic.lang_pay') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog: AFTER SALE-->
    <AfterSaleDialog
        :pos-type="posType"
        :invoice-result-data="invoiceResultData"
        :invoice-x-m-l-print-data="invoiceXMLPrintData"
        :total="savedTotal"
        :total-with-voucher="totalWithVoucher"
        :money-given="moneyGiven"
        :money-back="moneyBack"
        :goods-value="goodsValue"
        :vouchers-value="vouchersValue"
        :show-dialog="showAfterPaymentDialog"
        :real-user="realUser"
        @nextCustomer="closeDialog" :split="split"
    />
    <!----------------------->

    <!-- -------------------------------------------------------- -->
    <!-- ------------- REQUIRED IMPORTS FOR CASHOUT ------------- -->
    <!-- -------------------------------------------------------- -->
    <v-dialog v-model="loading" persistent width="500">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <v-progress-circular
                    indeterminate
                    color="primary"
                    width="3"
                    size="50"
                />
                <p style="margin-top: 15px;" class="text-center">{{ $t('accounting.lang_sellingInProgress') }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorDialog" persistent width="500">
      <v-card>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="text-center">
                <p style="font-size: 17px;">{{ errorDialogMessage }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" v-if="errorDialogShowCancelButton" :disabled="errorDialogLoading" text
                 @click="cancelErrorDialog">{{ $t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn color="success" :disabled="errorDialogLoading" :loading="errorDialogLoading" @click="closeErrorDialog">
            {{ errorDialogConfirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CashoutAdyenDialog ref="adyenDialog" :amount="total" @finished="adyenFinished"
                        @cancel="loading = false"></CashoutAdyenDialog>
    <CashoutZVTDiaog ref="zvtDialog" @finished="zvtFinished"
                     @cancel="loading = false"></CashoutZVTDiaog>


    <PrintOrderbon ref="printOrderBon"></PrintOrderbon>
    <!-- -------------------------------------------------------- -->
  </div>
</template>

<script>
/*
IMPORTANT: THIS MIXIN CONTAINS THE MAIN LOGIC!!! (only javascript)
*/
import cashoutMixin from "@/mixins/pos/cashout/cashoutMixin";

import InvoiceCustomerSearch from "../invoice/InvoiceCustomerSearch";
import {KEYBOARD} from "@/config";
import {mapState} from "vuex";
import CashoutAdyenDialog from "@/components/pos/CashoutAdyenDialog";
import ButtonsHS3Search from "@/components/pos/buttons/ButtonsHS3Search";
import ButtonsIbelsaSearch from "@/components/pos/buttons/ButtonsIbelsaSearch";
import PrintOrderbon from "@/mixins/pos/PrintOrderbon";
import CashoutZVTDiaog from "@/components/pos/CashoutZVTDiaog";
import CashoutNumpad from "@/components/pos/cashout/CashoutNumpad";

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import AfterSaleDialog from "@/components/pos/AfterSaleDialog";
import ButtonsSuite8Search from "@/components/pos/buttons/ButtonsSuite8Search";
import ButtonsSIHOTSearch from "@/components/pos/buttons/ButtonsSIHOTSearch";


/*
* 1    cash //euro-sign
* 2    voucher
* 3    EC //credit-card-blank
* 4    bill //receipt
* 5    ELV
* 6    AUFS HAUS //hands-heart
* 7    Split Payment *Special Case
* 8    PayCard Payment (RFID) *Special Case
* 9    CreditCard //credit-card
* 10   Ticket Restaurant
* 11   PayPal
* 12   CustomerCard
*/

export default {
  name: "CashoutDialog",

  components: {
    ButtonsSIHOTSearch,
    ButtonsSuite8Search,
    AfterSaleDialog,
    CashoutNumpad,
    CashoutZVTDiaog,
    PrintOrderbon,
    ButtonsIbelsaSearch,
    ButtonsHS3Search,
    CashoutAdyenDialog,
    InvoiceCustomerSearch,
    'font-awesome-icon': FontAwesomeIcon
  },

  props: {
    posType: String,
    showDialog: Boolean,
    openOnMounted: Boolean,
    invoiceItemsOverwrite: {
      type: Array,
      default: () => {
        return [];
      }
    },
    newOrderArray: {
      type: Array,
      default: () => {
        return [];
      }
    },
    split: {
      type: Boolean,
      default: false,
    }
  },

  mixins: [cashoutMixin],

  computed: {
    ...mapState([
      'touchkeyboard',
      'settings',
      'api',
      'cashierIDs'
    ]),
    cashierOptions() {
      let opt = {};
      opt = this.cashierIDs.cashiers.find(cashier => cashier.id === this.api.auth.cashierID);
      return opt
    }
  },

  data() {
    return {
      lastFocus: null,
      KEYBOARD,
      freetext: null,
      touchKeyboardShift: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
      numpadClicks: 0,
    }
  },

  mounted() {
    document.addEventListener('click', this.dismissOnScreenKeyboardShift);

    if (this.posType === 'gastro') {
      this.freetext = this.pos.gastro.freeText;
      if (Number(this.cashierOptions.takeAwayStandard) === 1 && (this.pos.gastro.table && this.pos.gastro.table.name === 0)) {
        this.offSite = Number(this.cashierOptions.takeAwayStandard);
      } else {
        this.offSite = Number(this.pos.gastro.takeAway);
      }
    } else {
      /*GET RETAIL FREE TEXT ONLY*/
      this.freetext = this.pos.retail.freeText;
    }


  },

  destroyed() {
    document.removeEventListener('click', this.dismissOnScreenKeyboardShift);
  },

  watch: {
    showDialog(val) {
      this.dialogOpen = val;
      this.numpadClicks = 0;
    },
    dialogOpen(val) {
      //CHECK TAKEAWAY STANDARD CASE

      if (this.posType === 'gastro') {
        if (Number(this.cashierOptions.takeAwayStandard) === 1 && this.pos.gastro.table.name === 0) {
          if (Number(this.cashierOptions.inHouseTakeAwayRequired) === 1) {
            this.offSite = Number(Number(this.cashierOptions.takeAwayStandard) === 1 && Number(this.pos.gastro.takeAway) === 1);
          } else {
            this.offSite = Number(this.cashierOptions.takeAwayStandard)
          }
        } else {
          this.offSite = Number(this.pos.gastro.takeAway);
        }
      }

      this.moneyGiven = this.totalWithVoucher;
      if (val) {
        if (this.settings.settings.payAppropriately == 0) {
          this.del();
        }
      }
    },
    offSite(v){
      this.$store.commit('pos/gastro/setTakeAway', Number(v));
    }
  },

  methods: {
    remember(event, method) {
      this.lastFocus = null;
      if (method !== null) {
        this.lastFocus = method;
      }
    },

    savefreeTextCashout() {
      this.$store.dispatch("pos/setFreetext", {
        posType: this.posType,
        freeText: this.freetext,
        selectedItem: null
      })
    },

    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardShift.input = e.target;
      this.touchKeyboardShift.layout = e.target.dataset.layout;

      if (!this.touchKeyboardShift.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardShift.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardShift() {
      if (this.touchKeyboardShift.visible) {
        this.touchKeyboardShift.visible = false;
      }
    },
    dismissOnScreenKeyboardShift: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivShift')) {
        if (!document.getElementById('onScreenKeyboardDivShift').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardShift();
          }
        }
      }
    },
    closeDialog() {
      if (this.showAfterPaymentDialog) {
        this.finalFinishCashout();
      }
      if (this.dialogOpen && !this.showDialog) {
        this.dialogOpen = false;
      } else {
        this.$emit("closeDialog");
        /* SEEMS TO DO THE TRICK */
        /* BUGFIX AFTER SALE PAYMENT DIALOG STAYS OPEN INFINITE */
        this.showAfterPaymentDialog = false;
      }
    },
    typed(val) {

      if (this.numpadClicks < 1) {
        this.moneyGiven = 0
      }
      this.numpadClicks++
      let tmp = this.moneyGiven.toString().split('.')
      if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
        this.moneyGiven = Number(val) / 100;
      } else if (val === '00') {
        this.moneyGiven = Number(this.moneyGiven) * 100
      } else {
        this.moneyGiven = Number(this.moneyGiven) * 10
        this.moneyGiven += Number(val) / 100
      }

    },
    inputTypedSplit(event, type) {

      let charCode = (event.which) ? event.which : event.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        event.preventDefault();
      } else {
        if (charCode === 13) {
          event.preventDefault()
        } else {
          event.preventDefault()
          let tmp = type.amount.toString().split('.')
          if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
            type.amount = (Number(event.key) / 100).toFixed(2);
          } else if (event.key === '00') {
            type.amount = (Number(type.amount) * 100).toFixed(2)
          } else {
            tmp = Number(type.amount) * 10
            tmp += Number(event.key) / 100
            type.amount = tmp.toFixed(2)
          }
        }

        return true;
      }

    },
    typedSplit(value) {
      if (this.lastFocus) {
        let tmp = this.lastFocus.amount.toString().split('.')
        if (Number(tmp[0]) === 0 && tmp[1] === undefined) {
          this.lastFocus.amount = (Number(value) / 100).toFixed(2);
        } else if (value === '00') {
          this.lastFocus.amount = (Number(this.lastFocus.amount) * 100).toFixed(2)
        } else {
          tmp = Number(this.lastFocus.amount) * 10
          tmp += Number(value) / 100
          this.lastFocus.amount = tmp.toFixed(2)
        }

      }
    },
    del() {
      this.moneyGiven = 0;
    },
    clearSplit() {
      if (this.lastFocus) {
        this.lastFocus.amount = 0;
      }
    }
  }
}
</script>

<style scoped>
.payment-btn {
  color: #ff6969;
}

.payment-btn:hover, .payment-btn:active {
  color: white !important;
  background-color: #ff6969;
}
</style>
