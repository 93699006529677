<script>
import {KEYBOARD} from "../config";
import {mapState} from "vuex";

export default {
  data() {
    return {
      KEYBOARD,
      touchKeyboard: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ])
  },

  methods: {
    showTouchKeyboardWithoutEvent() {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.clearIntervalID = setTimeout(() => {
        this.touchKeyboard.visible = true;
      }, 150);
    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboard.input = e.target;
      this.touchKeyboard.layout = e.target.dataset.layout;

      if (!this.touchKeyboard.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboard.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboard() {
      if (this.touchKeyboard.visible) {
        this.touchKeyboard.visible = false;
      }
    },
    dismissOnScreenKeyboard: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDiv')) {
        if (!document.getElementById('onScreenKeyboardDiv').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboard();
          }
        }
      }
    },
  },

  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboard);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboard);
  }
}
</script>
