<template>
  <v-dialog v-model="showDialog" max-width="500px" persistent scrollable>
    <v-card v-if="item !== null "
            class="mx-auto"
            max-width="500"
            outlined
    >
      <!-- Image -->
      <v-parallax v-if="itemProductImageUri.length > 0" id="product_image" class="px-0 pa-0" height="200">
        <v-btn color="#0000007a" elevation="0" fab style="position:absolute;z-index: 1001; top: 0; right: 0" x-small
               @click="closeDialog">
          <v-icon class="pa-1" color="error">close</v-icon>
        </v-btn>

        <v-img
            :src="itemProductImageUri"
            class="ma-0"
            cover
            width="100%"
        >

        </v-img>

        <v-row align="center" class="ma-0 mx-0 pa-12" no-gutters
               style="position: absolute; z-index: 1000; top: 0; bottom: 0; right: 0; left: 0; background-color: #0000007a; height: 200px !important;">
        </v-row>
      </v-parallax>

      <v-toolbar v-else flat>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" icon color="#0000007a">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- ------- -->


      <v-card-text v-if="this.show==false" class="pa-0">
        <v-row align="center" class="mx-0" no-gutters>
          <v-col cols="8">
            <v-card-title style="color: #3C7CBA">
              {{ item.name }}
            </v-card-title>

            <v-card-subtitle class="pt-1">
              <span v-if="item.sellPrice === item.originalSellPrice">{{ item.sellPrice | currency }}</span>
              <span v-else>
                                                <span style="text-decoration: line-through; text-decoration-color: red">{{
                                                    item.originalSellPrice | currency
                                                  }}</span> {{ item.sellPrice | currency }}
                                            </span>
            </v-card-subtitle>
          </v-col>
          <v-col cols="12">
            <v-card-text class="text-justify">
              {{ description }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text v-if="baseItem && Number(baseItem.isDailyGoods)>0">
        <v-alert  :color="(dailyAmount>0)?'success':'error'" text>
          {{ $t('generic.lang_dailyAmount') }} : {{ dailyAmount }}
        </v-alert>
      </v-card-text>

      <v-card-actions class="border-top pt-0 pb-0">
        <v-btn
            color="#3C7CBA"
            style="color:white;"
            @click="printInformations()">
          {{ $t('erp.lang_print') }}
        </v-btn>

        <v-spacer></v-spacer>
        <v-card-title class="mx-auto ma-auto pt-0 pb-0" style="color: #3C7CBA">
          {{ this.itemStackSellPrice | currency }}
        </v-card-title>
      </v-card-actions>

      <v-expand-transition>
        <div>
          <v-divider></v-divider>

          <v-card-text class="py-1">
            <v-row>
              <v-col cols="12">
                <div v-if="itemAllergene && itemAllergene.length > 0">
                  <p style="margin-bottom: 0;"><b>{{ $t('erp.lang_Allergene') }}:</b></p>

                  <ul style="margin-left: 15px;">
                    <li v-for="allergen in itemAllergene" :key="allergen.id">{{ allergen.name }}</li>
                  </ul>
                </div>

                <v-alert v-else color="info" text>
                  {{ $t('erp.lang_noAllergensPresent') }}
                </v-alert>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>

                <div v-if="itemAdditives && itemAdditives.length > 0">
                  <p style="margin-bottom: 0;"><b>{{ $t('erp.lang_Zusatzstoffe') }}:</b></p>

                  <ul style="margin-left: 15px;">
                    <li v-for="additive in itemAdditives" :key="additive.id">{{ additive.name }}</li>
                  </ul>
                </div>

                <v-alert v-else color="info" text>
                  {{ $t('erp.lang_noIngredientsPresent') }}
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </div>
      </v-expand-transition>
      <v-divider class="ma-0"/>
      <v-card tile v-if="!$store.getters['permissions/checkPermission']('hideStorageInItemInfo') && stockInfo" outlined elevation="1">
        <v-card-text>
          <p style="margin-bottom: 0; color: black;"><b>{{ $t('erp.lang_storageOverview') }}:</b></p>

        </v-card-text>
        <v-divider class="ma-0"/>
        <v-data-table :loading="loadingStockInfo" :items="(stockInfo[0])?stockInfo[0]:[]" :headers="dataTableHeaders" hide-default-footer>
          <template v-slot:body.append>
            <tr>
              <td colspan="2">{{ $t('generic.lang_total') }} :</td>
              <td class="text-center">{{ stockInfo[1] }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-card>

  </v-dialog>
</template>

<script>

import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";
import {geol} from "@/main";


export default {
  name: "ButtonsItemInfoDialog",
  props: {
    posType: String,
    showDialog: Boolean,
    item: Object
  },

  data() {
    return {
      activeTab: 0,
      imageUrl: "",
      show: false,
      loadingStockInfo: false,
      stockInfo: null,
      baseItem: null,
      itemgroup: null,
      dailyAmount:0,
    }
  },

  watch: {
    async showDialog(val) {
      if (val) {
        this.activeTab = 0;

        // GET ITEM FROM INDEXED DB
        if (this.item !== null) {
          this.baseItem = await this.$store.dispatch("items/getItemByID", this.item.id);
          this.itemgroup = await this.$store.dispatch("itemgroups/getItemgroupByID", this.item.itemgroupID);
        }

        this.getItemStockInfo()
      } else {
        this.baseItem = null;
        this.itemgroup = null;
      }

      if(this.baseItem)
        if(Number(this.baseItem.isDailyGoods)>0)
          this.getItemDailyGoods();
    },
  },

  computed: {
    ...mapState([
      'api',
      'pos',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    dataTableHeaders() {
      return [
        {
          text: this.$t('erp.lang_storageID'),
          value: 'storageID',
        },
        {
          text: this.$t('erp.lang_storageName'),
          value: 'storageName',
        },
        {
          text: this.$t('erp.lang_stockinventory'),
          value: 'storageValue',
        }
      ];
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    itemSellPrice() {
      //CHECK MEALSIZE PRICE
      if (this.baseItem !== null) {
        if (this.item.mealSizeID > 0) {
          let itemMealsize = this.baseItem.mealSizes.find((mealsize) => mealsize.id === this.item.mealSizeID);

          if (itemMealsize)
            return itemMealsize.price;
        }
      }

      return this.item.sellPrice;
    },
    itemStackSellPrice() {
      //CHECK IF WE HAVE WEIGHT
      return this.itemSellPrice * this.item.amount;
    },
    itemgroupName() {
      if (this.item === null) {
        return "";
      }

      if (this.itemgroup === null) {
        return "";
      }

      return this.itemgroup.name;
    },
    description() {
      if (this.baseItem === null) {
        return '';
      }

      return this.baseItem.description;
    },
    itemAllergene() {
      if (this.baseItem === null) {
        return [];
      }

      return this.baseItem.allergene;
    },
    itemAdditives() {
      if (this.baseItem === null) {
        return [];
      }

      return this.baseItem.zusatzstoffe;
    },
    itemProductImageUri() {
      if (this.baseItem === null)
        return "";

      if (this.baseItem.productImage === null)
        return "";

      return "https://img.3pos.de/unsafe/500x200/billsys_" + this.api.auth.posID + "/original/" + this.baseItem.productImage;
    }
  },

  methods: {
    selectItem() {
      this.$store.dispatch("pos/gastro/selectInvoiceOpenItem", this.item);
    },
    plus() {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.selectItem();


      if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/numpadQTY", (this.item.amount + 1));
      }

      if (this.posType === "gastro") {
        this.$store.dispatch("pos/gastro/numpadQTY", (this.item.amount + 1));
      }
    },
    minus() {
      if (this.item !== this.pos.gastro.selectedOpenItem)
        this.selectItem();


      if (this.posType === "retail" && (this.item.amount - 1) > 0) {
        this.$store.dispatch("pos/retail/numpadQTY", (this.item.amount - 1));
      }

      if (this.posType === "gastro" && (this.item.amount - 1) > 0) {
        this.$store.dispatch("pos/gastro/numpadQTY", (this.item.amount - 1));
      }
    },
    closeDialog() {
      //RESET DATA
      this.$emit("closeDialog");
    },
    printInformations() {

      let printData = [];

      printData.push({
        feed: 1
      });
      printData.push({
        font: "A"
      });
      printData.push({
        align: "center"
      });

      printData.push({
        fontSize: 2
      });

      printData.push({
        reverse: "on"
      });

      printData.push({
        text: geol.t('erp.lang_item_info')
      });

      printData.push({
        reverse: "off"
      });

      printData.push({
        feed: 1
      });

      printData.push({
        qrCode: this.item.ean
      });

      printData.push({
        feed: 1
      });

      printData.push({
        fontSize: 1
      });

      printData.push({
        align: "left"
      });

      printData.push({
        text: geol.t('erp.lang_posItemName') + ': ' + this.item.name
      });
      printData.push({
        text: geol.t('generic.lang_waregroup') + ': ' + this.itemgroupName
      });

      if (this.item.sellPrice === this.item.originalSellPrice) {
        printData.push({
          text: geol.t('erp.lang_sellPrice') + ': ' + this.item.sellPrice + ' EUR'
        });
      } else {
        printData.push({
          text: geol.t('erp.lang_sellPrice') + ': '
        });
        printData.push({
          reverse: "on"
        });
        printData.push({
          text: '\t\t' + this.item.originalSellPrice + ' EUR'
        });
        printData.push({
          reverse: "off"
        });
        printData.push({
          text: '\t\t' + this.item.sellPrice + ' EUR'
        });

      }

      printData.push({
        text: geol.t('erp.lang_itemStackSellPrice') + ' : ' + this.itemStackSellPrice + ' EUR'
      });

      if (this.description) {
        printData.push({
          text: geol.t('erp.lang_ware_create_desc') + ": " + this.description
        });
      }

      if (this.itemAllergene.length > 0) {
        printData.push({
          feed: 1
        });
        printData.push({
          text: geol.t('erp.lang_Allergene') + ': '
        });

        this.itemAllergene.forEach(allergen => {
          printData.push({
            text: '   * ' + allergen.name,
          });
        });
        printData.push({
          feed: 1
        });
      } else {
        printData.push({
          feed: 1
        });
      }

      if (this.itemAdditives.length > 0) {
        printData.push({
          text: geol.t('erp.lang_Zusatzstoffe') + ': '
        });
        this.itemAdditives.forEach(additive => {
          printData.push({
            text: '   * ' + additive.name,
          });
        });
        printData.push({
          feed: 1
        });
      } else {
        printData.push({
          feed: 1
        });
      }

      printData.push({
        cut: true
      });
      printDataFromPrinter(this.invoicePrinter,printData).then(()=>{

      }).catch((err)=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(()=>{

      })
    },
    getItemStockInfo() {
      this.loadingStockInfo = true
      this.axios.post('get/retail/wareinfo/', {
        wareID: this.item.id,
      }).then(res => {
        if (res.data) {
          this.stockInfo = res.data;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorLoadingStorageData'),
            color: "error"
          });
        }
      }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loadingStockInfo = false;
      })
    },
    async getItemDailyGoods(){
      this.axios.post(ENDPOINTS.ERP.DAILYGOODS.GET,{
        editItemID: this.item.id,
      }).then(res=>{
        this.dailyAmount=res.data.dailyAmount;
      })
    }

  },
  mounted() {
    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
      geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  }

}
</script>

<style>
#product_image > .v-parallax__content {
  padding: 0 !important;
}
</style>