import { render, staticRenderFns } from "./ButtonsSIHOTVoucherDialog.vue?vue&type=template&id=c8b892be&scoped=true&"
import script from "./ButtonsSIHOTVoucherDialog.vue?vue&type=script&lang=js&"
export * from "./ButtonsSIHOTVoucherDialog.vue?vue&type=script&lang=js&"
import style0 from "./ButtonsSIHOTVoucherDialog.vue?vue&type=style&index=0&id=c8b892be&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8b892be",
  null
  
)

export default component.exports