import { mapGetters, mapState } from 'vuex';
import { ENDPOINTS } from '@/config';
import { createVoucherPrintingData } from '@/plugins/printing/printVoucher';
import { createInvoicePrintingData } from '@/plugins/printing/printInvoice';
import { createPaperlesInvoicePrintingData } from '@/plugins/printing/printInvoice';
import { print } from '@/plugins/printing/printerController';
import { roundNumber } from '@/plugins/round';
import { format } from 'mathjs';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCreditCard,
  faCreditCardBlank,
  faEuroSign,
  faHandsHeart,
  faLineColumns,
  faPiggyBank,
  faReceipt,
} from '@fortawesome/pro-light-svg-icons';
import cashoutTSEMixin from '@/mixins/pos/cashout/cashoutTSEMixin';
import { Events } from '@/plugins/events';
import { printDataFromPrinter } from '../../../plugins/printing/printerController';
import { geol } from '../../../main';
// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
// we re gonna use this work arround for now until we find a better way
if (store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
  geol.locale = store.getters['settings/getSettingValue'](
    'geoloc_systemcountry'
  );
}

library.add(
  faCreditCard,
  faCreditCardBlank,
  faHandsHeart,
  faEuroSign,
  faPiggyBank,
  faReceipt,
  faLineColumns
);

export default {
  mixins: [cashoutTSEMixin],

  data() {
    return {
      rules: {
        canSplit: (val) => {
          let amount =
            val !== null ? Number(val.toString().replace(',', '.')) : 0;
          let num = window.parseFloat(amount.toFixed(2));
          let given = window.parseFloat(this.givenSplit.toFixed(2));
          let total = window.parseFloat(this.total.toFixed(2));
          return (
            (num >= 0 && given - num <= total - num) ||
            this.$t('generic.lang_pleaseAdjustTheAmount')
          );
        },
      },
      // -- AFTER SALE DIALOG --
      showAfterPaymentDialog: false,
      // -- ERROR DIALOG --
      errorDialog: false,
      errorDialogShowCancelButton: false,
      errorDialogMessage: '',
      errorDialogCloseAction: '',
      errorDialogLoading: false,
      errorDialogConfirmButtonText: this.$t('generic.lang_next'),
      // ----
      clearIntervalID: null,
      splitPayments: [],
      splitDialog: false,
      paymentIcons: {
        0: 'piggy-bank',
        1: 'euro-sign',
        3: 'credit-card',
        4: 'receipt',
        6: 'hands-heart',
        7: 'line-columns',
        9: 'credit-card-blank',
      },
      adyen: require('@/assets/images/ecterminal/adyen-logo-green.svg'),
      zvt: require('@/assets/images/ecterminal/ZVT.svg'),
      dialogOpen: false,
      ecPaymentRun: 0,
      ecPaymentMsg: '',
      customerNoAvatar: require('@/assets/images/no_avatar.png'),
      showLoadingDialog: true,
      vouchersToPrint: [],
      loading: false,
      bewirtungsbeleg: false,
      email: '',
      sendMail: false,
      moneyGiven: 0,
      moneyGivenTemp: 0,
      invoiceResultData: {},
      invoiceXMLPrintData: [],
      selectedPaymentType: 0,
      savedTotal: 0,
      currency: {
        euro: {
          muenzen: [
            {
              value: 0.01,
              image: require('@/assets/images/currency/euro/1cents.png'),
            },
            {
              value: 0.02,
              image: require('@/assets/images/currency/euro/2cents.png'),
            },
            {
              value: 0.05,
              image: require('@/assets/images/currency/euro/5cents.png'),
            },
            {
              value: 0.1,
              image: require('@/assets/images/currency/euro/10cents.png'),
            },
            {
              value: 0.2,
              image: require('@/assets/images/currency/euro/20cents.png'),
            },
            {
              value: 0.5,
              image: require('@/assets/images/currency/euro/50cents.png'),
            },
            {
              value: 1,
              image: require('@/assets/images/currency/euro/1.png'),
            },
            {
              value: 2,
              image: require('@/assets/images/currency/euro/2.png'),
            },
          ],
          scheine: [
            {
              value: 5,
              image: require('@/assets/images/currency/euro/5.png'),
            },
            {
              value: 10,
              image: require('@/assets/images/currency/euro/10.png'),
            },
            {
              value: 20,
              image: require('@/assets/images/currency/euro/20.png'),
            },
            {
              value: 50,
              image: require('@/assets/images/currency/euro/50.png'),
            },
            {
              value: 100,
              image: require('@/assets/images/currency/euro/100.png'),
            },
          ],
        },
        mad: {
          muenzen: [
            {
              value: 0.5,
              image: require("@/assets/images/currency/dirham/0dot5.png"),
              status: false,
            },
            {
              value: 1,
              image: require("@/assets/images/currency/dirham/1.png"),
              status: false,
            },
            {
              value: 2,
              image: require("@/assets/images/currency/dirham/2.png"),
              status: false,
            },
            {
              value: 5,
              image: require("@/assets/images/currency/dirham/5.png"),
              status: false,
            },
            {
              value: 10,
              image: require("@/assets/images/currency/dirham/10.png"),
              status: false,
            },
          ],
          scheine: [
            {
              value: 20,
              image: require("@/assets/images/currency/dirham/20.png"),
              status: false,
            },
            {
              value: 50,
              image: require("@/assets/images/currency/dirham/50.png"),
              status: false,
            },
            {
              value: 100,
              image: require("@/assets/images/currency/dirham/100.png"),
              status: false,
            },
            {
              value: 200,
              image: require("@/assets/images/currency/dirham/200.png"),
              status: false,
            }
          ]
        }
      },
      randomComponentID: '',
      hotelPayload: null,
      offSite: 0,
      restAsTip: false,
    };
  },

  computed: {
    ...mapState([
      'pos',
      'api',
      'tse',
      'cashierIDs',
      'settings',
      'posLayoutTemplates',
      'touchkeyboard',
      'payments',
      'user',
      'deposit',
    ]),
    currencyIMG(){
      return this.$store.getters['settings/getSettingValue']('geoloc_systemcountry') === "ma"?"mad":"euro";
    },
    ...mapState('printer', {
      printers: (state) => state.printers,
    }),
    ...mapGetters({
      retailGoodsValue: 'pos/retail/goodsValue',
      gastroGoodsValue: 'pos/gastro/goodsValue',
      forcedVOR: 'pos/gastro/forcedVOR',
      fiscalClient: 'tse/fiscalClient',
      activeUser: 'multiUser/activeUser',
    }),
    paperlessInvoiceCashiers() {
      return this.settings.settings.paperlessCashierIDs &&
        this.settings.settings.paperlessCashierIDs.length > 0 &&
        this.settings.settings.paperlessCashierIDs.includes('[') &&
        this.settings.settings.paperlessCashierIDs.includes(']')
        ? JSON.parse(this.settings.settings.paperlessCashierIDs)
        : [];
    },
    disableGlobalCashDrawer() {
      //search for the current cashier
      let current = this.cashierIDs.cashiers.find(
        (cashier) => cashier.id === this.api.auth.cashierID
      );
      if (current) return current.disableCashDrawer === 1;
      else return false;
    },
    canPayWithSplit() {
      return parseFloat(this.givenSplit.toFixed(2)) === parseFloat(this.total);
    },
    remaining() {
      let remain = this.givenSplit - this.total;
      if (remain < 0) {
        return -remain;
      }
      return 0;
    },
    givenSplit() {
      let given = 0;
      this.splitPayments.forEach(function (payment) {
        let amount =
          payment.amount !== null
            ? Number(payment.amount.toString().replace(',', '.'))
            : 0;
        given += amount;
      });
      return given;
    },
    total() {
      let total = 0;

      // CHECK AUFS HAUS
      if (this.selectedPaymentType === 6) return 0;

      this.invoiceItems.forEach((item) => {
        let itemPrice = 0;

        if (item.isVoid || item.amount === 0) {
          return;
        }

        //CHECK DISCOUNT
        let discount = 0;
        if (item.hasOwnProperty('discount')) {
          discount = item.discount;
        }

        //itemPrice = parseFloat((((100 - discount) / 100) * item.sellPrice).toFixed(2));
        //itemPrice = parseFloat((Math.round(((item.sellPrice * ((100 - discount) / 100)) + Number.EPSILON) * 100) / 100));
        itemPrice =
          ((item.sellPrice * ((100 - discount) / 100) + Number.EPSILON) * 100) /
          100;
        itemPrice = format(itemPrice, { precision: 14 });
        itemPrice = roundNumber(itemPrice, 2);

        //CHECK IF DEPOSIT
        if (item.hasOwnProperty('depositgroupID')) {
          if (item.depositgroupID > 0) {
            let deposit = this.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (deposit) itemPrice += deposit.priceTotal;
          }
        }

        let weightCalc = 0.0;
        weightCalc = parseFloat(itemPrice * item.amount);
        weightCalc = format(weightCalc, { precision: 14 });
        weightCalc = roundNumber(weightCalc, 2);

        total += parseFloat(weightCalc.toFixed(2));
      });

      return parseFloat(total.toFixed(2));
    },
    totalWithVoucher() {
      //CHECK IF VOUCHERS VALUE IS GREATER THEN GOODSVALUE
      if (this.goodsValue >= 0.0 && this.vouchersValue >= this.goodsValue) {
        return 0;
      }

      return parseFloat(this.total.toFixed(2));
    },
    offSiteTax() {
      if (this.posType === 'gastro') {
        if (this.settings.settings.hasOwnProperty('offSiteTax')) {
          return parseFloat(this.settings.settings.offSiteTax);
        }
      }

      return 0;
    },
    goodsValue() {
      if (this.posType === 'retail') {
        return this.retailGoodsValue;
      }

      if (this.posType === 'gastro') {
        return this.gastroGoodsValue;
      }

      return 0;
    },
    vouchersValue() {
      let total = 0;

      this.invoiceItems.forEach((item) => {
        if (
          (item.ean.includes('V$') ||
            item.ean.includes('GF$') ||
            item.ean.includes('ZMYLE$')) &&
          !item.isVoid
        ) {
          total += item.sellPrice;
        }
      });

      return total * -1;
    },
    customer() {
      if (this.posType === 'gastro') {
        return this.pos.gastro.customer;
      }

      if (this.posType === 'retail') {
        return this.pos.retail.customer;
      }

      return null;
    },
    project() {
      if (this.posType === 'gastro') {
        return this.pos.gastro.customerProject;
      }

      if (this.posType === 'retail') {
        return this.pos.retail.customerProject;
      }

      return null;
    },
    moneyLeftToPay() {
      let value = this.moneyGiven - this.totalWithVoucher;

      if (value < 0) {
        value = value * -1;

        return value;
      }

      return 0;
    },
    moneyBack() {
      let value = this.moneyGiven - this.totalWithVoucher;

      if (value < 0) {
        return 0;
      }

      return value;
    },
    customerDisplayItems() {
      return this.invoiceItems.map((item) => {
        //CHECK FOR DEPOSIT
        item.depositgroup = null;

        if (item.hasOwnProperty('depositgroupID')) {
          if (item.depositgroupID > 0) {
            //FIND DEPOSIT GROUP
            let depositGroup = this.deposit.deposits.find((depositGroup) => {
              return depositGroup.id === item.depositgroupID;
            });

            if (depositGroup) item.depositgroup = depositGroup;
          }
        }

        return item;
      });
    },
    invoiceItems() {
      let invoiceItems = [];

      if (this.posType === 'retail') {
        invoiceItems = this.pos.retail.orderedItems;
      }

      if (this.posType === 'gastro') {
        //USE OVERWRITE ARRAY -> WHEN WE SPLIT PAY TABLE IN GASTRO POS, OUR INVOICEITEMS ARE PASSING WITH invoiceItemsOverwrite(PROP)
        if (this.invoiceItemsOverwrite.length > 0) {
          invoiceItems = this.invoiceItemsOverwrite;
        } else {
          if (!this.pos.gastro.hasOwnProperty('table')) return invoiceItems;

          if (!this.pos?.gastro?.table?.hasOwnProperty('name'))
            return invoiceItems;

          if (!this.pos.gastro.hasOwnProperty('party')) return invoiceItems;

          if (!this.pos.gastro.party) return invoiceItems;

          if (!this.pos.gastro.party.hasOwnProperty('name'))
            return invoiceItems;

          if (this.pos?.gastro?.table?.name !== 0) {
            if (
              this.pos.gastro.orderedItems.hasOwnProperty(
                this.pos.gastro.party.name
              )
            ) {
              if (
                this.pos.gastro.orderedItems[this.pos.gastro.party.name]
                  .length > 0
              ) {
                invoiceItems =
                  this.pos.gastro.orderedItems[this.pos.gastro.party.name];
              }
            }
          } else {
            if (
              this.pos.gastro.openItems.hasOwnProperty(
                this.pos.gastro.party.name
              )
            ) {
              if (
                this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0
              ) {
                invoiceItems =
                  this.pos.gastro.openItems[this.pos.gastro.party.name];
              }
            }
          }
        }
      }

      return invoiceItems.map((item) => {
        if (this.selectedPaymentType === 6) {
          item.sellPrice = 0;

          item.selectedExtras.map((extra) => {
            extra.sellPrice = 0;

            return extra;
          });
        }

        return item;
      });
    },
    invoiceItemsToPrint() {
      let items = this._.cloneDeep(this.invoiceItems);

      items.forEach((invoiceItem) => {
        //CHECK IF WE HAVE CHOOSEN TAKE AWAY
        if (this.offSite > 0) {
          if (invoiceItem.takeAwayTaxing > 0) {
            invoiceItem.taxValue = this.offSiteTax;
          }
        }

        //DISCOUNT
        if (invoiceItem.discount > 0) {
          //invoiceItem.sellPrice = parseFloat((((100 - invoiceItem.discount) / 100) * invoiceItem.sellPrice).toFixed(2));
          //invoiceItem.sellPrice = parseFloat((Math.round(((invoiceItem.sellPrice * ((100 - invoiceItem.discount) / 100)) + Number.EPSILON) * 100) / 100).toFixed(2));
          invoiceItem.sellPrice =
            ((invoiceItem.sellPrice * ((100 - invoiceItem.discount) / 100) +
              Number.EPSILON) *
              100) /
            100;
          invoiceItem.sellPrice = format(invoiceItem.sellPrice, {
            precision: 14,
          });
          invoiceItem.sellPrice = roundNumber(invoiceItem.sellPrice, 2);
        }
      });

      return items;
    },
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
    multiUser() {
      if (this.settings.settings.hasOwnProperty('posGastroLayoutTemplate')) {
        //FIND TEMPLATE
        let templateID = 0;
        templateID = parseInt(this.settings.settings.posGastroLayoutTemplate);

        if (templateID > 0) {
          let template = this.posLayoutTemplates.templates.some((template) => {
            if (templateID === template.id) {
              return template.multiUser === 1 || template.multiUser === true;
            }
          });
          return template;
        }
      }
      return false;
    },
    activeUserID() {
      if (this.multiUser)
        if (this.activeUser.userID !== null)
          return parseInt(this.activeUser.userID);

      return this.user.userID;
    },
    realUser() {
      if (this.multiUser)
        if (this.activeUser.userID !== null) return this.activeUser;

      return this.user;
    },
    filteredPayments() {
      return (
        Array.isArray(this.payments.payments) ? this.payments.payments : []
      ).filter((payment) => {
        // "AUFS HAUS" PAYMENT ONLY IN GASTRO POS
        if (payment.paymentID === 6)
          return (
            this.posType === 'gastro' &&
            this.$store.getters['permissions/checkPermission'](
              'allows_parMaison'
            )
          );

        // DELIVERYNOTE / LIEFERSCHEIN
        // ONLY WHEN CUSTOMER SELECTED
        if (payment.paymentID === 13) if (this.customer === null) return false;

        if (payment.paymentID === 4) {
          if (
            this.settings.settings.hasOwnProperty(
              'invoiceOnlyIfACustomerSelected'
            ) &&
            this.settings.settings.hasOwnProperty(
              'invoiceOnlyIfUnlockedForInvoices'
            )
          ) {
            if (Number(this.settings.settings.invoiceOnlyIfACustomerSelected))
              if (this.customer) {
                if (
                  Number(
                    this.settings.settings.invoiceOnlyIfUnlockedForInvoices
                  )
                ) {
                  if (!this.customer.bill_allowed) {
                    return false;
                  }
                }
              } else {
                return false;
              }
          }
        }
        return true;
      });
    },
  },

  watch: {
    customer: function (val) {
      //RESET CUSTOMER EMAIL
      this.email = '';

      if (val !== null) {
        if (this.customer.email !== null) {
          if (this.customer.email.length > 0) {
            this.email = this.customer.email;
          }
        }
      }
    },
    dialogOpen(val) {
      if (
        val &&
        this.posType === 'gastro' &&
        this.pos?.gastro?.table?.name !== 0
      ) {
        //
        // SHOW CUSTOMER DISPLAY ITEMS
        // ON GASTRO POS WITH SELECTED TABLE
        // WHEN OPENING CASHOUT DIALOG
        //
        this.$socket.emit('customerdisplay.updateItems', {
          cashierID: this.api.auth.cashierID,
          items: this.customerDisplayItems,
        });
      }
    },
  },

  beforeDestroy() {
    if (this.posType === 'gastro' && this.pos?.gastro?.table?.name !== 0)
      this.$socket.emit('customerdisplay.changePage', {
        cashierID: this.api.auth.cashierID,
      });
  },

  mounted() {
    this.prepareSplitPaymentInputs();

    // SOMETIMES WE USE v-if on this component
    // SO THEN SET open-on-mounted prop
    if (this.openOnMounted) {
      this.dialogOpen = this.openOnMounted;
      //this.moneyGiven = this.totalWithVoucher;
    }
  },

  methods: {
    openCashDrawer() {
      let printData = [
        {
          cashDrawer: 1,
        },
      ];
      printDataFromPrinter(this.invoicePrinter, printData)
        .then(() => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_success'),
            color: 'success',
          });
        })
        .catch((err) => {
          Events.$emit('showSnackbar', {
            message: this.$t('generic.lang_errorOccurred'),
            color: 'error',
          });
        })
        .finally(() => {});
    },
    async getPickupName() {
      await this.axios
        .post(ENDPOINTS.POS.GASTRO.PICKUPNAMES)
        .then((res) => {
          if (res.data.status) {
            this.$store.dispatch(
              'pos/gastro/setPickup',
              res.data.pickUpNumber + ''
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    giveMoney(value) {
      if (this.moneyGivenTemp === 0) this.moneyGiven = 0;

      this.moneyGiven += value;

      this.moneyGivenTemp = value;
    },
    giveMoneyAndPay(money) {
      this.moneyGiven = money;

      if (this.moneyLeftToPay > 0) {
        this.dialogOpen = true;
        return;
      }

      this.selectPayment(1);
    },
    rest(payment) {
      let amount =
        payment.amount !== null
          ? Number(payment.amount.toString().replace(',', '.'))
          : 0;
      if (this.remaining + amount <= this.total) {
        payment.amount = (this.remaining + amount).toFixed(2);
      } else {
        payment.amount = 0;
      }
    },
    async selectPayment(paymentType) {
      this.savedTotal = this.total;

      if (!this.dialogOpen && this.moneyGiven === 0) {
        this.moneyGiven = this.totalWithVoucher;
      }

      if (
        paymentType === 1 &&
        parseFloat(this.moneyGiven).toFixed(2) < this.total
      ) {
        // ONLY DO THIS CHECK WHEN NO HOTEL PMS IS ACTIVE
        if (this.hotelPayload === null) {
          this.showErrorDialog(this.$t('generic.lang_notGivenEnoughMoney'), false, 'close_only');
          return;
        }
      }

      this.loading = true;

      this.selectedPaymentType = paymentType;

      // CHECK IF PAYMENTTYPE IS DELIVERYNOTE
      if (paymentType === 13) {
        this.selectDeliveryNotePayment();
        return;
      }

      let postData = {
        saleAction: 'CASHOUT',
        paymentType: paymentType,
        totalPrice: this.total,
        goodsValue: this.goodsValue,
        progressToken: this.pos.progressToken,
        OrderArray: this.invoiceItems,
        moneyGiven: this.moneyGiven,
        restAsTip: this.restAsTip,
      };

      if (this.posType === 'gastro') {
        if (this.pos.gastro.freeText !== null) {
          postData.globalFreeText = this.pos.gastro.freeText;
        }
      }
      if (this.posType === 'retail') {
        if (this.pos.retail.freeText !== null) {
          postData.globalFreeText = this.pos.retail.freeText;
        }
      }

      // -------------------------------------------------------------------------
      // ------------------- ADYEN / ZVT PAYMENT ---------------------------------
      let needECPayment = false;
      let ecPayValue = 0;

      if (paymentType === 3 || paymentType === 9) {
        needECPayment = true;
        ecPayValue = this.total;
        //EC TIPP RULE
        if (this.moneyGiven > this.total && this.restAsTip === true) {
          ecPayValue = this.moneyGiven;
        }
      } else if (paymentType === 7) {
        // CHECK SPLIT PAYMENT
        let ecPayment = this.splitPayments.find((split) => split.id === 3);

        if (ecPayment) {
          if (parseFloat(ecPayment.amount) > 0) {
            needECPayment = true;
            ecPayValue = ecPayment.amount;
          }
        }
      }

      if (needECPayment) {
        //ZVT
        if (this.payments.ecPaymentType === 1 && this.ecPaymentRun === 0) {
          this.$refs.zvtDialog.makePayment(parseFloat(ecPayValue));
          return;
        }

        //ADYEN
        if (this.payments.ecPaymentType === 2 && this.ecPaymentRun === 0) {
          this.$refs.adyenDialog.makePayment(parseFloat(ecPayValue));
          return;
        }
      }

      // ----- CUSTOMER CARD PAYMENT ------
      if (paymentType === 12) {
        try {
          const ccResponse = await this.axios.post(
            ENDPOINTS.POS.SALE.PRECHECKPAYWITHCUSTOMERCARD,
            {
              customerID: this.customer.id,
              total: this.total,
            }
          );

          if (!ccResponse.data.success) {
            this.loading = false;
            this.showErrorDialog(ccResponse.data.msg, true, 'close_only');
            return;
          }
        } catch (err) {
          this.loading = false;
          this.showErrorDialog(
            this.$t('generic.lang_customerCardPaymentCouldNotBeCarriedOut'),
            false,
            'close_only'
          );
          return;
        }
      }

      postData.ecPaymentMsg = this.ecPaymentMsg;
      // -------------------------------------------------------------------------
      // -------------------------------------------------------------------------

      //ADD POS VERSION + TABLE/PARTY NAME(GASTRO), SETTING TAKEAWAY AND ADDITIONAL STATISTICS
      if (this.posType === 'gastro') {
        postData.posVersion = 'GASTRO';

        // CHECK IF DELIVERY POS AND SET POS VERSION TO DELIVERY
        if (this.pos.gastro.isDeliveryService) {
          postData.posVersion = 'DELIVERY';
          if (!this.pos.gastro.delivery) {
            postData.driverID = -1;
          }
        }

        let currentCashierOptions = this.cashierIDs.cashiers.filter(
          (cashier) => cashier.id === this.api.auth.cashierID
        );
        //   if (currentCashierOptions.length > 0) {
        //    if (currentCashierOptions[0].inHouseTakeAwayRequired === 1) {
        //       postData.takeAway = this.offSite;
        //     } else if (currentCashierOptions[0].takeAwayStandard === 1) {
        postData.takeAway = this.offSite;
        //    }
        //   }

        if (this.table === null) {
          postData.tableName = 0;
        } else {
          postData.tableName = this.pos?.gastro?.table?.name;
          if (this.pos?.gastro?.table?.hasOwnProperty('roomName')) {
            postData.tableRoomName = this.pos?.gastro?.table?.roomName;
          }
          if (this.pos?.gastro?.table?.hasOwnProperty('source')) {
            if (this.pos?.gastro?.table?.name.includes('delivery_')) {
              postData.orderSource =
                this.pos?.gastro?.table?.source === 'pos' ? 'pos' : 'localbee';
            } else {
              postData.orderSource =
                this.pos?.gastro?.table?.source === 'pos' ? 'pos' : 'tablebee';
            }
          }
        }

        postData.partyName = this.pos.gastro.party.name;

        if (
          this.pos.gastro.additionalStatisticUUIDs &&
          this.pos.gastro.additionalStatisticUUIDs.length > 0
        ) {
          postData.additionalStatisticUUIDs =
            this.pos.gastro.additionalStatisticUUIDs;
          postData.OrderArray.forEach((item) => {
            item.additionalStatisticUUIDs =
              this.pos.gastro.additionalStatisticUUIDs;
          });
        }

        //SET FISKALY POST DATA:
        if (this.pos.gastro.transactionPayload) {
          postData.fiskalyPayload = this.pos.gastro.transactionPayload;
        }
      } else if (this.posType === 'retail') {
        postData.posVersion = 'RETAIL';
        postData.orderID = this.pos.retail.savedOrderID;

        //SET FISKALY POST DATA:
        if (this.pos.retail.transactionPayload) {
          postData.fiskalyPayload = this.pos.retail.transactionPayload;
        }

        //CHECK FOR ADDITIONAL STATISTICS

        if (
          this.pos.retail.additionalStatisticUUIDs &&
          this.pos.retail.additionalStatisticUUIDs.length > 0
        ) {
          postData.additionalStatisticUUIDs =
            this.pos.retail.additionalStatisticUUIDs;
          postData.OrderArray.forEach((item) => {
            item.additionalStatisticUUIDs =
              this.pos.retail.additionalStatisticUUIDs;
          });
        }
      }

      postData.newOrderArray = this.newOrderArray;

      //MultiUser CASE
      if (this.multiUser) {
        if (this.activeUser.userID !== null) {
          postData.userIDOverwite = this.activeUser.userID;
        }
      }

      //CUSTOMER
      if (this.customer !== null) {
        postData.customerID = this.customer.id;
      }

      //PROJECT SETTINGS
      if (this.project !== null) postData.projectData = this.project;

      //SPLIT CASE
      if (paymentType === 7) {
        let splitObj = {};

        this.splitPayments.forEach((el) => {
          let amount =
            el.amount !== null
              ? Number(el.amount.toString().replace(',', '.'))
              : 0;
          if (amount > 0) {
            splitObj[el.id] = amount;
          }
        });
        postData.splitPaymentObject = splitObj;
      }

      //CHECK FOR HS3 / IBELSA / SUITE8 PAYMENT
      if (
        paymentType === 100 ||
        paymentType === 101 ||
        paymentType === 102 ||
        paymentType === 103
      ) {
        postData.hotelPayload = this.hotelPayload;
      } else if (this.hotelPayload !== null) {
        // CHECK IF WE ARE USING HOTEL PMS BUT PAYING WITH PAYMENT METHOD
        this.hotelPayload.selectedPaymentMethod = paymentType;
        this.selectPayment(this.hotelPayload.internPMSPaymentType);
        return;
      }

      // CHECK IF SUITE8 IS ACTIVE BUT NO ROOM SELECTED
      if (
        this.hotelPayload === null &&
        this.$store.getters['permissions/checkModule'](45)
      ) {
        this.hotelPayload = {
          selectedPaymentMethod: paymentType,
          roomID: null,
          payAsGuest: true,
        };

        this.selectPayment(102);
        return;
      }

      //CHECK IF WE NEED TO RE ACTIVATE THE ORDER
      if (this.$route.query.hasOwnProperty('reActivateSale')) {
        postData.returnSaleID = this.$route.query.reActivateSale;
      }

      if (this.pos?.gastro?.table?.name === 0) {
        if (Number(this.settings.settings.pickup_enable)) {
          await this.getPickupName();
        }
      }

      //GET FISKALY TRANSACTION DATA

      //SEND API REQUEST
      this.axios
        .post(ENDPOINTS.POS.SALE.CREATE, postData)
        .then((res) => {
          if (res.data.status === 'PROGRESS_TOKEN') {
            // UPDATE PROGRESS TOKEN
            this.$store.commit(
              'pos/setProgressToken',
              res.data.apiProgressToken
            );
            this.loading = false;
            //FOR NOW DEACTIVATE TO INVESTIGATE DOUBLE BOOKINGS
            //                    this.selectPayment(paymentType);
          } else if (res.data.status === 'SUCCESS') {
            //TABLE UUID
            let tableName = null;

            if (this.posType === 'gastro')
              if (this.pos.gastro.table !== null)
                if (this.pos?.gastro?.table?.hasOwnProperty('uuid'))
                  tableName = this.pos?.gastro?.table?.name;

            //CLEAR AGE VERIFICATION
            this.$store.commit('pos/setVerifiedAge', 0);

            //KUNDENDISPLAY, ALSO FOR TABLEBEE

            let data = {
              cashierID: this.api.auth.cashierID,
              moneyGiven: this.moneyGiven,
              paymentType: paymentType,
              posType: this.posType,
              tableName: tableName,
            };

            // adding paperless invoice settings data for customer display
            if (this.$store.getters['permissions/checkModule'](62)) {
              if (
                Number(this.settings.settings.enablePaperlessInvoice) === 1 &&
                Number(this.settings.settings.displayPaperlessInvoiceQr) ===
                  1 &&
                this.settings.settings.paperlessCashierIDs?.includes(
                  this.api.auth.cashierID
                )
              ) {
                data.invoiceUUID = res.data.invoiceUUID;
                data.displayPaperlessInvoiceQr =
                  Number(this.settings.settings.displayPaperlessInvoiceQr) ===
                  1;
                data.paperlessInvoiceMSG =
                  this.settings.settings.paperlessInvoiceMSG || '';
                data.servicesSubDomainName =
                  this.settings.settings.servicesSubDomainName;
              }
            }

            if (
              Object.keys(this.$store.state.pos.gastro.orderUUIDS).length > 0
            ) {
              if (
                this.pos.gastro.orderUUIDS.hasOwnProperty(
                  this.pos.gastro.party.name
                )
              )
                data.orderUUID =
                  this.pos.gastro.orderUUIDS[this.pos.gastro.party.name].uuid;
            }

            this.$socket.emit('pos.checkOut', data);

            // UPDATE PROGRESS TOKEN
            this.$store.commit(
              'pos/setProgressToken',
              res.data.apiProgressToken
            );

            this.invoiceResultData = res.data;

            //PRINT ORDERBON
            this.saleCreated();
          } else if (res.data.status === 'WARNING') {
            // UPDATE PROGRESS TOKEN
            this.$store.commit(
              'pos/setProgressToken',
              res.data.apiProgressToken
            );

            this.showErrorDialog(
                this.$t('generic.lang_saleWasSavedIn3POSButErrorsOccurred') + ' (' +
                res.data.msg +
                ')',
              false,
              ''
            );
            this.loading = false;
          } else {
            // CHECK PROGRESS TOKEN
            if (res.data.hasOwnProperty('apiProgressToken')) {
              // UPDATE PROGRESS TOKEN
              this.$store.commit(
                'pos/setProgressToken',
                res.data.apiProgressToken
              );
            }

            this.showErrorDialog(
                this.$t('generic.lang_saleCouldNotBeCompleted') + ' (' +
                res.data.msg +
                ')',
              false,
              'close_only'
            );
            this.loading = false;
          }
        })
        .catch((err) => {
          this.showErrorDialog(err.message, false, 'close_only');
        });
    },
    selectDeliveryNotePayment() {
      let payload = {
        customerId: this.customer.id,
        deliveryNoteObj: this.invoiceItems,
        total: this.totalWithVoucher,
        deliveryNoteRef: '',
      };
      payload.newOrderArray = this.newOrderArray;
      // CHECK IF GASTRO POS
      // THEN ADD TABLE + PARTY NAME
      if (this.posType === 'gastro') {
        if (this.pos?.gastro?.table?.name !== 0) {
          payload.tableName = this.pos?.gastro?.table?.name;
          payload.partyName = this.pos.gastro.party.name;
        }
        if (this.pos?.gastro?.table?.hasOwnProperty('roomName')) {
          payload.tableRoomName = this.pos?.gastro?.table?.roomName;
        }
      }

      this.axios
        .post(ENDPOINTS.BILLING.DELIVERYNOTES.CREATE, payload)
        .then((res) => {
          if (res.data.status === 'SUCCESS') {
            /*
                    SKIP TSE
                    BECAUSE PAYMENTTYPE IS DELIVERY NOTE AND WE DONT NEED THE FISCAL

                    SKIP VOUCHER PRINTING

                    SKIP ORDERBON PRINTING
                    */
            this.loadingPrinting = true;

            let printData;

            try {
              //GENERATE INVOICE DATA
              let data = {
                saleID: 0,
                cashierID: this.api.auth.cashierID,
                userID: this.realUser.userID,
                ts: Math.floor(Date.now() / 1000),
                moneyGiven: 0,
                paymentType: 1,
              };

              if (this.posType === 'gastro') {
                data['tableNo'] = this.pos?.gastro?.table?.name;
              }

              if (this.posType === 'retail') {
                data['tableNo'] = '';
              }

              printData = createInvoicePrintingData(
                this.invoiceItemsToPrint,
                data,
                this.invoicePrinter.options,
                this.realUser,
                false,
                true
              );

              //CHECK IF LAST ELEMENT IS CUT
              let lastElememt = printData[printData.length - 1];

              if (!lastElememt.hasOwnProperty('cut'))
                printData.push({ cut: true });
            } catch (err) {
              this.loading = false;
            }
            // EPSON EPOS PRINT
            printDataFromPrinter(this.invoicePrinter, printData)
              .then(() => {
                this.showAfterSaleDialog();
              })
              .catch((err) => {
                this.showErrorDialog(
                  this.$t('generic.lang_deliveryNoteCouldNotBePrinted'),
                  false,
                  'show_after_sale_dialog'
                );
              })
              .finally(() => {
                this.loadingPrinting = false;
              });
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    saleCreated() {
      //CHECK IF TSE DEVICE IS AVAILABLE
      if (this.fiscalClient !== null) {
        if (this.fiscalClient.device.type === null) {
          this.fiscalClient.device.type = 'epsonTSE';
        }
        if (this.fiscalClient.device.type === 'fiskaly') {
          if (this.invoiceResultData.fiskalQRCode !== undefined && this.invoiceResultData.fiskalQRCode !== null) {
            if(this.invoiceResultData.fiskalQRCode.length < 20){
              this.printWithoutTSE(true);
            }else{
              this.printWithoutTSE();
            }
          }else{
            this.printWithoutTSE(true);
          }
        } else {
          this.checkTSEPrint();
        }
      } else {
        this.printWithoutTSE();
      }
    },
    adyenFinished(axiosPayload) {
      this.ecPaymentRun = 1;
      this.ecPaymentMsg = axiosPayload.msg;

      //RECALL SELECT PAYMENT FUNCTION
      this.selectPayment(this.selectedPaymentType);
    },
    zvtFinished(axiosPayload) {
      this.ecPaymentRun = 1;
      this.ecPaymentMsg = axiosPayload;

      //RECALL SELECT PAYMENT FUNCTION
      this.selectPayment(this.selectedPaymentType);
    },
    printWithoutTSE(withTSEerror = false) {
      //CHECK IF INVOICE PRINTER EXISTS

      if (!this.invoicePrinter) {
        this.checkVoucher();
        return;
      }

      //RESET INVOICE ERROR MESSAGE
      this.errorInvoicePrintDialogMessage = '';

      this.loadingPrinting = true;

      //GENERATE INVOICE DATA

      let data = {
        saleID: this.invoiceResultData.invoiceID,
        cashierID: this.invoiceResultData.cashierID,
        userID: this.invoiceResultData.userID,
        ts: this.invoiceResultData.ts,
        moneyGiven: this.moneyGiven, //this.invoiceResultData.moneyGiven,
        paymentType: this.invoiceResultData.paymentType,
        splitPayments: this.splitPayments,
        invoiceUUID: this.invoiceResultData.invoiceUUID,
      };

      if (this.posType === 'gastro') {
        data['tableNo'] = this.pos?.gastro?.table?.name;
        if (this.pos.gastro.isDeliveryService) {
          data.isDelivery = this.pos.gastro.delivery;
          data.serviceTime = this.pos.gastro.serviceTime;
          data.isAsap = !this.forcedVOR;
        }
      }

      //console.log(data)

      if (this.posType === 'retail') {
        data['tableNo'] = '';
      }

      //TRY TO CREATE PRINT DATA
      let printData;
      let paperlessInvoicePrintData;
      try {
        if (
          this.$store.getters['permissions/checkModule'](62) &&
          Number(this.settings.settings.enablePaperlessInvoice) === 1 &&
          Number(this.settings.settings.printSmallReceipt) === 1 &&
          !this.pos.gastro.isDeliveryService &&
          Number(this.settings.settings.disableReceiptPrint) === 0 &&
          this.paperlessInvoiceCashiers?.includes(this.api.auth.cashierID)
        ) {
          paperlessInvoicePrintData = createPaperlesInvoicePrintingData(
            this.invoiceItemsToPrint,
            data,
            this.invoicePrinter.options,
            this.realUser,
            false
          );
        }

        printData = createInvoicePrintingData(
          this.invoiceItemsToPrint,
          data,
          this.invoicePrinter.options,
          this.realUser,
          false,
          false,
          this.goodsValue,
          this.vouchersValue
        );

        //BEWIRTUNGSBELEG
        if (this.bewirtungsbeleg) {
          let today = new Date();
          let current_date =
            today.getDate() +
            '.' +
            (today.getMonth() + 1) +
            '.' +
            today.getFullYear();
          let current_city = '';

          if (this.settings.settings.hasOwnProperty('systemCity'))
            current_city = this.settings.settings.systemCity;

          printData.push({
            text:
              '\n-----------------------------------\n' +
              geol.t('generic.lang_hospitalityExpensesInformationAccordingTo') +
              ' \n' +
              geol.t('generic.lang_paragraph4Paragraph5Number2EStG') +
              ' \n-----------------------------------\n' +
              geol.t('generic.lang_hostedPersons') +
              ':\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n\n' +
              geol.t('generic.lang_occasionOfTheHospitality') +
              ':\n...................................\n\n...................................\n\n\n' +
              geol.t('generic.lang_amountOfExpenditure') +
              ':\n\n...................................\n' +
              geol.t('generic.lang_whenCateringInTheRestaurant') +
              '\n\n...................................\n' +
              geol.t('generic.lang_inOtherCases') +
              '\n\n\n' +
              geol.t('generic.lang_date_place') +
              ':' +
              current_date +
              ' / ' +
              current_city +
              '\n\n...................................\n' +
              geol.t('generic.lang_signature') +
              '\n\n\n\n',
          });
          //printData.push({
          //   text: "\nAngaben zum Nachweis der Hoehe und der \nbetrieblichen Veranlassung von \nBewirtungsaufwendungen Par. 4 Abs. 5\nTag der Bewirtung:" + current_date + "\nBewirtete Personen:\n\n\n\n\n\n\n\nAnlass der Bewirtung:\n\n\n\n\nDatum / Ort:" + current_date + " / " + current_city + "\nUnterschrift:\n\n\n\n"
          // });
        }

        if (withTSEerror == true) {
          printData.push({
            text: '\n' + geol.t('accounting.lang_tseIsUnreachable') + '\n'
          });
        } else {
          let fiskalQrCode = this.invoiceResultData.fiskalQRCode;
          let fiskalData = this.invoiceResultData.fiskalData;

          if (fiskalData !== null) {
            printData.push({
              fontSize: 1,
            });

            printData.push({
              font: 'B',
            });
            printData.push({
              text:
                geol.t('accounting.lang_eazSerNr') +
                ': ' +
                fiskalData.client_id,
            });
            printData.push({
              text:
                geol.t('accounting.lang_tseSerNr') +
                ': ' +
                fiskalData.tss_serial_number,
            });
            printData.push({
              text:
                geol.t('accounting.lang_tseStart') +
                ': ' +
                fiskalData.time_start,
            });
            printData.push({
              text:
                geol.t('accounting.lang_tseStop') + ':  ' + fiskalData.time_end,
            });
            printData.push({
              text:
                geol.t('accounting.lang_tseTrxNr') + ': ' + fiskalData.number,
            });
            printData.push({
              text:
                geol.t('accounting.lang_tseSigCount') +
                ':' +
                fiskalData.signature.counter,
            });
            printData.push({
              text:
                geol.t('accounting.lang_signatur') +
                ':  ' +
                fiskalData.signature.value,
            });

            printData.push({
              align: 'center',
            });

            //ADD QR CODE
            printData.push({
              qrCode: fiskalQrCode,
            });
          }
        }

        // ADD ZVT PRINTING
        printData = this.addZVTPrinting(printData);
        // CHECK IF CASH DRAWER SHOULD NOT BE OPENED WHEN PAYING WITH EC
        if (
          this.disableGlobalCashDrawer ||
          ((this.selectedPaymentType === 3 || this.selectedPaymentType === 9) &&
            parseInt(
              this.$store.getters['settings/getSettingValue'](
                'ecPaymentOpenCashDrawer'
              )
            ) === 0)
        ) {
          // THEN GET INDEX OF OPENCASHDRAWER ELEMENT AND REMOVE IT
          let cashDrawerElementIndex = printData.findIndex((el) => {
            return el.hasOwnProperty('cashDrawer');
          });

          // REMOVE OPEN CASH DRAWER ELEMENT
          this.$delete(printData, cashDrawerElementIndex);
        }

        // CHECK IF LAST ELEMENT IS CUT
        let lastElememt = printData[printData.length - 1];
        if (!lastElememt.hasOwnProperty('cut')) printData.push({ cut: true });

        // SAVE PRINT DATA
        this.invoiceXMLPrintData = printData;
      } catch (err) {
        console.log(err);

        this.loadingPrinting = false;
        this.showErrorDialog(
            this.$t('generic.lang_printReceiptError'),
          false,
          'check_vouchers'
        );
        return;
      }

      //paperless invoice settings check
      if (
        this.$store.getters['permissions/checkModule'](62) &&
        Number(this.settings.settings.enablePaperlessInvoice) === 1 &&
        Number(this.settings.settings.disableReceiptPrint) === 1 &&
        !this.pos.gastro.isDeliveryService &&
        this.paperlessInvoiceCashiers?.includes(this.api.auth.cashierID)
      ) {
        if (
          !(
            this.disableGlobalCashDrawer ||
            ((this.selectedPaymentType === 3 ||
              this.selectedPaymentType === 9) &&
              parseInt(
                this.$store.getters['settings/getSettingValue'](
                  'ecPaymentOpenCashDrawer'
                )
              ) === 0)
          )
        )
          this.openCashDrawer();

        this.checkVoucher();
        return;
      }

      let dataToPrint;
      if (
        this.$store.getters['permissions/checkModule'](62) &&
        Number(this.settings.settings.enablePaperlessInvoice) === 1 &&
        Number(this.settings.settings.printSmallReceipt) === 1 &&
        !this.pos.gastro.isDeliveryService &&
        Number(this.settings.settings.disableReceiptPrint) === 0 &&
        this.paperlessInvoiceCashiers?.includes(this.api.auth.cashierID)
      ) {
        dataToPrint = paperlessInvoicePrintData;
      } else {
        dataToPrint = printData;
      }

      // CHECKING CASHIER OPTIONS SETTINGS FOR DIRECT SALE RECEIPT PRINTING
      if (
        this.settings.settings.disableDirectSaleReceiptPrint === '1' &&
        this.posType === 'gastro' &&
        this.pos?.gastro?.table?.name === 0
      ) {
        this.loadingPrinting = false;
        this.checkVoucher();
        return;
      }

      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter, dataToPrint)
        .then(() => {
          this.checkVoucher();
        })
        .catch((err) => {
          this.showErrorDialog(
            this.$t('generic.lang_printReceiptError') + '  (' + err + ')',
            false,
            'check_vouchers'
          );
        })
        .finally(() => {
          this.loadingPrinting = false;
        });
    },
    checkVoucher() {
      //CHECK IF VOUCHERS WERE CREATED
      if (this.invoiceResultData.vouchers.length > 0) {
        //CHECK IF INVOICE PRINTER IS SETUP
        if (!this.invoicePrinter) {
          this.showErrorDialog(
            this.$t('generic.lang_vouchersWereSoldButNoCashRegisterPrinterWasSetUp')
          );
          return;
        }

        this.vouchersToPrint = this.invoiceResultData.vouchers;

        //PRINT ALL VOUCHERS
        this.invoiceResultData.vouchers.forEach((voucher) => {
          //GET VOUCHER PRINTING DATA
          let printVouchersData = createVoucherPrintingData(
            voucher.voucherUUID,
            voucher.voucherValue
          );

          //SEND DATA TO PRINTER
          // EPSON EPOS PRINT
          // EPSON EPOS PRINT
          printDataFromPrinter(this.invoicePrinter, printVouchersData)
            .then(() => {
              this.finishCashout();
            })
            .catch((err) => {
              this.showErrorDialog(
                this.$t('erp.lang_voucher') + ' ' +
                  voucher.voucherUUID +
                  this.$t('generic.lang_couldNotBePrinted') + ' ',
                true,
                'reprint_vouchers',
                'Retry'
              );
            })
            .finally(() => {
              this.loadingPrinting = false;
            });
        });
      } else {
        this.finishCashout();
      }
    },
    reprintVouchers() {
      this.errorDialogLoading = true;

      //PRINT ALL VOUCHERS
      this.vouchersToPrint.forEach((voucher) => {
        //GET VOUCHER PRINTING DATA
        let printVouchersData = createVoucherPrintingData(
          voucher.voucherUUID,
          voucher.voucherValue
        );

        //SEND DATA TO PRINTER
        // EPSON EPOS PRINT
        printDataFromPrinter(this.invoicePrinter, printVouchersData)
          .then(() => {
            this.finishCashout();
          })
          .catch((err) => {
            this.showErrorDialog(
                this.$t('generic.lang_vouchersCouldNotBePrinted'),
              true,
              'reprint_vouchers',
              'Retry'
            );
          })
          .finally(() => {});
      });
    },
    addZVTPrinting(printData) {
      // --------------------------- EC PRINTING --------------------------
      if (this.ecPaymentMsg.hasOwnProperty('printing')) {
        if (this.ecPaymentMsg.printing.hasOwnProperty('customer')) {
          // printData.push({cut: true});
          // REMOVE LAST CUT
          if (this.ecPaymentMsg.printing.customer.length > 1) {
            let lastElement = printData[printData.length - 1];
            if (!lastElement.hasOwnProperty('cut'))
              this.$delete(printData, printData.length - 1);

            printData.push({ align: 'center' });
            printData.push({ feed: 2 });

            // LOOP EC PRINTING DATA
            this.ecPaymentMsg.printing.customer.forEach((printElement) => {
              if (printElement.trim().length === 0) return;

              printData.push({ text: printElement });
            });

            printData.push({ feed: 4 });
          }
        }

        // HÄNDLERBELEG
        if (this.ecPaymentMsg.printing.hasOwnProperty('salesperson')) {
          printData.push({ cut: true });
          if (this.ecPaymentMsg.printing.salesperson.length > 1) {
            printData.push({ feed: 2 });

            // LOOP EC PRINTING DATA
            this.ecPaymentMsg.printing.salesperson.forEach((printElement) => {
              if (printElement.trim().length === 0) return;

              printData.push({ text: printElement });
            });

            printData.push({ feed: 4 });
            printData.push({ cut: true });
          }
        }
      }
      // ---------------------------------------------------------------

      return printData;
    },
    finishCashout() {
      if (this.posType === 'gastro' && this.pos?.gastro?.table?.name === 0) {
        this.$refs.printOrderBon
          .printOrderBons()
          .then(() => {
            //this.finalFinishCashout();
            this.showAfterSaleDialog();
          })
          .catch(() => {
            this.$refs.printOrderBon.$once('cancelReprint', () => {
              //this.finalFinishCashout();
              this.showAfterSaleDialog();
            });

            this.$refs.printOrderBon.$once('reprintFinished', () => {
              //this.finalFinishCashout();
              this.showAfterSaleDialog();
            });
          });
      } else {
        //this.finalFinishCashout();
        this.showAfterSaleDialog();
      }
    },
    finalFinishCashout() {
      this.loading = false;

      //RESET EC TERMINAL DATA
      this.ecPaymentRun = 0;
      this.ecPaymentMsg = '';

      this.moneyGiven = 0;

      this.prepareSplitPaymentInputs();
      this.removeCustomer();
      this.clearCashier();

      if (this.splitDialog) {
        this.toggleSplitDialog();
      }
    },

    clearCashier() {
      if (this.posType === 'gastro') {
        this.$store.dispatch('pos/gastro/setPagerNo', null);
        this.$store.commit('pos/gastro/setAdditionalStatistic', []);
        this.$store.commit('pos/gastro/setTakeAway', 0);
        this.$store.commit('pos/gastro/setTransactionPayload', {});
        this.$store.dispatch('pos/setCourse', {
          posType: this.posType,
          course: null,
        });

        if (this.pos?.gastro?.table?.name === 0) {
          //CLEAR BOOKED ITEMS
          this.$store.dispatch('pos/gastro/setOpenItems', {
            party: this.pos.gastro.party,
            orders: [],
          });

          if (this.$vuetify.breakpoint.smAndDown) {
            this.$emit('closeDialog');
            this.$emit('redirect', 'send_invoice');
            this.$emit('setPaymentResponse', {
              invoiceResultData: this.invoiceResultData,
              invoiceXMLPrintData: this.invoiceXMLPrintData,
            });
            this.$store.dispatch('pos/gastro/setServiceTime', null);
          } else {
            this.$emit('closeDialog');
            this.$store.dispatch('pos/gastro/setServiceTime', null);
            if (!this.split) {
              /*this.$router.push({
                                name: "pos.gastro.tresenverkauf"
                            });*/

              Events.$emit('resetGrid');
            }
          }
        } else {
          //CLEAR BOOKED ITEMS
          this.$store.dispatch('pos/gastro/setOrderedItems', {
            partyName: this.pos.gastro.party.name,
            orders: [],
          });

          if (this.$vuetify.breakpoint.smAndDown) {
            this.$emit('closeDialog');
            this.$emit('redirect', 'send_invoice');
            this.$emit('setPaymentResponse', {
              invoiceResultData: this.invoiceResultData,
              invoiceXMLPrintData: this.invoiceXMLPrintData,
            });
            this.$store.dispatch('pos/gastro/setServiceTime', null);
          } else {
            this.$emit('closeDialog');

            if (!this.split) {
              // CHECK IF RFID CASHIER IS ACTIVE
              if (this.pos.gastro.isDeliveryService) {
                this.$router
                  .replace({
                    name: 'pos.delivery',
                    query: null,
                  })
                  .then(() =>
                    this.$store.dispatch('pos/gastro/setServiceTime', null)
                  );
              } else if (this.$store.getters['permissions/checkModule'](50)) {
                this.$router
                  .replace({
                    name: 'pos.rfid',
                  })
                  .then(() =>
                    this.$store.dispatch('pos/gastro/setServiceTime', null)
                  );
              } else {
                this.$router
                  .replace({
                    name: 'pos.gastro.tables',
                  })
                  .then(() =>
                    this.$store.dispatch('pos/gastro/setServiceTime', null)
                  );
              }
            }
          }
        }
      }

      if (this.posType === 'retail') {
        //CLEAR BOOKED ITEMS
        this.$store.commit('pos/retail/setAdditionalStatistic', []);
        this.$store.commit('pos/retail/reset');
        this.$emit('closeDialog');
      }
    },
    removeCustomer() {
      if (this.posType === 'gastro') {
        this.$store.dispatch('pos/gastro/setCustomer', null);
        this.$store.dispatch('pos/gastro/setCustomerProject', null);
      }

      if (this.posType === 'retail') {
        this.$store.dispatch('pos/retail/setCustomer', null);
        this.$store.dispatch('pos/retail/setCustomerProject', null);
      }
    },
    showErrorDialog(
      message,
      showCancelButton = false,
      closeAction = null,
      confirmButtonText = null
    ) {
      this.errorDialogLoading = false;
      this.errorDialogShowCancelButton = false;

      //CHECK IF ARE ON MOBILE POS AND SHOULD SHOW THE BOOTSTRAP TOAST
      if (closeAction === 'close_only') {
        this.$bvToast.toast(message, {
          title: 'error',
          autoHideDelay: 4000,
          variant: 'danger',
        });

        return;
      }

      this.errorDialogMessage = message;
      this.errorDialogShowCancelButton = showCancelButton;

      if (closeAction !== null) this.errorDialogCloseAction = closeAction;
      else this.errorDialogCloseAction = '';

      if (confirmButtonText !== null)
        this.errorDialogConfirmButtonText = confirmButtonText;
      else this.errorDialogConfirmButtonText = this.$t('generic.lang_next');

      //OPEN ERROR DIALOG
      this.errorDialog = true;
    },
    showErrorSnackBox(message, closeAction = null) {
      Events.$emit('showSnackbar', {
        message: message,
        color: 'cyan darken-2',
      });

      if (closeAction !== null) this.errorDialogCloseAction = closeAction;
      else this.errorDialogCloseAction = '';

      this.closeErrorDialog();
    },
    closeErrorDialog() {
      this.errorDialog = false;

      if (this.errorDialogCloseAction === 'close_only') return;

      if (this.errorDialogCloseAction === 'check_vouchers') {
        this.checkVoucher();
        return;
      }

      if (this.errorDialogCloseAction === 'reprint_vouchers') {
        this.reprintVouchers();
        return;
      }

      if (this.errorDialogCloseAction === 'print_without_tse') {
        this.printWithoutTSE(true);
        return;
      }

      if (this.errorDialogCloseAction === 'show_after_sale_dialog') {
        this.showAfterSaleDialog();
        return;
      }

      this.finishCashout();
    },
    cancelErrorDialog() {
      this.finishCashout();
    },
    hs3OccupationSelected(occupation) {
      this.hotelPayload = {
        occupationID: occupation.occupationid,
      };

      this.selectPayment(100);
    },
    hs3OccupationGuestSelected(payload) {
      this.hotelPayload = {
        occupationID: payload.occupation.occupationid,
        arrivingGuestID: payload.guest.arriving_guestid,
      };

      this.selectPayment(100);
    },
    ibelsaRoomSelected(room) {
      this.hotelPayload = {
        roomID: room.id,
      };

      this.selectPayment(101);
    },
    suite8RoomSelected(payload) {
      this.hotelPayload = {
        internPMSPaymentType: 102,
        roomID: payload.room.RoomNum,
        payAsGuest: false,
      };

      // SHOULD WE SETTLE WITH PAYMENT?
      if (!payload.selectPayment) this.selectPayment(102);
    },
    sihotRoomSelected(payload) {
      // SET HOTEL PAYLOAD
      this.hotelPayload = {
        internPMSPaymentType: 103,
        accountno: payload.room.accountno,
        roomID: payload.room.roomno,
        guestData: {
          adress: payload.room.adress,
          firstname: payload.room.firstname,
          lastname: payload.room.lastname,
        },
        payAsGuest: false,
      };

      // SHOULD WE SETTLE WITH PAYMENT?
      if (!payload.selectPayment) this.selectPayment(103);
    },
    sihotPayAsGuest() {
      this.hotelPayload = {
        internPMSPaymentType: 103,
        accountno: null,
        roomID: null,
        guestData: null,
        payAsGuest: true,
      };
    },
    prepareSplitPaymentInputs() {
      let arr = [];
      this.filteredPayments.forEach((payment) => {
        if (!(payment.paymentID === 7 || payment.paymentID === 4)) {
          let obj = {
            id: payment.paymentID,
            label: payment.paymentName,
            amount: 0,
          };
          arr.push(obj);
        }
      });
      this.splitPayments = arr;
    },
    toggleSplitDialog() {
      this.splitDialog = !this.splitDialog;
    },
    showAfterSaleDialog() {
      if (this.settings.settings.disableAfterSaleDialog === '1') {
        this.finalFinishCashout();
      } else {
        this.showAfterPaymentDialog = true;

        // this.finalFinishCashout(); // WHY?

        /*show after sale dialog for mobile version*/
        if (this.$vuetify.breakpoint.smAndDown) {
          this.$emit('closeDialog');
          this.$emit('redirect', 'send_invoice');
          this.$emit('setPaymentResponse', {
            invoiceResultData: this.invoiceResultData,
            invoiceXMLPrintData: this.invoiceXMLPrintData,
          });
        }
      }
    },
  },
};
