<template>
  <v-layout wrap>
    <v-card color="#FAFBFC" class="flex" flat>
      <v-card-text style="padding: 0 !important;">
        <v-container fluid style="padding: 0 !important;">
          <v-layout :style="{
              backgroundColor: this.$vuetify.theme.dark? '#1E1E1E' : ''
            }" wrap>
            <v-flex lg7 md6 class="text-xs-center">
              <v-layout wrap>
                <v-flex md10>
                  <Parties v-if="posType==='gastro'" :pos-type="posType" :ordering="ordering"/>
                </v-flex>

                <v-flex md2>
                  <div style="float: right; width: 100%; margin-right: 1px;">
                    <div @click="paginateUp"
                         style="background-color: #1e3c72; height: 37px; color: white;text-align:center;">
                      <font-awesome-icon style="transform: scale(1.5,1); font-size: 35px;"
                                         :icon="['fal', 'chevron-up']"/>
                    </div>

                    <div @click="paginateDown"
                         style="background-color: #1e3c72; height: 37px; margin-top: 1px; color: white;text-align:center;">
                      <font-awesome-icon style="transform: scale(1.5,1); font-size: 35px;"
                                         :icon="['fal', 'chevron-down']"/>
                    </div>
                  </div>
                </v-flex>
              </v-layout>
            </v-flex>


            <v-flex lg3 md3 class="text-xs-center">
              <div class="grid-menu grid-menu-3col">
                <div class="no-gutters row">
                  <!-- cashout button -->
                  <div class="col-sm-4 dark col-xl-4">
                    <button v-if="showCashoutButton" :disabled="isBooking" @click="triggerCashoutDialog"
                            class="btn-icon-vertical btn-square btn-transition btn btn-outline-primary active"
                            style="text-transform: uppercase;height: 75px;">
                                            <span v-if="!showCashoutDialog && !loadingCashoutBtn">
                                                <font-awesome-icon icon="euro-sign" size="2x"/>
                                                <br>
                                            </span>

                      <span v-else>
                                                <v-progress-circular color="white" indeterminate/>
                                            </span>
                    </button>
                  </div>
                  <!-- end: cashout button -->


                  <div class="col-sm-4 col-xl-4">
                    <FooterButtonOrder :enabled="showOrderButton || canOrder" :pos-type="posType"/>
                  </div>
                  <div v-if="posType !== 'retail'" class="col-sm-4 col-xl-4">
                    <button @click="backTo()" :disabled="ordering"
                            class="btn-icon-vertical btn-square btn-transition btn active btn-outline-danger"
                            style="text-transform: uppercase;height: 75px;">
                      <span>
                        <font-awesome-icon
                        :icon="['fal', 'concierge-bell']" :class="[ordering? 'grey--text' : '']" size="2x"/><br>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </v-flex>


            <v-flex lg2 md3>
              <v-container fill-height fluid>
                <v-layout>
                  <v-flex class="text-xs-right">
                    <p class="totalPrice">{{ $t('generic.lang_total') }}: {{
                        totalPrice | currency
                      }}</p>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <CheckStockCount ref="checkStockCount" :pos-type="posType"></CheckStockCount>
    <CashoutDialog v-if="showCashoutDialog" open-on-mounted :show-dialog="showCashoutDialog" :pos-type="posType"
                   @closeDialog="showCashoutDialog = false"/>

    <inHouseTakeAwayChooser :pos-type="posType" ref="inHouseDialog" @inHouseTakeAway="inHouseTakeAway"
                            @selectAdditionals="selectAdditionals"/>
  </v-layout>
</template>

<style scoped>
.totalPrice {
  font-family: ANTON;
  font-weight: bold;
  color: #D92550;
  letter-spacing: 1px;
  text-align: center;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
}

@media (max-width: 1024px) {
  .totalPrice {
    font-size: 20px;
  }
}

@media (min-width: 1025px) {
  .totalPrice {
    font-size: 23px;
  }
}
</style>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {ENDPOINTS} from "@/config";

import {faEuroSign, faReceipt} from '@fortawesome/free-solid-svg-icons'

import {faChevronDown, faChevronUp, faConciergeBell, faHeadset} from '@fortawesome/pro-light-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import FooterButtonOrder from "./FooterButtonOrder";
import CashoutDialog from "./cashout/CashoutDialog";
import Parties from "./Parties";
import {mapGetters, mapState} from "vuex";
import inHouseTakeAwayChooser from "./inHouseTakeAwayChooser";
import CheckStockCount from "@/components/pos/cashout/CheckStockCount";
import bookingevents from "../../mixins/pos/bookingevents";
import {Events} from "../../plugins/events";

library.add(
    faReceipt,
    faChevronUp,
    faChevronDown,
    faEuroSign,
    faHeadset,
    faConciergeBell
);

export default {
  name: "Footer",
  mixins: [bookingevents],
  props: {
    posType: String,
    canOrder: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    CheckStockCount,
    inHouseTakeAwayChooser,
    CashoutDialog,
    Parties,
    FooterButtonOrder,
    'font-awesome-icon': FontAwesomeIcon
  },

  data() {
    return {
      showCashoutDialog: false,
      showPartyAddDialog: false,
      table: {},
      loadingCashoutBtn: false,
      ordering: false,
    }
  },

  computed: {
    ...mapGetters({
      gastroTotalPrice: 'pos/gastro/totalPrice',
      gastroTable: 'pos/gastro/tableName',
      retailTotalPrice: 'pos/retail/totalPrice',
      user: 'user/user',
    }),
    ...mapState({
      pos: 'pos',
      current: 'api',
      cashiers: 'cashierIDs',
    }),
    showOrderButton() {
      if (this.posType !== "gastro") {
        return false;
      }

      if (this.pos.gastro.table === null) {
        return true;
      }

      if (this.pos.gastro.table.name !== 0) {
        if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
          if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    showCashoutButton() {
      if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          return true;
        }
      }

      if (this.posType === "gastro") {
        //DIRECT SALE
        if (this.pos.gastro.table !== null) {
          if (this.pos.gastro.table.name === 0) {
            if (this.pos.gastro.party !== null) {
              if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                  //return (this.table.soldBy === this.user.userID);
                  return true;
                }
              }
            }
          } else {
            //TABLEPLAN
            if (this.pos.gastro.party !== null) {
              // CHECK IF RFID CASHIER ACTIVE
              if (this.$store.getters["permissions/checkModule"](50) && !this.$store.getters["permissions/checkPermission"]("allow_cashoutRfidTag"))
                return false;

              if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                //CHECK ALL PARTIES, IF WE HAVE OPEN ITEMS
                let hasOpenItems = false;

                for (let party in this.pos.gastro.openItems) {
                  if (!this.pos.gastro.openItems.hasOwnProperty(party))
                    continue;

                  if (this.pos.gastro.openItems[party].length > 0)
                    hasOpenItems = true;
                }

                if (hasOpenItems)
                  return false;
              }

              if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                  return true;
                }
              }
            }
          }
        }
      }

      return false;
    },
    totalPrice() {
      if (this.posType === "gastro") {
        return this.gastroTotalPrice;
      }

      if (this.posType === "retail") {
        return this.retailTotalPrice;
      }

      return 0;
    },

  },

  methods: {
    paginateDown() {
      this.$root.$emit("pos." + this.posType + ".paginateDown");
    },
    paginateUp() {
      this.$root.$emit("pos." + this.posType + ".paginateUp");
    },
    getTableOrders() {
      if (this.posType === "gastro") {
        if (this.gastroTable !== null) {
          if (this.gastroTable !== 0) {
            this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then((res) => {
              if (res.status === 200) {
                this.table = this.getTable(this.gastroTable, res.data.tables);
              }
            }).catch((err) => {

            });
          }
        }

      }
    },
    getTable(name, from) {
      for (let i = 0, l = from.length; i < l; i++) {
        if (from[i].name.toString() === name.toString()) {
          return from[i];
        }
      }
      return null;
    },
    async triggerCashoutDialog() {
      if (this.showCashoutButton) {
        //CHECK STOCK COUNT
        try {
          this.loadingCashoutBtn = true;

          await this.$refs.checkStockCount.checkSaleStock();
        } catch (err) {
          //STOCK NOT SUFFICIENT
          return;
        } finally {
          this.loadingCashoutBtn = false;
        }

        //CHECK IF INHOUSE DIALOG NEEDS TO BE OPEN
        let currentCashierOptions = this.cashiers.cashiers.filter(cashier => cashier.id === this.current.auth.cashierID);
        if (this.posType === 'gastro') {
          if (currentCashierOptions[0].inHouseTakeAwayRequired === 1 || currentCashierOptions[0].askForAdditionalStatistics === 1) {
            this.$refs.inHouseDialog.show();
          } else {
            this.showCashoutDialog = true;
          }
        } else {
          if (currentCashierOptions[0].askForAdditionalStatistics === 1) {
            this.$refs.inHouseDialog.show();
          } else {
            this.showCashoutDialog = true;
          }
        }

      }
    },
    selectAdditionals(data) {
      if (this.posType === 'gastro') {
        this.$store.commit('pos/gastro/setAdditionalStatistic', data);
      } else {
        this.$store.commit('pos/retail/setAdditionalStatistic', data);
      }
      this.showCashoutDialog = true;
    },
    inHouseTakeAway(state) {
      this.$store.commit(
          'pos/gastro/setTakeAway',
          (state === 'in') ? 0 : 1
      );
      this.showCashoutDialog = true;
    },
    backTo() {

      //checking if we re coming from delivery in thes case of rfid module active
      if (this.$store.getters["permissions/checkModule"](50)) {
        this.$router.replace({
          name: 'pos.rfid'
        });

        return;
      }

      //deciding where to go back based on where we ve been

      let route = '/pos/gastro/tables';

      if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
        if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
          this.$swal({
            title: this.$t('generic.lang_warning'),
            text: this.$t('generic.lang_warning_unorderedItems'),
            icon: "warning",
            showCancelButton: true,
            showLoaderOnConfirm: true,
            confirmButtonText: this.$t('generic.lang_next'),
            cancelButtonText: this.$t('generic.lang_cancel'),
            preConfirm: () => {
              this.$router.replace(route);
            },
            allowOutsideClick: () => !this.$swal.isLoading,
          });
        } else {
          this.$router.replace(route);
        }
      } else {
        this.$router.replace(route);
      }
    }
  },

  mounted() {
    this.getTableOrders();

    Events.$on('ordering', (status) => {
      this.ordering = status;
    });
  },
  beforeDestroy() {
    Events.$off('ordering');
  }
}
</script>
