<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="90%" width="500">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('generic.lang_pager') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn :disabled="loading" icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12">

              <v-text-field v-model="pagerNo" outlined filled :loading="loading" :disabled="loading"
                            :label="$t('cashier.lang_pagerNumber')" type="number" aria-autocomplete="none"/>

              <NumpadComponent numpadOnly ref="numpad" @updateValue="numpadUpdateValue" @resetField="numpadResetField"
                               disableComma :disabled="loading"></NumpadComponent>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn :disabled="loading" color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>

        <template v-if="$store.getters['permissions/checkModule'](69)&&$store.getters['settings/getSettingValue']('enable_ratiotec_pager')==='1'">
          <v-btn  color="warning" @click="callPager" :disabled="(!pagerNo || pagerNo === '') || loading" :loading="loading">{{ $t('generic.lang_callPager') }}</v-btn>
          <v-btn  color="success" @click="setPagerNo" :disabled="(!pagerNo || pagerNo === '') || loading">{{ $t('generic.lang_next') }}</v-btn>
        </template>
        <v-btn v-else color="success" @click="setPagerNo" :disabled="(!pagerNo || pagerNo === '')">{{ $t('generic.lang_next') }}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import {mapState} from "vuex";
import NumpadComponent from "../NumpadComponent";
import {ENDPOINTS} from "@/config/";
import {Events} from "../../../plugins/events";

export default {
  name: "ButtonsPagerDialog",
  components: {
    NumpadComponent,
  },
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    posType: String,

  },
  data() {
    return {
      pagerNo: null,
      dialog: false,
      loading:false,
    }
  },
  watch: {
    showDialog(val) {
      this.dialog = val;
      if (val) {
        this.pagerNo = this.$store.state.pos.gastro.pagerNo;
      }else{
        clearInterval(this.timerID);
      }
    }
  },
  computed: {
    ...mapState(['pos']),
  },
  methods: {
    closeDialog() {
      this.pagerNo = '';
      this.$emit("closeDialog");
    },
    callPager(){
      this.loading = true;
      this.$store.dispatch('pos/gastro/setPagerNo', this.pagerNo);
      this.axios.post(ENDPOINTS.POS.GASTRO.CALLRATIOTECPAGER,{
        pagerNumber:this.pagerNo,
      }).then((res) => {
        this.closeDialog();
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "warning"
        });
      }).finally(()=>{
        this.loading = false;
      })
    },
    numpadUpdateValue(value) {
      this.pagerNo = value;
    },
    numpadResetField() {
      this.pagerNo = '';
    },
    setPagerNo() {
      this.$store.dispatch('pos/gastro/setPagerNo', this.pagerNo);
      this.$refs.numpad.resetField();
      this.closeDialog();
    }
  },
  destroyed() {
    clearInterval(this.timerID);
  }
}
</script>

<style scoped>

</style>