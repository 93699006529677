import store from "@/store";
import Vue from "vue";
import {geol} from "../../main.js";
import {formatCurrency} from "../currencyFormater";

// since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it

if(store.getters['settings/getSettingValue']('geoloc_systemcountry')){
    geol.locale = store.getters['settings/getSettingValue']('geoloc_systemcountry');
}
export function createVoucherPrintingData(voucherID, voucherValue) {
    let printObj = [];

    //GET SETTINGS
    let settings = store.getters["settings/settings"];

    //VOUCHER BASIC LAYOUT:
    printObj.push({"align": "center"});
    printObj.push({"printerLogo": "true"});
    printObj.push({"text": settings.system_name});
    printObj.push({"text": settings.systemStreetNo});
    printObj.push({"text": settings.systemZip+" "+settings.systemCity});
    printObj.push({"text": geol.t('erp.lang_TaxID')+": "+settings.taxID});
    printObj.push({"feed": 2});
    printObj.push({"fontSize": 2});
    printObj.push({"text": geol.t('erp.lang_voucher')});
    printObj.push({"text": geol.t('erp.lang_voucher_balance')+":"});
    printObj.push({"text": "- "+formatCurrency(voucherValue)+" -"});
    printObj.push({"fontSize": 1});
    printObj.push({"feed": 2});
    printObj.push({"qrCode": voucherID});
    printObj.push({"text": voucherID});
    printObj.push({"feed": 3});
    printObj.push({"cut": "true"});

    return printObj;
}