<template>
  <swipe-out :disabled="false"
             class="elevation-3" style="height: 54px !important;position: fixed; bottom: 0; left: 0; right: 0;">
    <v-bottom-navigation
        id="main-bottom-nav"
        :value="value"
        :background-color="this.$vuetify.theme.dark? '' : 'white'"
        class="mx-0 ma-0 text-center"
        color="#FF6969"
        fixed

    >
      <v-btn class="mx-auto bottom-btns ma-0 pt-2 pl-2- text-center" @click="redirect('home')">
        <span class="pt-2 pb-2 pl-4">
          {{ $t('generic.lang_home') }}
        </span>
        <font-awesome-icon :icon="['fal', 'home']" class="ml-4" style="font-size: 16px"/>
      </v-btn>

      <v-btn v-if="tab==='items'" class="mx-auto bottom-btns ma-0 pt-2" @click="redirect('options')">
        <span class="pt-2 pb-2">
          {{ $t('generic.lang_Options') }}
        </span>
        <font-awesome-icon :icon="['fal', 'sliders-v-square']" style="font-size: 16px"/>
      </v-btn>

      <v-btn v-if="tab==='home'" fab small dark class="mx-auto ma-0 pt-2" color="#FF6969" @click="showAddTable">
        <v-icon color="white">add</v-icon>
      </v-btn>

      <v-btn v-if="tab!=='send_invoice'" class="mx-auto bottom-btns ma-0 pt-2" @click="redirect('payment')">
        <span class="pt-2 pb-2 pr-3">
          {{ $t('generic.lang_pay') }}
        </span>
        <font-awesome-icon :icon="['fal', 'euro-sign']" class="mr-3" style="font-size: 16px"/>
      </v-btn>

      <!-- In house take away & additional statistics -->

      <in-house-take-away-chooser :pos-type="posType" ref="inHouseDialog" @inHouseTakeAway="inHouseTakeAway"
                                  @selectAdditionals="selectAdditionals"/>


      <!--      <v-btn class="mx-auto bottom-btns ma-0 pt-2" @click="redirect('customer')">
              <span class="pt-2 pb-2">
                Customer
              </span>
              <font-awesome-icon :icon="['fal', 'user']"
                                 size="2x"
                                 style="font-size: 16px"/>
            </v-btn>-->

      <v-btn v-if="tab==='items'" class="mx-auto bottom-btns ma-0 pt-2" @click="coursesDialog = true">
        <span class="pt-2 pb-2">
          {{ $t('generic.lang_course') }}
        </span>
        <font-awesome-icon :icon="['fal', courseIcon]" style="font-size: 16px;"/>
      </v-btn>

      <MobileCourseDialog :pos-type="this.posType" :show-dialog="coursesDialog" @closeDialog="coursesDialog = false"/>

    </v-bottom-navigation>

    <template v-slot:right="{close}">
      <v-bottom-navigation
          id="secondary-bottom-navigation"
          :value="value"
          class="mx-0 ma-0"
          color="white"
      >
        <buttons-print-users-report class="d-inline-block" :pos-type="posType"/>
        <buttons-print-daily-report class="d-inline-block" :pos-type="posType"/>

        <v-btn id="close-bottom-swiper" class="ma-0 swipeout-action" small style="width: 20px !important"
               @click="close">

          <font-awesome-icon :icon="['fal', 'chevron-right']" class="text-danger"
                             size="2x"
                             style="font-size: 25px"/>
        </v-btn>
      </v-bottom-navigation>

    </template>
  </swipe-out>
</template>

<script>


import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import ButtonsPrintUsersReport from "@/components/pos/buttons/ButtonsPrintUsersReport";
import {
  faChevronRight,
  faCog,
  faConciergeBell,
  faCreditCard,
  faEdit,
  faEuroSign,
  faHome,
  faSlidersVSquare,
  faTimes,
  faUser
} from '@fortawesome/pro-light-svg-icons'
import {SwipeOut} from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import {mapState} from 'vuex';
import MobileCourseDialog from "@/components/pos/mobile/wedgets/MobileCourseDialog";
import ButtonsPrintDailyReport from "@/components/pos/buttons/ButtonsPrintDailyReport";
import InHouseTakeAwayChooser from "@/components/pos/inHouseTakeAwayChooser";
import {Events} from "@/plugins/events";


library.add(faEuroSign, faConciergeBell, faUser, faCog, faHome, faChevronRight, faTimes, faEdit, faCreditCard, faSlidersVSquare);

export default {
  name: "BottomBar",
  props: {
    tab: {
      type: String,
      required: true
    },
    posType: {
      type: String,
      required: true
    },
  },
  components: {
    InHouseTakeAwayChooser,
    MobileCourseDialog,
    'font-awesome-icon': FontAwesomeIcon,
    SwipeOut,
    ButtonsPrintUsersReport,
    ButtonsPrintDailyReport,

  },
  data: () => {
    return {
      value: "",
      coursesDialog: false,
    }
  },
  computed: {
    ...mapState({
      pos: 'pos',
      current: 'api',
      cashiers: 'cashierIDs',
    }),
    courseIcon() {
      if (this.pos.gastro.selectedCourse === null) {
        return 'bell'
      } else {
        if (this.pos.gastro.selectedCourse.courseIcon === null || this.pos.gastro.selectedCourse.courseIcon === '') {
          return 'utensils-alt';
        } else {
          return this.pos.gastro.selectedCourse.courseIcon;
        }
      }
    },
    canPay() {
      if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          return true;
        }
      }

      if (this.posType === "gastro") {

        //DELIVERY

        if (this.pos.gastro.isDeliveryService) {
          if (this.$route.query.hasOwnProperty('useDeliveryService')) {
            if (this.$route.query.useDeliveryService) {
              if (this.$route.query.hasOwnProperty('checkout')) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          }
        }

        //DIRECT SALE
        if (this.pos.gastro.table !== null) {
          if (this.pos.gastro.table.name === 0) {
            if (this.pos.gastro.party !== null) {
              if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                  //return (this.table.soldBy === this.user.userID);
                  return true;
                }
              }
            }
          }
          //TABLEPLAN
          else {
            if (this.pos.gastro.party !== null) {
              // CHECK IF RFID CASHIER ACTIVE
              if (this.$store.getters["permissions/checkModule"](50) && !this.$store.getters["permissions/checkPermission"]("allow_cashoutRfidTag"))
                return false;

              if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                //CHECK ALL PARTIES, IF WE HAVE OPEN ITEMS
                let hasOpenItems = false;

                for (let party in this.pos.gastro.openItems) {
                  if (!this.pos.gastro.openItems.hasOwnProperty(party))
                    continue;

                  if (this.pos.gastro.openItems[party].length > 0)
                    hasOpenItems = true;
                }

                if (hasOpenItems)
                  return false;
              }

              if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                  return true;
                }
              }
            }
          }
        }
      }

      return false;
    },
  },
  methods: {
    showAddTable() {
      this.$emit('showAddTable')
    },
    redirect(route) {


      if (route === "payment") {
        if (!this.canPay) {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_canntPayNow'),
            color: "warning"
          });
          return;
        }
        //CHECK IF INHOUSE DIALOG NEEDS TO BE OPEN
        let currentCashierOptions = this.cashiers.cashiers.filter(cashier => cashier.id === this.current.auth.cashierID);
        if (this.posType === 'gastro') {
          if (currentCashierOptions[0].inHouseTakeAwayRequired === 1 || currentCashierOptions[0].askForAdditionalStatistics === 1) {
            this.$refs.inHouseDialog.show();
          } else {
            this.$emit('input', route);
          }
        } else {
          if (currentCashierOptions[0].askForAdditionalStatistics === 1) {
            this.$refs.inHouseDialog.show();
          } else {
            this.$emit('input', route);
          }
        }
      } else {
        this.$emit('input', route);
      }
    },
    selectAdditionals(data) {
      if (this.posType === 'gastro') {
        this.$store.commit('pos/gastro/setAdditionalStatistic', data);
      } else {
        this.$store.commit('pos/retail/setAdditionalStatistic', data);
      }
      this.$emit('input', "payment");
    },
    inHouseTakeAway(state) {
      this.$store.commit(
          'pos/gastro/setTakeAway',
          (state === 'in') ? 0 : 1
      );
      this.$emit('input', "payment");
    },
  }
}
</script>

<style scoped>
#secondary-bottom-navigation {
  background-image: -webkit-gradient(linear, left top, right top, from(#ff6969), to(#ff696985)) !important;
  background-image: linear-gradient(to left top, #ff6969, #ff696985) !important;
}
</style>
