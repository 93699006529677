<template>
  <div>
  <v-dialog v-model="showDialog" persistent scrollable max-width="500">
    <v-card :disabled="loading" :loading="loading">
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_save_order') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text v-if="isCartEmpty && !loading">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert text color="primary">{{ $t('generic.lang_saveOrderTextEmptyCart') }}</v-alert>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-text v-else>
        <v-container>
          <v-row>
            <v-col cols="12">
              <p class="text-center" style="font-size: 17px;"> {{ $t('generic.lang_saveOrderText') }}</p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="saveOrder" v-if="!isCartEmpty">{{ $t('erp.lang_save_order') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>


  <!-- TSE Error Dialog -->
  <v-dialog :value="tseErrorMessage.length > 0" max-width="290" persistent>
    <v-card>
      <v-card-title>{{ $t('generic.lang_tseError') }}</v-card-title>
      <v-card-text class="text-center">{{ tseErrorMessage }}</v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="primary" text @click="closeTSEErrorDialog">{{ $t('generic.lang_understand') }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {ENDPOINTS} from "../../../config";
import {Events} from "@/plugins/events";

export default {
  name: "ButtonsSaveOrderDialog",

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object
  },

  data() {
    return {
      loading: false,
      tseErrorMessage: ""
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    ...mapGetters({
      gastroTotalPrice: 'pos/gastro/totalPrice',
      retailTotalPrice: 'pos/retail/totalPrice',
      fiscalClient: 'tse/fiscalClient'
    }),
    isCartEmpty() {
      if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          return false;
        }
      }

      return true;
    },
    customerID() {
      if (this.posType === "retail") {
        if (this.pos.retail.customer !== null) {
          return this.pos.retail.customer.id;
        }
      }

      return 0;
    }
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.loading = false;
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    saveOrder() {
      if (this.posType === "retail") {
        this.loading = true;

        //SET FISKALY POST DATA:
        let fiskalyData = "";
        if (this.pos.retail.transactionPayload){
           fiskalyData = this.pos.retail.transactionPayload;
        }

        this.axios.post(ENDPOINTS.POS.RETAIL.ORDER.CREATE, {
          items: this.pos.retail.orderedItems,
          transactionData: fiskalyData,
          customerID: this.customerID,
          orderID: this.pos.retail.savedOrderID,
          totalPrice: this.retailTotalPrice
        }).then((res) => {
          if (res.data.success) {
            //CHECK FISCAL CLIENT
            if (this.fiscalClient !== null && this.fiscalClient.device.type !== "fiskaly") {
              this.finishTransaction();
            }else {
              this.finishSavingOrder();
              //RESET FISKALY TRANSACTION
              this.$store.commit('pos/retail/setTransactionPayload', {});
            }
          }
        }).catch((e) => {
          this.loading = false;
        })
      }
    },

    // TSE FUNCTIONS
    finishTransaction() {
      let tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

      if (tseDevice === null)
        this.tseErrorMessage = this.$t('generic.lang_unknownError') + "...";

      if (!tseDevice.tseReady && tseDevice.connected)
        this.tseErrorMessage = this.$t('generic.lang_tseIsNotOperational');

      if (!tseDevice.tseReady && !tseDevice.connected)
        this.tseErrorMessage = this.$t('generic.lang_tseIsNotOperational');


      tseDevice.finishOrderbonTransaction(this.fiscalClient.clientID, this.pos.retail.transactionPayload, this.pos.retail.orderedItems);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (!payload.result.result === "EXECUTION_OK") {
            this.tseErrorMessage = payload.result.result;
          }
          else {
            this.finishSavingOrder();
          }
        }
      });
    },
    closeTSEErrorDialog() {
      this.tseErrorMessage = "";
      this.finishSavingOrder();
    },

    finishSavingOrder() {
      if(this.posType === "retail")
        this.$store.commit("pos/retail/reset");

      if(this.posType === "gastro")
        this.$store.commit('pos/gastro/setTransactionPayload', {}); // RESET TSE PAYLOAD

      this.closeDialog();
    }
  }
}
</script>