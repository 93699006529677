<template>
  <v-dialog v-model="open_dialog" class="mx-0" max-width="600" scrollable>
    <v-card :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="rounded-lg mx-auto ma-auto" style="border-radius: 25px !important;" width="100%">
      <v-progress-linear v-if="this.loading" :color="this.colors.primary" indeterminate/>
      <v-card-text class="pa-0 px-0">
        <div class="grid-menu grid-menu-3col">
          <v-row align="center" class="pa-0 ma-0 mx-0" no-gutters>
            <v-col class="text-center" cols="4">
              <b-button :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition"
                        @click="redirect('customer')">
                <font-awesome-icon
                    :icon="['fal', 'users']"
                    class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                    v-bind:style="{color: this.colors.primary}"
                />
                <span class="icons_text">
                  {{ this.$t('customers.lang_customers') }}
                  </span>
              </b-button>
            </v-col>

            <v-col class="text-center" cols="4">
              <b-button :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition" @click="scanner"
              >
                <font-awesome-icon
                    :icon="['fal', 'barcode-read']"
                    class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                    v-bind:style="{color: this.colors.primary}"
                />
                <span class="icons_text">
                  {{ $t('generic.scan') }}
                </span>
              </b-button>
            </v-col>


            <v-col class="text-center" cols="4">
              <b-button :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition font-size-sm"
                        @click="$emit('changeTable') & redirect('home')"
              >
                <font-awesome-icon
                    :icon="['fal', 'loveseat']"
                    class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                    v-bind:style="{color: this.colors.primary}"
                />
                <span class="icons_text">
                  {{ $t('generic.lang_changeTable') }}
                </span>
              </b-button>
            </v-col>


            <v-col class="text-center" cols="4">
              <!--
                            <b-button class="ma-auto btn-icon-vertical text-muted btn-square btn-transition bg-white font-size-sm"
                            >
                              <font-awesome-icon
                                  :icon="['fal', 'file-invoice-dollar']"
                                  class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                                  v-bind:style="{color: this.colors.primary}"
                              />
                              <span class="icons_text">
                                Zwischenrechnung
                                </span>
                            </b-button>
              -->

              <buttons-print-interim-bill @printed="interimBillPrinted" :colors="this.colors" :pos-type="this.posType"
                                          show-button-text/>
            </v-col>
            <v-col class="text-center" cols="4">
              <b-button :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition font-size-sm"
                        @click="openSplit"
              >
                <v-icon
                    :color="this.colors.primary"
                    class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                >call_split
                </v-icon>
                <span class="icons_text">
                  {{ $t('generic.lang_split_table') }}
                </span>
              </b-button>
            </v-col>
            <v-col class="text-center" cols="4">
              <b-button :class="[this.$vuetify.theme.dark? 'dark-bg' : 'bg-white']" class="ma-auto btn-icon-vertical text-muted btn-square btn-transition font-size-sm"
                        @click="openMerge"
              >
                <font-awesome-icon
                    :icon="['fal', 'glass-cheers']"
                    class="btn-icon-wrapper ma-auto btn-icon-md icons mb-2"
                    v-bind:style="{color: this.colors.primary}"
                />
                <span class="icons_text">
                  {{ $t('generic.lang_fold') }}
                </span>
              </b-button>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
      <v-card-actions class="pa-0 px-0 ma-0 mx-0" style="max-height: 0px !important; max-width: 0px !important;">
        <buttons-table-merge ref="mergeTableBtn" :pos-type="this.posType" hide-button/>
        <buttons-table-split ref="splitTableBtn" :pos-type="this.posType" hide-button/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";


import {library} from '@fortawesome/fontawesome-svg-core';

import {faBarcodeRead, faFileInvoiceDollar, faGlassCheers, faLoveseat, faUsers} from '@fortawesome/pro-light-svg-icons'
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

import {mapState} from 'vuex'
import ButtonsTableMerge from "@/components/pos/buttons/ButtonsTableMerge";
import ButtonsTableSplit from "@/components/pos/buttons/ButtonsTableSplit";
import ButtonsPrintInterimBill from "@/components/pos/buttons/ButtonsPrintInterimBill";

library.add(faUsers, faBarcodeRead, faLoveseat, faFileInvoiceDollar, faGlassCheers);
export default {
  name: "MobileOptionsDialog",
  components: {
    ButtonsPrintInterimBill,
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsTableMerge,
    ButtonsTableSplit
  },
  props: {
    posType: {
      type: String,
      required: true
    },
    colors: {
      type: Object,
      required: true
    },
    dialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      enableCameraInApp: false,
      loading: false,
      camera: false
    }
  },
  mounted() {
    this.getData();
  },
  methods: {

    interimBillPrinted() {
      this.open_dialog = false;
    },
    openSplit() {
      this.$refs.splitTableBtn.openDialog();
    },
    openMerge() {
      this.$refs.mergeTableBtn.openDialog()
    },
    scanner() {
      if (this.enableCameraInApp) {
        //console.log("camera");
        this.camera = true;
      } else {
        //console.log("optic scanner", this.enableCameraInApp)
      }
    },
    getData() {
      this.loading = true;
      this.axios.post(ENDPOINTS.SETTINGS.BASIC.SYSTEMSETTINGS.CASHIER.OPTIONS.GET,
          {cashierID: this.api.auth.cashierID})
          .then(res => {
            if (res.data.status === "SUCCESS") {
              this.enableCameraInApp = res.data.settings.enableCameraInApp === 1;
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error",
              });
            }
          }).catch(err => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(fin => {
        this.loading = false;
      })
    },
    redirect(route) {
      this.$emit('redirect', route)
      this.open_dialog = false;
    }
  },
  computed: {
    ...mapState([
      'api'
    ]),
    open_dialog: {
      get() {
        return this.dialog;
      },
      set(val) {
        this.$emit('input', val);
      }
    }
  },
  watch: {}
}
</script>

<style scoped>

.icons_text {
  font-size: 11px !important;
}
</style>
