<template>
  <div>
    <v-dialog v-if="this.$vuetify.breakpoint.smAndDown" v-model="showDialog" max-width="600px" persistent scrollable>
      <v-card max-height="90%" outlined rounded style="border-radius: 20px !important;">
        <v-card-text>
          <v-row no-gutters>
            <v-col class="pt-5" cols="12">
              <v-textarea
                  v-model="freeTextInput"
                  :hint="$t('generic.lang_hintAtLeast3Chars')"
                  :label="$t('generic.lang_Freitext')"
                  @keyup="checkPatternFreeText"
                  dense
                  outlined
              />
            </v-col>
            <v-col cols="12">
              <v-btn v-for="(freeTextTag) in filteredFreeTextTags" :key="freeTextTag.name" color="success"
                     outlined @click="selectFreeTextTag(freeTextTag)">{{ freeTextTag.name }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="text-right pr-3 card-footer pt-0 pb-0 bg-light">
          <v-btn class="mr-8" color="error" small text @click="closeDialog">
            {{ this.$t('generic.lang_cancel') }}
          </v-btn>
          <v-spacer/>
          <v-btn :disabled="loading" :loading="loading" class="text-light mx-auto"
                 color="#FF6969" elevation="0"
                 right
                 rounded
                 small @click="saveFreetext">
            {{ this.$t('generic.lang_next') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-else v-model="showDialog" max-width="700px" persistent scrollable>
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ $t('generic.lang_Freitext') }}</v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="closeDialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0"/>
        <v-card-text style="margin-top: 20px;">
          <v-layout>
            <v-flex md6>
              <v-textarea
                  autofocus
                  v-model="freeTextInput"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  :hint="$t('generic.lang_hintAtLeast3Chars')"
                  :label="$t('generic.lang_Freitext')"
                  @keyup="checkPatternFreeText"
                  filled
                  outlined
                  style="margin-top: 10px;"
                  @focus="showTouchKeyboard"/>
            </v-flex>

            <v-flex md5 offset-md1>
              <v-btn v-for="(freeTextTag) in filteredFreeTextTags" :key="freeTextTag.name" color="primary"
                     outlined @click="selectFreeTextTag(freeTextTag)">
                {{ freeTextTag.name }}
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-text>

        <v-card-actions>
          <v-btn color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
          <v-spacer/>
          <v-btn :disabled="freeTextInput && freeTextInput.length < 3 && selectedFreeTextTag === null" color="success"
                 @click="saveFreetext">{{ $t('generic.lang_save') }}
          </v-btn>
        </v-card-actions>

        <div ref="onScreenKeyboardDivFreeText" style="z-index: 299 !important;">
          <vue-touch-keyboard v-if="touchKeyboardFreeText.visible" id="onScreenKeyboard"
                              :accept="hideTouchKeyboardFreeText"
                              :cancel="hideTouchKeyboardFreeText"
                              :defaultKeySet="touchKeyboardFreeText.keySet"
                              :input="touchKeyboardFreeText.input"
                              :layout="touchKeyboardFreeText.layout"
                              :options="touchKeyboardFreeText.options"
                              class="internalWidthExpanded"/>
        </div>
      </v-card>

    </v-dialog>
  </div>

</template>

<style scoped>
#onScreenKeyboardDiv {
  z-index: 299 !important;
}
</style>

<script>
import {KEYBOARD} from "../../../config";
import {mapState} from "vuex";
import validation from "../../../mixins/validation/validation";

export default {
  name: "ButtonsFreetextDialog",

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object
  },

  mixins:[validation],

  data() {
    return {
      loading: false,
      freeTextInput: "",
      selectedFreeTextTag: null,
      KEYBOARD,
      touchKeyboardFreeText: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },

  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    //document.addEventListener('click', this.dismissOnScreenKeyboardFreeText);
  },


  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardFreeText);
  },

  watch: {
    freeTextInput: function (value) {
      if (value !== "")
        this.selectedFreeTextTag = null;
    },
    showDialog(val) {
      //SET ITEM FREETEXT IF AVAILABLE
      if (val) {
        if (this.item !== null) {
          if (this.item.freeText) {
            if (this.item.freeText.length > 0) {
              this.freeTextInput = this.item.freeText;
            }
          }
        }else{
          if (this.posType === "gastro") {
            if (this.pos.gastro.freeText !== null)
              this.freeTextInput = this.pos.gastro.freeText;
          }else if (this.posType === "retail") {
            if (this.pos.retail.freeText !== null)
              this.freeTextInput = this.pos.retail.freeText;
          }
        }

        document.addEventListener('click', this.dismissOnScreenKeyboardFreeText);
      } else {
        clearInterval(this.clearIntervalID);
        document.removeEventListener('click', this.dismissOnScreenKeyboardFreeText);
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    }
  },

  computed: {
    ...mapState([
      'freetexttags',
      'touchkeyboard',
      'pos'
    ]),
    filteredFreeTextTags() {
      if (!this.item)
        return [];

      //sort items by positions
      const freeTextTags = this.freetexttags.freeTextTags.filter(freeTextTag => {
        return freeTextTag.itemgroups.length === 0 || freeTextTag.itemgroups.includes(this.item.itemgroupID);
      });

      //sort by name and position "asc"
      if (Array.isArray(freeTextTags)) // to avoid sort not function error
        return freeTextTags.sort(function (a, b) {
          const result = b.position - a.position || a.name.localeCompare(b.name) * -1;
          return result * -1;
        })
      else return []
    }
  },

  methods: {
    checkPatternFreeText() {
      this.freeTextInput = this.freeTextInput.replace(this.preventSpecialCharsRegex,'');
    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardFreeText.input = e.target;
      this.touchKeyboardFreeText.layout = e.target.dataset.layout;

      if (!this.touchKeyboardFreeText.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardFreeText.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardFreeText() {
      if (this.touchKeyboardFreeText.visible) {
        this.touchKeyboardFreeText.visible = false;
      }
    },
    dismissOnScreenKeyboardFreeText: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivFreeText) {
        if (!this.$refs.onScreenKeyboardDivFreeText.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardFreeText();
          }
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.resetData();
    },
    resetData() {
      this.loading = false;
      this.freeTextInput = "";
      this.selectedFreeTextTag = null;
    },
    selectFreeTextTag(freeTextTag) {
      this.selectedFreeTextTag = freeTextTag;
      this.freeTextInput = this.freeTextInput + " " + freeTextTag.name;
    },
    saveFreetext() {
      this.$store.dispatch("pos/setFreetext", {
        posType: this.posType,
        freeText: this.freeTextInput,
        selectedItem: this.item
      }).then(() => {
        this.closeDialog();
      });
    }
  },
}
</script>
