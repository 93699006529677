<template>
  <swipe-list
      ref="open_list"
      :disabled="false"
      :items="openItems"
      item-key="id"
  >
    <template v-slot="{ item, index}">
      <mobile-invoice-open-item
          :colors="colors"
          :item="item"
          :item-index="index"
          :item-id="item.id"
          :pos-type="posType"
          :key="index"
          @closeLeftReveal="closeLeftReveal"
          @closeRightReveal="closeRightReveal"
      />

    </template>
    <template v-slot:right="{item}">
      <div style="width: auto; display: flex !important;">
        <buttons-freetext :pos-type="posType"/>
        <more-options-button :item="item" :pos-type="posType"/>
      </div>
    </template>

    <template v-slot:left="{item}">
      <div v-if="$store.getters['permissions/checkPermission']('allowImmediateVoidItems')" class="px-0 pa-0" style="width: 100%">
        <buttons-storno :item="item"
                        :pos-type="posType" :show-button-text="true"/>
      </div>
    </template>


  </swipe-list>
</template>

<script>

import {SwipeList} from 'vue-swipe-actions';
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import MobileInvoiceOpenItem from "@/components/pos/mobile/wedgets/MobileInvoiceOpenItem";
import ButtonsStorno from "@/components/pos/buttons/ButtonsStorno";
import ButtonsFreetext from "@/components/pos/buttons/ButtonsFreetext";
import MoreOptionsButton from "@/components/pos/mobile/wedgets/buttons/MoreOptionsButton";

export default {
  name: "MobileOpenItem",
  components: {
    MoreOptionsButton,
    SwipeList,
    MobileInvoiceOpenItem,
    ButtonsStorno,
    ButtonsFreetext,
  },
  computed: {
    /* getItem() {
       return (item, index) => {
         return this.openItems[index];
       }
     },
     itemIndex() {
       return (index) => {
         this.$forceUpdate();
         return index;
       }
     },
     itemId() {
       return (item, index) => {
         return this.openItems[index].id;
       }
     }*/

  },
  methods: {
    closeLeftReveal(item) {
      let index = this.openItems.indexOf(item)
      if (this.$refs.open_list.isRevealed(index) === false) {
        if (index > -1) {
          for (let i = 0; i < this.openItems.length; i++) {
            if (i == index) continue;
            if (this.$refs.open_list.isRevealed(i) === 'left')
              this.$refs.open_list.closeActions(i);
          }
        }

      }
    },
    closeRightReveal(item) {
      let index = this.openItems.indexOf(item)
      if (this.$refs.open_list.isRevealed(index) === false) {
        if (index > -1) {
          for (let i = 0; i < this.openItems.length; i++) {
            if (i == index) continue;
            if (this.$refs.open_list.isRevealed(i) === 'right')
              this.$refs.open_list.closeActions(i);
          }
        }

      }
    }
  },
  props: {
    openItems: {
      type: Array,
      required: true
    },
    colors: {
      type: Object,
      require: true
    },
    posType: String,
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
