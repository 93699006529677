import { render, staticRenderFns } from "./Gastro.vue?vue&type=template&id=25367432&scoped=true&"
import script from "./Gastro.vue?vue&type=script&lang=js&"
export * from "./Gastro.vue?vue&type=script&lang=js&"
import style0 from "./Gastro.vue?vue&type=style&index=0&id=25367432&prod&scoped=true&lang=css&"
import style1 from "./Gastro.vue?vue&type=style&index=1&id=25367432&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25367432",
  null
  
)

export default component.exports