<template>
  <v-dialog max-width="700px" scrollable persistent v-model="showDialog">
    <v-card>
      <v-toolbar color="#424242" dark dense flat>
        <v-toolbar-title>Suite8 {{ $t('datatables.lang_search') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn @click="closeDialog" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <!-- Error Message -->
        <v-alert v-if="errorMessage.length > 0" color="error" style="margin-top: 30px;" dark><b>Fehler:</b> {{ errorMessage }}</v-alert>

        <!-- Search Input -->
        <v-row style="margin-top: 30px;">
          <v-col :class="this.$vuetify.breakpoint.smAndDown? 'pa-1' : ''" cols="9">
            <v-text-field dense outlined :label="$t('datatables.lang_search')" @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="searchInput"></v-text-field>
          </v-col>

          <v-col :class="this.$vuetify.breakpoint.smAndDown? 'pa-1' : ''" cols="3">
            <v-btn class="mx-auto"  color="primary" @click="searchForItem" :disabled="loading" :loading="loading">{{ $t('datatables.lang_search') }}</v-btn>
          </v-col>
        </v-row>
      </v-card-text>

      <v-toolbar v-if="rooms.length > 0" dark flat tile>
        <v-toolbar-title>{{ $t('generic.lang_guests') }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text style="margin-top: 20px; padding: 0;">
        <v-list>
          <v-list-item v-for="data in rooms" :key="data.RoomNum" @click="selectedRoom = data">
            <v-list-item-title>{{ data.GuestName }}</v-list-item-title>
            <v-list-item-subtitle>{{ data.GuestArrival }} - {{data.GuestDeparture}}</v-list-item-subtitle>
            <v-list-item-subtitle>{{ $t('accounting.lang_zimmer') }} {{ data.RoomNum }} // {{ data.NoOfAdults }} {{ $t('generic.lang_guests') }}</v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-card-text style="padding: 0;" v-if="selectedRoom !== null">
        <div v-if="loading" class="text-center">
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </div>

        <div v-else>
          <v-tabs background-color="#424242" dark centered>
            <v-tab>{{ $t('generic.lang_booking') }}</v-tab>

            <!-- Occupation Details -->
            <v-tab-item>
              <v-simple-table class="occupationTable" v-if="selectedRoom !== null">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <td>{{ $t('generic.lang_bookingNo') }}</td>
                    <td>{{ selectedRoom.ProfileID }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('accounting.lang_zimmer') }}</td>
                    <td>{{ selectedRoom.RoomNum }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_from') }}</td>
                    <td>{{ selectedRoom.GuestArrival }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_to') }}</td>
                    <td>{{ selectedRoom.GuestDeparture }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_amountOfGuests') }}</td>
                    <td>{{ selectedRoom.NoOfAdults }}</td>
                  </tr>
                  <tr>
                    <td>{{ $t('generic.lang_guest') }}</td>
                    <td>{{ selectedRoom.GuestName }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-tab-item>
          </v-tabs>
        </div>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn :x-small="this.$vuetify.breakpoint.smAndDown" @click="closeDialog" color="error" text>{{$t("generic.lang_cancel")}}</v-btn>
        <v-spacer></v-spacer>
        <v-btn :x-small="this.$vuetify.breakpoint.smAndDown" :disabled="loading || selectedRoom === null"
               :loading="loading" @click="selectRoom" color="success">{{ $t("generic.lang_bookToRoom") }}
        </v-btn>
        <v-btn :x-small="this.$vuetify.breakpoint.smAndDown" :disabled="loading || selectedRoom === null"
               :loading="loading" @click="selectPaymentMethod" color="primary">{{ $t("generic.lang_selectPaymentMethod") }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div ref="onScreenKeyboardDivSuite8" style="z-index: 299 !important;">
      <vue-touch-keyboard v-if="touchKeyboardSuite8.visible" id="onScreenKeyboard"
                          :accept="hideTouchKeyboardSuite8"
                          :cancel="hideTouchKeyboardSuite8" :defaultKeySet="touchKeyboardSuite8.keySet"
                          :input="touchKeyboardSuite8.input" :layout="touchKeyboardSuite8.layout"
                          :options="touchKeyboardSuite8.options" class="internalWidthExpanded"/>
    </div>
  </v-dialog>
</template>

<style scoped>
.occupationTable tr td:first-child {
  font-weight: bold;
}

.v-list .v-list-item:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05);
}
</style>

<script>
import {ENDPOINTS} from "@/config";
import {KEYBOARD} from "@/config";
import {mapState} from "vuex";

export default {
  name: "ButtonsSuite8SearchDialog",

  props: {
    showDialog: Boolean
  },

  data() {
    return {
      loading: false,
      rooms: [
        /*{
          GuestName: "Bjarne Oehmke",
          GuestArrival: "15.03.2021",
          GuestDeparture: "17.03.2021",
          RoomNum: 100,
          NoOfAdults: 1,
          ProfileID: "541",
        }*/
      ],
      selectedRoom: null,
      searchInput: "",
      awaitingSearch: null,
      showPaymentMethodsDialog: false,
      errorMessage: "",

      // KEYBOARD
      KEYBOARD,
      touchKeyboardSuite8: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.selectedRoom = null;
        this.rooms = [];
        this.loading = false;
        this.searchInput = "";
        this.errorMessage = "";
      }
    },
    searchInput() {
      this.errorMessage = "";
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
  },

  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardSuite8);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardSuite8);
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem() {
      this.loading = true;

      //AXIOS CALL
      this.axios.get(ENDPOINTS.SUITE8.SEARCH, {
        params: {
          search: this.searchInput
        }
      }).then((res) => {
        this.loading = false;

        if (res.status === 200) {
          if(res.data.success) {
            if (res.data.results.length > 0) {
              this.rooms = res.data.results;
            } else {
              this.rooms = [];
            }
          }
          else {
            this.errorMessage = res.data.msg;
          }
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    selectRoom() {
      this.$emit("roomSelected", {
        room: this.selectedRoom,
        selectPayment: false
      });
    },
    selectPaymentMethod() {
      this.$emit("roomSelected", {
        room: this.selectedRoom,
        selectPayment: true
      });
    },

    // ---- KEYBOARD ----
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardSuite8.input = e.target;
      this.touchKeyboardSuite8.layout = e.target.dataset.layout;

      if (!this.touchKeyboardSuite8.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardSuite8.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardSuite8() {
      if (this.touchKeyboardSuite8.visible) {
        this.touchKeyboardSuite8.visible = false;
      }
    },
    dismissOnScreenKeyboardSuite8: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.touchKeyboardSuite8) {
        if (!this.$refs.touchKeyboardSuite8.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardSuite8();
          }
        }
      }
    },
  }
}
</script>
