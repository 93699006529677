<template>
  <v-dialog
    v-model="showDialog"
    max-width="450px"
    persistent
    scrollable
  >
    <v-card>
      <v-toolbar
        :color="this.$vuetify.breakpoint.smAndDown ? '#FF6969' : ''"
        dense
        flat
      >
        <v-toolbar-title
          :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : ''"
          >{{ $t('generic.lang_discount') }}
        </v-toolbar-title>

        <v-spacer />

        <v-btn
          :class="this.$vuetify.breakpoint.smAndDown ? 'text-white' : ''"
          icon
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0" />
      <v-card-text class="pa-0">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :max="100"
                v-model="discountValue"
                :label="$t('generic.lang_discount')"
                aria-autocomplete="none"
                filled
                outlined
                suffix="%"
                type="number"
                @keyup.enter="discount()"
                :rules="[(v) => !!v, (v) => v && v >= 0 && v <= 100]"
              />
              <NumpadComponent
                ref="numpad"
                show-discount-buttons
                @resetField="numpadResetField"
                @updateValue="numpadUpdateValue"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions
        :class="this.$vuetify.breakpoint.smAndDown ? 'card-footer pa-1' : ''"
      >
        <v-container class="pa-1">
          <v-row no-gutters>
            <v-col cols="6">
              <v-btn
                :color="
                  this.$vuetify.breakpoint.smAndDown ? 'primary' : '#FF6969'
                "
                :elevation="this.$vuetify.breakpoint.smAndDown ? 0 : ''"
                :disabled="
                  discountValue.length === 0 ||
                  parseInt(discountValue) < 0 ||
                  parseInt(discountValue) > 100
                "
                :rounded="this.$vuetify.breakpoint.smAndDown"
                :small="this.$vuetify.breakpoint.smAndDown"
                :text="this.$vuetify.breakpoint.smAndDown"
                class="ma-0"
                @click="discountInvoice()"
              >
                {{ $t('generic.lang_overall_discount') }}
              </v-btn>
            </v-col>
            <v-col
              v-if="item"
              align="end"
              cols="6"
            >
              <v-btn
                :color="
                  this.$vuetify.breakpoint.smAndDown ? '#FF6969' : 'primary'
                "
                :disabled="!this.canApplyDiscount"
                :elevation="this.$vuetify.breakpoint.smAndDown ? '0' : ''"
                :rounded="this.$vuetify.breakpoint.smAndDown"
                :small="this.$vuetify.breakpoint.smAndDown"
                class="ma-0"
                v-bind:class="
                  this.$vuetify.breakpoint.smAndDown ? 'text-white' : ''
                "
                @click="discount"
              >
                {{ $t('generic.lang_apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
      <div
        id="onScreenKeyboardDivDiscount"
        style="z-index: 299 !important"
      >
        <vue-touch-keyboard
          v-if="touchKeyboardDiscount.visible"
          id="onScreenKeyboard"
          :accept="hideTouchKeyboardDiscount"
          :cancel="hideTouchKeyboardDiscount"
          :defaultKeySet="touchKeyboardDiscount.keySet"
          :input="touchKeyboardDiscount.input"
          :layout="touchKeyboardDiscount.layout"
          :options="touchKeyboardDiscount.options"
          class="internalWidthExpanded"
        />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { KEYBOARD } from '@/config';
import { mapState } from 'vuex';
import NumpadComponent from '@/components/pos/NumpadComponent';
import { roundNumber } from '@/plugins/round';
import { format } from 'mathjs';

export default {
  name: 'ButtonsDiscountDialog',

  components: {
    NumpadComponent,
  },

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object,
  },

  data() {
    return {
      loading: false,
      discountValue: '',
      discountPrice: '',
      KEYBOARD,
      touchKeyboardDiscount: {
        visible: false,
        layout: 'normal',
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false,
        },
      },
      clearIntervalID: null,
    };
  },

  watch: {
    showDialog(val) {
      if (this.item !== null && this.item !== undefined) {
        this.itemAmount = this.item.amount;
        if (this.item.discount > 0) {
          this.discountValue = this.item.discount;
        } else {
          this.discountValue = '';
        }
      } else {
        this.discountValue = '';
      }

      this.dialogOpen = val;

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause');
      } else {
        this.$root.$emit('barcodeScanner.unpause');
      }
    },
    discountValue() {
      console.log(
        'discountValue',
        this.discountValue,
        Number(this.discountValue) > 100
      );
      if (this.discountValue > 100) {
        this.discountValue = 100;
      }

      if (this.discountValue < 0) {
        this.discountValue = 0;
      }

      if (
        this.discountValue >= 1 &&
        this.item !== undefined &&
        this.item !== null
      ) {
        this.calculatePrice();
      }
    },
  },

  computed: {
    ...mapState(['touchkeyboard']),
    canApplyDiscount() {
      return (
        this.discountValue?.length > 0 &&
        parseInt(this.discountValue) >= 0 &&
        parseInt(this.discountValue) <= 100
      );
    },
  },

  methods: {
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled) return;

      this.touchKeyboardDiscount.input = e.target;
      this.touchKeyboardDiscount.layout = e.target.dataset.layout;

      if (!this.touchKeyboardDiscount.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardDiscount.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardDiscount() {
      if (this.touchKeyboardDiscount.visible) {
        this.touchKeyboardDiscount.visible = false;
      }
    },
    dismissOnScreenKeyboardDiscount: function (e) {
      if (
        this.touchkeyboard.settings.enabled &&
        document.getElementById('onScreenKeyboardDivDiscount')
      ) {
        if (
          !document
            .getElementById('onScreenKeyboardDivDiscount')
            .contains(e.target)
        ) {
          if (e.target.tagName !== 'INPUT') {
            this.hideTouchKeyboardDiscount();
          }
        }
      }
    },
    calculatePrice() {
      this.discountPrice =
        ((this.item.sellPrice * ((100 - this.discountValue) / 100) +
          Number.EPSILON) *
          100) /
        100;
      this.discountPrice = format(this.discountPrice, { precision: 14 });
      this.discountPrice = roundNumber(this.discountPrice, 2);
    },
    closeDialog() {
      this.$refs.numpad.resetField();
      this.numpadResetField();
      this.$emit('closeDialog');
    },
    discountPrice2() {
      this.$store
        .dispatch('pos/priceDiscount', {
          posType: this.posType,
          discountPrice: parseFloat(this.discountPrice.replace(',', '.')),
          amount: this.itemAmount,
        })
        .then(() => {
          this.closeDialog();
        });
    },
    discount() {
      if (!this.canApplyDiscount) return;
      this.$store
        .dispatch('pos/discount', {
          posType: this.posType,
          discount: parseFloat(this.discountValue),
          amount: this.itemAmount,
        })
        .then(() => {
          this.closeDialog();
        });
    },
    discountInvoice() {
      this.$store
        .dispatch('pos/discountInvoice', {
          posType: this.posType,
          discount: parseFloat(this.discountValue),
        })
        .then(() => {
          this.closeDialog();
        });
    },
    numpadUpdateValue(value) {
      this.discountValue = value;
    },
    numpadResetField() {
      this.discountValue = '';
    },
  },
  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardDiscount);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardDiscount);
  },
};
</script>
