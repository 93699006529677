<template>
    <v-dialog v-model="showDialog" width="400">
        <v-card>
            <v-card-text>
                <v-row>
                    <v-col class="text-center" cols="12">
                        {{ $t('generic.lang_stockCheckNotEnough') }}

                        <br><br>
                        <b v-if="errorItem !== null">{{ errorItem.name }}</b>
                    </v-col>
                </v-row>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="showDialog = false">{{ $t('generic.lang_understand')}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {ENDPOINTS} from "@/config";
import {mapState} from "vuex";

export default {
    name: "CheckStockCount",

    props: {
        posType: String
    },

    data() {
        return {
            showDialog: false,
            errorAtKey: null
        }
    },

    computed: {
        ...mapState([
            'pos',
            'settings'
        ]),
        invoiceItems() {
            let invoiceItems = [];

            if (this.posType === "retail") {
                invoiceItems = this.pos.retail.orderedItems;
            }

            if (this.posType === "gastro") {
                if (!this.pos.gastro.hasOwnProperty("table"))
                    return invoiceItems;

                if (!this.pos.gastro.table.hasOwnProperty("name"))
                    return invoiceItems;

                if (!this.pos.gastro.hasOwnProperty("party"))
                    return invoiceItems;

                if (!this.pos.gastro.party)
                    return invoiceItems;

                if (!this.pos.gastro.party.hasOwnProperty("name"))
                    return invoiceItems;

                if (this.pos.gastro.table.name !== 0) {
                    if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                        if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                            invoiceItems = this.pos.gastro.orderedItems[this.pos.gastro.party.name];
                        }
                    }
                } else {
                    if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                        if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                            invoiceItems = this.pos.gastro.openItems[this.pos.gastro.party.name];
                        }
                    }
                }
            }

            return invoiceItems.map((invoiceItem) => {
                return invoiceItem;
            });
        },
        errorItem() {
            if(this.errorAtKey === null)
                return null;

            return this.invoiceItems[this.errorAtKey];
        }
    },

    methods: {
        checkSaleStock() {
            return new Promise((resolve, reject) => {
                //CHECK IF WE NEED TO CHECK
                if(!this.settings.settings.hasOwnProperty("checkStockCount"))
                    return resolve();

                if(parseInt(this.settings.settings.checkStockCount) === 0)
                    return resolve();

                this.axios.post(ENDPOINTS.POS.CHECKSTOCKCOUNTSALE, {
                    orderObj: this.invoiceItems
                }).then((res) => {
                    if(res.data.success)
                        resolve();
                    else {
                        this.errorAtKey = parseInt(res.data.pos);
                        this.showDialog = true;

                        reject(res.data.msg);
                    }
                }).catch((err) => {
                    reject(err.message);
                });
            })
        },
    }
}
</script>