<template>
  <div>
    <BookItem ref="itemBooking" v-if="includeItemBooking" :pos-type="posType"></BookItem>
  </div>
</template>

<script>
import BookItem from "./pos/BookItem";
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
export default {
  name: "barcodeKeyboard",

  props: {
    includeItemBooking: {
      type: Boolean,
      default: false
    },
    posType: String
  },

  components: {
    BookItem
  },

  data() {
    return {
      barcodeCodeString: "",
      barcodeClearID: "",
      pause: false
    }
  },

  computed: {
    ...mapState([
      'app'
    ])
  },

  methods: {
    addToBarcode(e) {
      //CHECK IF WE SHOULD SKIP INPUTS
      if (this.pause)
        return;

      if (e.which === 13) {
        e.preventDefault();
        this.returnBarcode();
      } else {
        this.barcodeCodeString += String.fromCharCode(e.keyCode);
        //RESTART TIMER
        clearInterval(this.barcodeClearID);
        this.barcodeKeyInterval();
      }
    },
    returnBarcode() {
      let bestBeforeDate = "";
      let batchNo = "";

      if (this.includeItemBooking) {
        /* IF BARCODE HAS INFORMATIONS LIKE BEST BEFORE DATE AND A BATCH NUMBER */
        if (this.barcodeCodeString.length > 20) {
          let barcodeStr = this.barcodeCodeString;

          if (barcodeStr.substr(0, 2) == "01" && barcodeStr.substr(15, 2) == "15" && barcodeStr.substr(23, 2) == "10") {
            this.barcodeCodeString = barcodeStr.substr(2, 13);

            //Check for Best Before Date
            bestBeforeDate = barcodeStr.substr(17, 6);

            // Check Bath No
            batchNo = barcodeStr.substr(25, 31);
          }
        }

        // CHECK IF USE NORMAL FUNCTION TO BOOK ITEM BY EAN
        // OR IF WE USE API FOR ITEM BOOKING
        if(this.app.useAPIForPOSActions && this.posType === "retail" && !this.barcodeCodeString.includes("V$") && !this.barcodeCodeString.includes("C$") && !this.barcodeCodeString.includes("GF$") && !this.barcodeCodeString.includes("CA$") && !this.barcodeCodeString.includes("CC$")  && !this.barcodeCodeString.includes("R$")) {
          this.axios.post(ENDPOINTS.POS.RETAIL.SCAN, {
            query: this.barcodeCodeString
          }).then((res) => {
            if(res.data.success)
              this.$refs.itemBooking.selectItemByData(res.data.itemgroup, res.data.item, res.data.overwrite.weight);
          });
        }
        else {
          this.$refs.itemBooking.selectItemEAN(this.barcodeCodeString, bestBeforeDate, batchNo);
        }
      }

      this.clearBarcodeString();
    },
    clearBarcodeString() {
      this.barcodeCodeString = "";
    },
    //RESET SET
    barcodeKeyInterval() {
      this.barcodeClearID = setInterval(() => {
        this.clearBarcodeString()
      }, 700);
    },
  },

  mounted() {
    window.addEventListener("keypress", this.addToBarcode);

    this.$root.$on("barcodeScanner.pause", () => {
      this.pause = true;
    });

    this.$root.$on("barcodeScanner.unpause", () => {
      this.pause = false;
    });

    this.barcodeKeyInterval();
  },

  destroyed() {
    window.removeEventListener('keypress', this.addToBarcode);
    clearInterval(this.barcodeClearID);
  }
}
</script>