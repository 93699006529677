<template>
  <div>
    <button v-if="this.$vuetify.breakpoint.smAndDown" class="br-tr btn-transition btn text-white bg-success" style="height: 40px !important;width: 40px !important;" @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'arrow-up']"/>
      </i>
      <span v-if="showButtonText">{{ $t('generic.lang_discount') }}</span>
    </button>

    <button v-else class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-success" @click="openDialog">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'arrow-up']"/>
      </i>
      <span v-if="showButtonText">{{ $t('generic.lang_discount') }}</span>
    </button>

    <ButtonsIncreaseDialog :item="selectedItem" :pos-type="posType" :show-dialog="showDialog" @closeDialog="showDialog = false"/>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import ButtonsIncreaseDialog from "./ButtonsIncreaseDialog";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faPercent} from '@fortawesome/pro-light-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapState} from "vuex";

library.add(
    faPercent
);

export default {
  name: "ButtonsIncrease",

  components: {
    'font-awesome-icon': FontAwesomeIcon,
    ButtonsIncreaseDialog
  },

  props: {
    posType: String,
    showButtonText: Boolean
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    ...mapState([
      'pos'
    ]),
    selectedItem() {
      if (this.posType === "retail") {
        if (this.pos.retail.selectedItem !== null) {
          return this.pos.retail.selectedItem;
        }
      }

      if (this.posType === "gastro") {
        if (this.pos.gastro.selectedOpenItem !== null) {
          return this.pos.gastro.selectedOpenItem;
        } else if (this.pos.gastro.selectedOrderedItem !== null) {
          return this.pos.gastro.selectedOrderedItem;
        }
      }

      return null;
    }
  },

  methods: {
    openDialog() {
      if (this.selectedItem === null) {
        //if discount all
        //return;
      } else {
        if (this.selectedItem.isVoid)
          return;

        if (this.selectedItem.ean.includes("V$") || this.selectedItem.ean.includes("GF$") || this.selectedItem.ean.includes("ZMYLE$") || this.selectedItem.ean.includes("MYWORLD$"))
          return;
      }

      this.showDialog = true;
    }
  }
}
</script>