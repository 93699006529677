<template>
    <div>
        <button @click="payDirect(3)" :disabled="disabled"
                class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary">
            <span v-if="!loadingStockCount">
                <i class="btn-icon-wrapper">
                  <font-awesome-icon :icon="['fal', 'credit-card']"/>
              </i>
              <span v-if="showButtonText">{{ $t('generic.lang_cash') }}</span>
            </span>

            <span v-else>
                <v-progress-circular width="2" style="margin-top: 10px;" color="primary" indeterminate/>
            </span>
        </button>

        <CashoutDialog ref="cashoutDialog" :pos-type="posType" @closeDialog="showCashoutDialog = false"></CashoutDialog>

        <v-dialog :value="errorDialogMessage.length > 0" persistent max-width="300">
            <v-card>
                <v-card-title>{{ $t('generic.lang_info') }}</v-card-title>
              <v-divider class="ma-0 mb-2"/>
                <v-card-text class="text-center">{{ errorDialogMessage }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="errorDialogMessage = ''">{{ $t('generic.lang_next') }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <CheckStockCount ref="checkStockCount" :pos-type="posType"></CheckStockCount>
    </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {
  faCreditCard
} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';

library.add(
    faCreditCard
);

import CashoutDialog from "../cashout/CashoutDialog";
import {mapState} from "vuex";
import CheckStockCount from "@/components/pos/cashout/CheckStockCount";

export default {
  name: "ButtonsPayEC",

  props: {
    posType: String,
    showButtonText: Boolean,
    disabled: Boolean,
  },

  components: {
      CheckStockCount,
    CashoutDialog,
    'font-awesome-icon': FontAwesomeIcon
  },

  data() {
    return {
      paymentActive: false,
      errorDialogMessage: "",
        loadingStockCount: false
    }
  },

  computed: {
    ...mapState({
      pos: 'pos'
    }),
    isPayAllowed() {
      if (this.posType === "retail") {
        if (this.pos.retail.orderedItems.length > 0) {
          return true;
        }
      }

      if (this.posType === "gastro") {


        //DELIVERY
        /*
        if(this.pos.gastro.isDeliveryService){
          if(this.$route.query.hasOwnProperty('useDeliveryService')){
            if(this.$route.query.useDeliveryService){
              if(this.$route.query.hasOwnProperty('checkout')){
                return true;
              }else{
                return false;
              }
            }else{
              return false;
            }
          }
        }*/

        //DIRECT SALE
        if (this.pos.gastro.table !== null) {
          if (this.pos.gastro.table.name === 0) {
            if (this.pos.gastro.party !== null) {
              if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                  //return (this.table.soldBy === this.user.userID);
                  return true;
                }
              }
            }
          }
          //TABLEPLAN
          else {
            if (this.pos.gastro.party !== null) {
              if (this.pos.gastro.openItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.openItems[this.pos.gastro.party.name].length > 0) {
                  //return (this.table.soldBy === this.user.userID);
                  return false;
                }
              }
              if (this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                if (this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0) {
                  //return (this.table.soldBy === this.user.userID);
                  return true;
                }
              }
            }
          }
        }
      }

      return false;
    },
  },

  methods: {
    async payDirect(paymentType) {
        if (!this.isPayAllowed) {
            this.errorDialogMessage = this.$t('generic.lang_nothingHasBeenSoldYet');

            return;
        }

        //CHECK STOCK COUNT
        try {
            this.loadingStockCount = true;

            await this.$refs.checkStockCount.checkSaleStock();
        }
        catch(err) {
            //STOCK NOT SUFFICIENT
            return;
        }
        finally
        {
            this.loadingStockCount = false;
        }

      this.paymentActive = true;

      this.$nextTick(() => {
        this.$refs.cashoutDialog.selectPayment(paymentType);
      });
    }
  }
}
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
