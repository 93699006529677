<template>
  <div>
    <v-dialog v-model="showDialog" persistent max-width="600px">
      <v-card :disabled="loading">
        <v-card-title>
          <span class="headline">{{ $t('accounting.lang_enter_cash_book_entry') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <!-- TSE ALERT -->
            <v-row v-if="fiscalClient !== null">
              <v-col cols="12">
                <v-alert dark color="info"><b>Info: </b> {{ $t('accounting.lang_youCanOnlyCreateCashBookEntriesIfTseIsAccessible') }}</v-alert>
              </v-col>
            </v-row>
            <v-form v-model="valid" lazy-validation style="width: 100%;" ref="CashbookEntryForm">
            <v-row>
              <v-col cols="12" sm="6">
                <v-select outlined
                          :items="cashierIDs"
                          v-model="cashierIDCreateVal"
                          disabled
                          :label="$t('generic.lang_cashierID')"
                          required
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select outlined
                          item-text="text" item-value="value"
                          :items="[{text: this.$t('accounting.lang_income'),value: 1},{text: this.$t('accounting.lang_expense'),value: 0}]"
                          v-model="createEntryType"
                          :label="$t('accounting.lang_invoiceType')"
                          required
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="4">
                <v-select outlined item-text="text" item-value="value" v-model="createEntryAccount"
                          :label="$t('accounting.langAccountingAccount')+':'" :rules="[v=>!!v||$t('generic.lang_requiredField')]"
                          v-if="accounts && accounts != null" :items="accounts"></v-select>

              </v-col>
              <v-col cols="12" sm="4">
                <v-select :items="taxes"
                          :label="$t('erp.lang_tax_value')"
                          item-text="name"
                          item-value="tax"
                          outlined
                          v-model="createEntryTax"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="4">
                <v-currency-field
                    :label="$t('generic.lang_total')"
                    outlined
                    required
                    v-model="createEntryTotal"
                    @focus="showTouchKeyboard"
                    :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                    :min="0"
                    :auto-decimal-mode="true"
                    :max="createEntryType===0?endCredit:null"
                    :local="$store.getters['settings/getSettingValue']('geoloc_systemcountry')"
                    :allow-negative="false"
                    :rules="[rules.checkTotalWithEndCredit,]"
                ></v-currency-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field outlined
                              @focus="showTouchKeyboard"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              v-model="createEntryBelNo"
                              :label="$t('accounting.lang_invoiceNo')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <date-picker :label="$t('accounting.lang_receiptDate')" :rules="[v=>!!v||$t('generic.lang_requiredField')]" v-model="createEntryBelDate" clearable/>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12">
                <v-textarea outlined
                            @focus="showTouchKeyboard"
                            :data-layout="KEYBOARD.KEYSETS.NORMAL"
                            :label="$t('generic.lang_Freitext')"
                            v-model="createEntryFreetext"
                ></v-textarea>
              </v-col>
            </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_close') }}</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" :disabled="!valid||loading"
                 :loading="loading" text
                 @click="checkTSE">{{ $t('generic.lang_create') }} 
          </v-btn>
        </v-card-actions>
      </v-card>

      <!-- TSE ERROR DIALOG -->
      <v-dialog :value="tseErrorMessage.length > 0" persistent width="400">
        <v-card>
          <v-card-title>{{ $t('generic.lang_error') }}</v-card-title>
          <v-card-text v-text="tseErrorMessage"></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeTSEErrorDialog">{{ $t('generic.lang_understand') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <div id="onScreenKeyboardDivCashBookEntry" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboardCashBookEntry.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboardCashBookEntry"
                            :cancel="hideTouchKeyboardCashBookEntry" :defaultKeySet="touchKeyboardCashBookEntry.keySet"
                            :input="touchKeyboardCashBookEntry.input" :layout="touchKeyboardCashBookEntry.layout"
                            :options="touchKeyboardCashBookEntry.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>

  </div>

</template>

<script>
import {ENDPOINTS, KEYBOARD} from "@/config";
import {Events} from "@/plugins/events";
import {mapGetters, mapState} from "vuex";
import DatePicker from "@/components/common/datepicker";
import DateTimePicker from "@/components/common/datetimepicker";

export default {
  name: "CashbookCreateEntryDialog",

  components: {
    DatePicker,
    DateTimePicker
  },

  props: {
    showDialog: Boolean,
    taxes: Array,
    accounts: Array,
    cashierIDs: Array,
    endCredit: Number,
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.createEntryType = "";
        this.createEntryTax = null;
        this.createEntryAccount = null;
        this.createEntryBelNo = "";
        this.createEntryBelDate = "";
        this.createEntryFreetext = "";
        this.createEntryTotal = "";

        this.loading = false;

        this.createdEntryUUID = null;

        //DISABLE LISTENER
        Events.$off("eposDevice", this.eposDeviceCallback);
        Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
      }else{
        let now = new Date();
        this.createEntryBelDate = now.toISOString().substr(0, 10);
      }
    }
  },

  data() {
    return {
      loading: false,
      valid: false,
      //
      KEYBOARD,
      touchKeyboardCashBookEntry: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
      createEntryType: 0,
      createEntryTax: null,
      createEntryAccount: null,
      createEntryBelNo: "",
      createEntryBelDate: "",
      createEntryFreetext: "",
      createEntryTotal: "",
      cashierIDCreateVal: 1,
      createdEntryUUID: null,

      //TSE
      tseDevice: null,
      tseErrorMessage: "",
      tseErrorDialogButton: "",
      rules:{
        checkTotalWithEndCredit:(v)=>{

          let val =(v+'').replace('.','')
          val = Number(val.replace(',','.'))

          if(isNaN(val)){
            return this.$t('generic.lang_pleaseEnterValidNumber')
          }

          if(this.createEntryType===1){
            return  !!val||this.$t('generic.lang_requiredField');
          }
          if(Number(val) > Number(this.endCredit)){
            return this.$t('generic.lang_shouldBeLessThanTheEndingBalance')
          }
          return !!val || this.$t('generic.lang_requiredField');
        },
      },
    }
  },

  computed: {
    ...mapState([
      'api',
      'pos',
      'touchkeyboard',
    ]),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient'
    })
  },

  mounted() {
    Events.$on("eposDevice", this.eposDeviceCallback);
    Events.$on("GFE_OnReceive", this.GFE_OnReceiveCallback);
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardCashBookEntry);
    let now = new Date();
    this.createEntryBelDate = now.toISOString().substr(0, 10);
    this.cashierIDCreateVal = this.api.auth.cashierID;

  },
  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardCashBookEntry);
  },
  beforeDestroy() {
    Events.$off("eposDevice", this.eposDeviceCallback);
    Events.$off("GFE_OnReceive", this.GFE_OnReceiveCallback)
  },

  methods: {
    checkNegativity(val) {
      let newVal = val.toString();
      newVal = newVal.replace(",", "");
      newVal = newVal.replace(".", "");
      if (Number(newVal) < 0 || isNaN(newVal)) this.createEntryTotal = 0;

    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardCashBookEntry.input = e.target;
      this.touchKeyboardCashBookEntry.layout = e.target.dataset.layout;

      if (!this.touchKeyboardCashBookEntry.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardCashBookEntry.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardCashBookEntry() {
      if (this.touchKeyboardCashBookEntry.visible) {
        this.touchKeyboardCashBookEntry.visible = false;
      }
    },
    dismissOnScreenKeyboardCashBookEntry: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivCashBookEntry')) {
        if (!document.getElementById('onScreenKeyboardDivCashBookEntry').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardCashBookEntry();
          }
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
    },
    checkTSE() {

      if(!this.$refs['CashbookEntryForm'].validate()){
        return;
      }

      this.loading = true;

      //CHECK IF TSE CLIENT IS SETUP
      if (this.fiscalClient === null) {
        this.createEntry(false);
        return;
      }

      if (this.fiscalClient.device.type === null) {
        this.fiscalClient.device.type = "epsonTSE";
      }
      // IF WE USE CLOUD TSE THE ENTRY IS BEEING FISCALIZED IN BACKEND
      if (this.fiscalClient.device.type !== "epsonTSE") {
        this.createEntry(false);
        return;
      }


      //CHECK IF TSE DEVICE ALREADY ADDED
      let tsePrinters = this.$eposClass.getTSEPrinters();

      if (!tsePrinters.hasOwnProperty(this.fiscalClient.id)) {
        //ADD NEW TSE PRINTER
        this.$eposClass.addTSEPrinter(new this.$epson.ePOSDevice(), {
          id: this.fiscalClient.id,
          TSEProxyIPAdress: this.fiscalClient.device.TSEProxyIPAdress,
          ip: this.fiscalClient.device.ip,
          port: this.fiscalClient.device.port,
          deviceID: this.fiscalClient.device.deviceID,
          adminID: 'Administrator',
          clientID: this.fiscalClient.clientID
        }, false, false, (this.fiscalClient.device.useTSEProxy === 1));

        this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);
      } else {
        this.tseDevice = this.$eposClass.getTSEPrinter(this.fiscalClient.id);

        if (this.tseDevice.tseReady) {
          //CREATE ENTRY
          this.createEntry();
        } else {
          if (this.tseDevice.connected) {
            //TSE WAS ADDED BUT ISNT READY YET
            this.loading = false;
            this.tseErrorMessage = this.$t('generic.lang_tseIsNotReadyTryAgain');
          } else {
            //TRY TO RE-CONNECT!!!
            this.tseDevice.connect();
          }
        }
      }
    },
    createEntry(withTSE = true) {
      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.CREATE, {
        cashBookCashierIDEntry: this.cashierIDCreateVal,
        buchungsBelegArt: this.createEntryType,
        buchungsSatzSteuer: this.createEntryTax,
        cashBookKontoNo: this.createEntryAccount,
        cashBookBelegRr: this.createEntryBelNo,
        Belegdatum: this.createEntryBelDate,
        cashBookNotes: this.createEntryFreetext,
        cashBookTotal: this.createEntryTotal,
        shouldCommited: (withTSE ? 1 : 0)
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          this.createdEntryUUID = res.data.entryUUID;

          if (withTSE)
            this.startTransaction();
          else
            this.finishCreating();
        } else {
          this.loading = false;

          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        this.loading = false;

        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    startTransaction() {
      this.tseDevice.GFE_StartTransaction(this.fiscalClient.clientID);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "StartTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishTransaction(payload.result);
          } else {
            this.tseErrorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
            this.tseErrorDialogButton = "close";
          }
        }
      });
    },
    finishTransaction(startTransactionResult) {
      //CREATE FAKE ITEM TO USE EXISTING FUNCTION
      let items = [{
        isVoid: false,
        sellPrice: parseFloat(this.createEntryTotal).toFixed(2) * (this.createEntryType === 0 ? -1 : 1),
        taxValue: parseFloat(this.createEntryTax),
        amount: 1,
      }];

      this.tseDevice.finishInvoiceTransaction(this.fiscalClient.clientID, startTransactionResult.output, items, 1, []);

      Events.$once("GFE_OnReceive", (payload) => {
        if (payload.result.function === "FinishTransaction") {
          if (payload.result.result === "EXECUTION_OK") {
            this.finishCreating(startTransactionResult, payload.result);
          } else {
            this.tseErrorMessage = this.$t('generic.lang_couldNotCompleteSigning') + ": " + payload.result.result;
            this.tseErrorDialogButton = "close";
          }
        }
      });
    },
    finishCreating(startTransactionResult = null, finishTransactionResult = null) {
      //SAVE FISCAL DATA INTO DATABASE
      if (startTransactionResult !== null && finishTransactionResult !== null) {
        let payload = {
          fiscalDevice: {
            id: this.fiscalClient.device.id,
            deviceID: this.fiscalClient.device.deviceID
          },
          fiscalClient: {
            id: this.fiscalClient.id,
            clientID: this.fiscalClient.clientID
          },
          tse: {
            serialNumber: this.tseDevice.storageInfo.tseInformation.serialNumber,
          },
          transaction: {
            processType: "Kassenbeleg-V1",
            startTime: startTransactionResult.output.logTime,
            stopTime: finishTransactionResult.output.logTime,
            transactionNumber: startTransactionResult.output.transactionNumber,
            signatureCounter: finishTransactionResult.output.signatureCounter,
            signature: finishTransactionResult.output.signature
          }
        };

        //SAVE FISCAL DATA INTO DATABASE
        this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.UPDATETSEDATA, {
          entryUUID: this.createdEntryUUID,
          tsePayload: payload
        }).then(() => {
          this.exitDialog();
        }).catch(() => {
          this.exitDialog();
        });
      } else {
        this.exitDialog();
      }
    },
    exitDialog() {
      Events.$emit("showSnackbar", {
        message: this.$t('generic.lang_cashBookEntryCreated'),
        color: "success"
      });

      this.$emit("entryCreated");
    },
    closeTSEErrorDialog() {
      if (this.tseErrorDialogButton === "close") {
        this.exitDialog();
      } else {
        this.tseErrorMessage = "";
      }
    },

    // -----------------------------------------------
    // ------------------ GFE / TSE ------------------
    // ------------------ CALLBACKS ------------------
    // -----------------------------------------------
    eposDeviceCallback(payload) {
      // IMPORTANT
      if (!this.showDialog)
        return;

      if (payload.action === "createDeviceCallback.error") {
        this.tseErrorMessage = `${this.$t('generic.lang_setupOfTheTseCouldNotBeStarted')}<br>(${payload.code})`;
      }

      if (payload.action === "connectCallback.error") {
        //CONNECTION ERROR
        this.tseErrorMessage = this.$t('generic.lang_connectionToTseCouldNotBeEstablishedCheckIp')
      }

      if (payload.action === "disconnect") {
        this.tseDevice = null;
      }
    },
    GFE_OnReceiveCallback(payload) {
      // IMPORTANT
      if (!this.showDialog)
        return;

      if (payload.result.function !== "StartTransaction") {
        if (payload.result.function === "GetStorageInfo") {
          //TSE FINALLY FINISHED CONNECTING
          //START INSERTING CASHBOOK ENTRY
          this.createEntry(true);
        } else {
          if (payload.result.result !== "EXECUTION_OK") {
            //OTHER ERROR OCCURED -> ABORTING
            this.tseErrorMessage = `${this.$t('generic.lang_errorOccurred')}<br>(${payload.result.result})`;
            this.exitDialog();
          }
        }
      }
    }
  }
}
</script>
