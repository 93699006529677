<template>
  <div>
    <button
        v-if="this.$vuetify.breakpoint.smAndDown"
        class="br-tr btn-transition btn text-white bg-primary"
        style="height: 40px !important; width: 40px !important"
        @click="reprintLastInvoice"
    >
      <template v-if="!loadingReprint">
        <i class="btn-icon-wrapper">
          <font-awesome-icon :icon="['fal', 'print']"/>
        </i>
        <span v-if="showButtonText">{{ $t('generic.lang_reprintLastInvoice') }}</span>
      </template>
      <v-progress-circular v-else class="btn-icon-wrapper mx-auto" indeterminate/>
    </button>

    <button
        v-else
        class="btn-icon-vertical btn-square br-tr btn-transition btn btn-outline-primary"
        @click="reprintLastInvoice"
    >
      <template v-if="!loadingReprint">
        <i class="btn-icon-wrapper">
          <font-awesome-icon :icon="['fal', 'print']"/>
        </i>
        <span v-if="showButtonText">{{ $t('generic.lang_reprintLastInvoice') }}</span>
      </template>
      <v-progress-circular v-else class="btn-icon-wrapper mx-auto" indeterminate/>
    </button>
  </div>
</template>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>

<script>
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";

import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faPrint} from "@fortawesome/pro-light-svg-icons";
import {mapState} from "vuex";
import {createInvoicePrintingData} from "../../../plugins/printing/printInvoice";
import {createRetourePrintingData} from "../../../plugins/printing/printRetoure";
import {printDataFromPrinter} from "../../../plugins/printing/printerController";
import {geol} from "@/main";

library.add(faPrint);

export default {
  name: "ButtonsReprintLastInvoice",

  components: {
    "font-awesome-icon": FontAwesomeIcon,
  },
  props: {
    posType: String,
    showButtonText: Boolean,
  },
  data() {
    return {
      loadingReprint: false,
      items: [],
      invoiceData: {},
      invoiceNo: null,
    }
  },
  computed: {

    ...mapState([
      'settings',
      'api',
    ]),
    ...mapState('printer', {
      printers: state => state.printers
    }),
    invoicePrinter() {
      return this.printers.find((printer) => {
        if (printer.cashierID.includes(this.api.auth.cashierID)) {
          if (printer.type === 1) {
            return true;
          }
        }
      });
    },
  },
  mounted() {
    // since the geol.local can change when ever werefresh the browser we need to set it every time we need to use it
    // we re gonna use this work arround for now until we find a better way
    if (this.$store.getters['settings/getSettingValue']('geoloc_systemcountry')) {
        geol.locale = this.$store.getters['settings/getSettingValue']('geoloc_systemcountry');
    }
  },
  methods: {
    addZVTPrinting(printData) {

      // --------------------------- EC PRINTING --------------------------
      if (this.invoiceData.ecTermReceiptJSON && this.invoiceData.ecTermReceiptJSON.printing !== undefined) {

        if (this.invoiceData.ecTermReceiptJSON.printing.customer) {
          // printData.push({cut: true});
          // REMOVE LAST CUT
          if (this.invoiceData.ecTermReceiptJSON.printing.customer.length > 1) {
            let lastElement = printData[(printData.length - 1)];
            if (lastElement.hasOwnProperty("cut"))
              this.$delete(printData, (printData.length - 1));

            printData.push({align: 'center'});
            printData.push({feed: 2});

            // LOOP EC PRINTING DATA
            this.invoiceData.ecTermReceiptJSON.printing.customer.forEach((printElement) => {
              if (printElement.trim().length === 0)
                return;

              printData.push({text: printElement});
            });

            printData.push({feed: 4});

          }
        }

        // HÄNDLERBELEG
        if (this.invoiceData.ecTermReceiptJSON.printing.salesperson) {
          printData.push({cut: true});
          if (this.invoiceData.ecTermReceiptJSON.printing.salesperson.length > 1) {
            printData.push({feed: 2});

            // LOOP EC PRINTING DATA
            this.invoiceData.ecTermReceiptJSON.printing.salesperson.forEach((printElement) => {
              if (printElement.trim().length === 0)
                return;

              printData.push({text: printElement});
            });

            printData.push({feed: 4});
            printData.push({cut: true});
          }
        }
      }
      // ---------------------------------------------------------------

      return printData;
    },
    printInvoice() {
      var printData;

      // PRINT INVOICE
      if (this.invoiceData.invoiceType === 1 || this.invoiceData.invoiceType === 2) {
        // RE-MAP INVOICE POSITIONS
        let invoicePositions = this.items.map((invoiceItem) => {
          return {
            amount: invoiceItem.amount,
            ean: invoiceItem.ean,
            name: invoiceItem.name,
            taxValue: invoiceItem.taxValue,
            sellPrice: invoiceItem.unitPrice,
            originalSellPrice: invoiceItem.originalSellPrice,
            isVoid: 0, // TO DISABLE WRONG  PRINTOUT AFTER VOIDING
            discount: invoiceItem.discount,
            selectedExtras: invoiceItem.selectedExtras,
          };
        });

        // GOODSVALUE
        let goodsValue = 0;

        invoicePositions.forEach((item) => {
          //CHECK IF VOIDED
          if (item.isVoid || item.amount === 0)
            return;

          if (item.sellPrice < 0)
            return;

          goodsValue += parseFloat((item.sellPrice * item.amount).toFixed(2));
        }, 0);

        // VOUCHERS VALUE
        let vouchersValue = 0;

        invoicePositions.forEach((item) => {
          if ((item.ean.includes("V$") || item.ean.includes("GF$")) && !item.isVoid) {
            vouchersValue += item.sellPrice;
          }
        });

        vouchersValue = vouchersValue * -1;

        // SPLITPAYMENT
        let splitPayments = [];

        if (this.invoiceData.typeOfPayment === 7) {
          for (let payment in this.invoiceData.splitPaymentObj) {
            if (!this.invoiceData.splitPaymentObj.hasOwnProperty(payment))
              continue;

            let label = payment;
            label = label.replace('1', "BAR");
            label = label.replace('2', "GUTSCHEIN");
            label = label.replace('3', "EC");
            label = label.replace('4', "RECHNUNG");
            label = label.replace('7', "SPLIT");
            label = label.replace('9', "KREDITKARTE");

            splitPayments.push({
              label: label,
              amount: parseFloat(this.invoiceData.splitPaymentObj[payment])
            })
          }
        }

        //GENERATE INVOICE DATA
        printData = createInvoicePrintingData(invoicePositions, {
          saleID: this.invoiceNo,
          cashierID: this.invoiceData.cashierID,
          userID: this.invoiceData.sellerID,
          ts: this.invoiceData.invoiceTimestamp,
          tableNo: this.invoiceData.tableName,
          moneyGiven: this.invoiceData.moneyGiven,
          paymentType: this.invoiceData.typeOfPayment,
          splitPayments: splitPayments
        }, this.invoicePrinter.options, this.invoiceData.sellerData, false, false, goodsValue, vouchersValue);

        if (this.invoiceData.tableName != 0 || this.invoiceData.tableName.length > 0) {
          let today = new Date();
          let current_date = today.getDate() + "." + (today.getMonth() + 1) + "." + today.getFullYear();
          let current_city = "";

          if (this.settings.settings.hasOwnProperty("systemCity"))
            current_city = this.settings.settings.systemCity;

          printData.push({
            text: "\n-----------------------------------\n" + geol.t('generic.lang_hospitalityExpensesInformationAccordingTo') + " \n" + geol.t('generic.lang_paragraph4Paragraph5Number2EStG') + " \n-----------------------------------\n" + geol.t('generic.lang_hostedPersons') + ":\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n...................................\n\n\n" + geol.t('generic.lang_occasionOfTheHospitality') + ":\n...................................\n\n...................................\n\n\n" + geol.t('generic.lang_amountOfExpenditure') + ":\n\n...................................\n" + geol.t('generic.lang_whenCateringInTheRestaurant') + "\n\n...................................\n" + geol.t('generic.lang_inOtherCases') + "\n\n\n" + geol.t('generic.lang_date_place') + ':' + current_date + " / " + current_city + "\n\n...................................\n" + geol.t('generic.lang_signature') + "\n\n\n\n"
          });
        }
        if (this.invoiceData.fiscalJSONObj !== null) {
          //FIND LAST CUT OF PRINT DATA(INDEX)
          let lastCutIndex = null;

          printData.forEach((element, index) => {
            if (element.hasOwnProperty("cut"))
              if (element.cut)
                lastCutIndex = index;
          });
          try {
            //DELETE LAST CUT, BECAUSE WE WILL ADD CUT AFTER TSE DATA
            if (lastCutIndex !== null)
              this.$delete(printData, lastCutIndex);


            printData.push({
              fontSize: 1
            });

            printData.push({
              font: "B"
            });

            let fiscalJSON = this.invoiceData.fiscalJSONObj;

            if (fiscalJSON.hasOwnProperty("firstOrder")) {
              printData.push({
                text: "1. " + geol.t('generic.lang_best') + ".: " + fiscalJSON.firstOrder.orderCreated
              });
            }

            if (fiscalJSON.hasOwnProperty("tse")) {
              //PRINT EPOS
              printData.push({
                text: geol.t('accounting.lang_eazSerNr') + ": " + this.api.auth.posID + " - " + this.api.auth.cashierID
              });
              printData.push({
                text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tse.serialNumber
              });
              printData.push({
                text: geol.t('accounting.lang_tseStart') + ": " + fiscalJSON.transaction.startTime
              });
              printData.push({
                text: geol.t('accounting.lang_tseStop') + ":  " + fiscalJSON.transaction.stopTime
              });
              printData.push({
                text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.transaction.transactionNumber
              });
              printData.push({
                text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.transaction.signatureCounter
              });
              printData.push({
                text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.transaction.signature
              });

              printData.push({
                align: "center"
              });

              //ADD QR CODE
              printData.push({
                qrCode: fiscalJSON.qrCode
              });

              printData.push({
                cut: true
              });
            } else {
              //PRINT FISKALY

              printData.push({
                fontSize: 1
              });

              printData.push({
                font: "B"
              });
              printData.push({
                text: geol.t('accounting.lang_eazSerNr') + ": " + fiscalJSON.client_id
              });
              printData.push({
                text: geol.t('accounting.lang_tseSerNr') + ": " + fiscalJSON.tss_serial_number
              });
              printData.push({
                text: geol.t('accounting.lang_tseStart') + ": " + fiscalJSON.time_start
              });
              printData.push({
                text: geol.t('accounting.lang_tseStop') + ":  " + fiscalJSON.time_end
              });
              printData.push({
                text: geol.t('accounting.lang_tseTrxNr') + ": " + fiscalJSON.number
              });
              printData.push({
                text: geol.t('accounting.lang_tseSigCount') + ":" + fiscalJSON.signature.counter
              });
              printData.push({
                text: geol.t('accounting.lang_signatur') + ":  " + fiscalJSON.signature.value
              });

              printData.push({
                align: "center"
              });

              //ADD QR CODE
              printData.push({
                qrCode: fiscalJSON.qr_code_data
              });

              printData.push({
                cut: true
              });

            }
          } catch (err) {
            Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_tseError') + ':' + err,
              color: "error"
            });
            return;
          }
        } else {
          //CHECK IF LAST ELEMENT IS CUT
          let lastElememt = printData[(printData.length - 1)];

          if (!lastElememt.hasOwnProperty("cut"))
            printData.push({cut: true});
        }
        printData = this.addZVTPrinting(printData);
      }

      //RETOURE DRUCK
      if (this.invoiceData.invoiceType === 3) {
        //CALCULATE INVOICE TOTAL SUM + RE-MAP INVOICE POSITIONS
        let invoiceTotal = 0;
        let invoicePositions = [];

        this.items.forEach((item) => {
          //TOTAL SUM
          invoiceTotal += item.unitPrice * item.qty;

          invoicePositions.push({
            qty: item.qty,
            name: item.descr,
            tax: item.tax,
            sellPrice: item.unitPrice
          });
        }, 0);

        //GET PRINTING XML DATA
        printData = createRetourePrintingData(invoicePositions, {
          total: invoiceTotal,
          saleID: this.invoiceNo,
          cashierID: this.invoiceData.cashierID,
          userID: this.invoiceData.sellerID,
          ts: this.invoiceData.invoiceTimestamp,
        }, this.invoicePrinter.paperType);
      }
      // EPSON EPOS PRINT
      printDataFromPrinter(this.invoicePrinter, printData).then(() => {

      }).catch((err) => {
        console.log(err)
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred') + " " + err,
          color: "error"
        });
      }).finally(() => {
        this.loadingReprint = false;
      })
    },
    reprintLastInvoice() {

      this.loadingReprint = true;
      this.axios
          .post(ENDPOINTS.ACCOUNTING.LAST_USER_INVOICE)
          .then((res) => {
            if (res.data.success) {
              this.invoiceNo = "" + res.data.data.invoiceData.invoiceSaleRealm + res.data.data.invoiceData.invoiceSaleID + res.data.data.invoiceData.invoiceRandomizerID;
              this.items = res.data.data.invoicePositionData;
              this.invoiceData = res.data.data.invoiceData;
              this.printInvoice();
            } else {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error",
              });
            }
          })
          .catch((err) => {
            console.log(err)
            this.loadingReprint = false;
            Events.$emit("showSnackbar", {
              message: this.$t("generic.lang_errorOccurred") + " " + err,
              color: "error",
            });
          })
    },
  },
};
</script>
