<template>
    <div>
        <button @click="open"
                class="btn-icon-vertical btn-square btn-transition btn btn-outline-success">
            <span class="btn-icon-wrapper" style="display:block !important;">G</span>
        </button>

        <v-dialog persistent max-width="700" v-model="dialog">
            <v-card>
                <v-toolbar dense flat>
                    <v-toolbar-title>{{ $t('erp.lang_nav_voucherCardsScan') }}</v-toolbar-title>

                    <v-spacer/>

                    <v-btn icon @click="closeDialog">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-title>
                    <v-text-field :loading="loading" :disabled="loading" autofocus @focus="showTouchKeyboard"
                                  :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="giftCardID"
                                  v-on:keydown.enter="checkVoucher(giftCardID)"
                                  :label="$t('erp.lang_GiftCardNo')" dense outlined/>
                </v-card-title>

                <v-card-actions class="card-footer">
                    <v-btn :disabled="loading" @click="closeDialog" text color="error">{{ $t('generic.lang_cancel') }}</v-btn>
                    <v-spacer/>
                    <v-btn :loading="loading" :disabled="loading || giftCardID.length === 0" @click="checkVoucher(giftCardID)" color="primary">
                        {{ this.$t('generic.lang_next') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog :value="voucherErrorMessage.length > 0" max-width="300">
            <v-card>
                <v-card-title>Info</v-card-title>
                <v-card-text class="text-center">{{ voucherErrorMessage }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" text @click="voucherErrorMessage = ''">{{
                            $t('generic.lang_understand')
                        }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <div id="onScreenKeyboardDiv">
            <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                                :defaultKeySet="touchKeyboard.keySet"
                                :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                                :options="touchKeyboard.options" class="internalWidthExpanded"
                                id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
        </div>

        <BookItem ref="bookItem" :pos-type="posType" @voucherBooked="closeDialog"></BookItem>
    </div>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import BookItem from "@/mixins/pos/BookItem";

export default {
    name: "ButtonsGiftCardScanner",
    components: {BookItem},
    props: {
        posType: String
    },

    data: () => {
        return {
            loading: false,
            dialog: false,
            voucherErrorMessage: "",
            giftCardID: ""
        }
    },

    mixins: [mixin],

    computed: {
        ...mapState([
            'pos'
        ]),
        orderedItems() {
            if (this.posType === "retail") {
                return this.pos.retail.orderedItems;
            }

            return [];
        }
    },

    methods: {
        open() {
            this.$root.$emit("barcodeScanner.pause");

            this.giftCardID = "";

            this.dialog = true;
        },
        closeDialog() {
            this.dialog = false;

            this.$root.$emit("barcodeScanner.unpause");
        },
        checkVoucher(ean) {
          if(ean.includes("GF$") || ean.includes("V$"))
            this.$refs.bookItem.checkVoucher(ean);
          else
              this.voucherErrorMessage = this.$t('generic.lang_invalid_input')
        }
    }
}
</script>

<style scoped>
@media (min-width: 900px) {
    .btn-icon-vertical {
        padding: 0 !important;
    }
}

@media only screen and (max-height: 850px) {
    .btn-icon-vertical .btn-icon-wrapper {
        font-size: 150% !important;
    }
}
</style>