<template>
  <v-dialog v-model="dialogOpen" class="rounded" scrollable>
    <v-card class="border ma-0 mx-0 pa-0 px-0"
            style="border-radius: 20px !important;">
      <v-card-text class="ma-0 mx-0">
        <v-row>
          <v-col cols="12">
            <v-autocomplete
                ref="customerSearch"
                v-model="selectedCustomer"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                :items="showCustomers"
                :loading="loading"
                :search-input.sync="searchInput"
                cache-items
                class="mx-0"
                clearable
                hide-details
                item-text="text"
                item-value="id"
                :label="$t('generic.lang_custSearchLabel')"
                spellcheck="false"
                @change="loadCustomer($event)"
                @focus="showTouchKeyboard"
            >

              <template v-slot:append-outer>
                <span id="search_icon__container" v-bind:style="{
                  'background-color': colors.primary
                }">
                  <font-awesome-icon :icon="['fal', 'search']"
                                     size="2x"
                                     style="font-size: 18px"
                                     v-bind:style="{
                  color: 'white'
                                   }"/>
                </span>
                <br/>
              </template>

              <template v-slot:prepend>
                <font-awesome-icon :icon="['fal', 'user-cowboy']" class="text-muted"
                                   size="2x"
                                   style="font-size: 25px"/>
              </template>
              <template v-if="this.$store.getters['permissions/checkPermission']('create_cust')" v-slot:no-data>
                <v-container>
                  <v-layout>
                    <v-flex md12>
                      <v-btn :color="colors.primary" block elevation="0" @click="showCreateCustomerDialog = true">
                        {{ $t('customers.lang_create_NewCust') }}
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-form ref="projectFrom" lazy-validation>
              <users v-if="this.showUsers" v-model="user" :rules="[v => !!v]" dense filter="isServiceProvider=1"
                     label="Select service provider" outlined v-bind:user="this.user"/>
            </v-form>
          </v-col>

          <v-col cols="12">
            <div id="onScreenKeyboardDivCustomerSearch" style="z-index: 299 !important;">
              <vue-touch-keyboard v-if="touchKeyboardCustomerSearch.visible" id="onScreenKeyboard"
                                  :accept="hideTouchKeyboardCustomerSearch"
                                  :cancel="touchKeyboardCustomerSearch"
                                  :defaultKeySet="touchKeyboardCustomerSearch.keySet"
                                  :input="touchKeyboardCustomerSearch.input"
                                  :layout="touchKeyboardCustomerSearch.layout"
                                  :options="touchKeyboardCustomerSearch.options" class="internalWidthExpanded"/>
            </div>
          </v-col>

          <!--<v-col class="pt-0 pb-0" cols="12">
            <v-row align="center" class="pt-0 pb-0" justify="center">
              <v-col class="text-center" cols="4">
                <button
                    class="btn-icon-vertical text-muted bg-white text-danger btn-square  btn">

                  <font-awesome-icon :icon="['fal', '']" class="mt-1 mb-1 btn-icon-wrapper mx-auto ma-auto"
                                     style="font-size: 32px !important;"
                                     v-bind:style="{
                    color: colors.primary
                                     }"/>
                  <span style="font-size: 12px">
                    Rechnung
                  </span>
                </button>
              </v-col>

              <v-col class="text-center" cols="4">
                <button
                    class="btn-icon-vertical text-muted bg-white text-danger btn-square  btn">

                  <v-icon :color="colors.primary"
                          class="mt-1 mb-1 btn-icon-wrapper mx-auto ma-auto" style="font-size: 32px !important;">
                    call_split
                  </v-icon>
                  <span style="font-size: 12px">
                    Split
                  </span>
                </button>
              </v-col>

              <v-col class="text-center pa-0 px-0" cols="4">
                <button
                    class="btn-icon-vertical text-muted bg-white text-danger btn-square  btn">

                  <font-awesome-icon :icon="['fal', 'gift']" class="mt-1 mb-1 btn-icon-wrapper mx-auto ma-auto"
                                     style="font-size: 32px !important;"
                                     v-bind:style="{
                    color: colors.primary
                                     }"/>
                  <span style="font-size: 12px">
                    Aufshaus
                  </span>
                </button>
              </v-col>
            </v-row>
          </v-col>-->
        </v-row>
      </v-card-text>
      <v-card-actions class="text-right pr-3 card-footer pt-0 pb-0 bg-light">
        <v-btn class="mr-8" color="error" small text @click="closeDialog">
          {{ this.$t('generic.lang_cancel') }}
        </v-btn>
        <v-spacer/>
        <v-btn :color="colors.primary" :disabled="loading || selectedCustomer === null" :loading="loading"
               class="text-light mx-auto" elevation="0"
               right
               rounded
               small @click="setCustomer">
          {{ this.$t('generic.lang_next') }}
        </v-btn>

      </v-card-actions>
    </v-card>


    <CustomerCreateDialog v-if="this.$store.getters['permissions/checkPermission']('create_cust')" :colors="colors"
                          :pos-type="posType" :show-dialog="showCreateCustomerDialog"
                          @closeDialog="showCreateCustomerDialog = false"
                          @customerCreated="customerCreated"/>
  </v-dialog>
  <!--
  <v-dialog v-model="dialogOpen" max-width="600px" scrollable>
    <v-card>
      <v-toolbar color="#424242" dark dense flat>
        <v-toolbar-title>Kunden Suche</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text style="height: 400px;">

        <v-row>
          <v-col cols="12">
            <v-autocomplete
                ref="customerSearch"
                v-model="selectedCustomer"
                :data-layout="KEYBOARD.KEYSETS.NORMAL"
                :items="showCustomers"
                :loading="loading"
                :search-input.sync="searchInput"
                cache-items
                class="mx-0"
                clearable
                flat
                hide-details
                item-text="text"
                item-value="id"
                label="Kunden ID, Name"
                solo-inverted
                spellcheck="false"
                style="margin-top: 20px;"
                @change="loadCustomer($event)"
                @focus="showTouchKeyboard"
            >
              <template v-if="this.$store.getters['permissions/checkPermission']('create_cust')" v-slot:no-data>
                <v-container>
                  <v-layout>
                    <v-flex md12>
                      <v-btn block color="primary" @click="showCreateCustomerDialog = true">Neuen Kunden erstellen
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-container>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12">
            <button
                v-for="(p , i) in this.projects"
                :key="i"
                :class="selectedProject === p.projectUUID? 'btn mr-2 mb-2 btn-transition btn-hover-shine hovering-zoom btn-primary border-none text-light' : 'btn mr-2 mb-2 btn-transition btn-hover-shine hovering-zoom'"
                v-bind:style="{
                border: '1px solid ' + p.projectColor,
                color: p.projectColor
              }"
                @click="setProject(p)">{{ p.projectName }}
            </button>
          </v-col>
        </v-row>

      </v-card-text>
      <v-card-text>
        <v-form ref="projectFrom" lazy-validation>
          <users v-if="this.showUsers" v-model="user" :rules="[v => !!v]" dense filter="isServiceProvider=1"
                 label="Select service provider" outlined v-bind:user="this.user"/>
        </v-form>
      </v-card-text>
      <v-divider/>

      <v-card-actions>

        <v-btn class="fontTextSize" color="error" text @click="closeDialog">Abbrechen</v-btn>

        <v-btn v-if="this.$store.getters['permissions/checkPermission']('create_cust')"
               class="fontTextSize" color="primary" text
               @click="showCreateCustomerDialog = true">Neuen
          Kunden erstellen
        </v-btn>
        <div v-if="$vuetify.breakpoint.smAndDown"><br><br></div>

        <v-btn :disabled="loading || selectedCustomer === null" :loading="loading"
               class="fontTextSize"
               color="success" @click="setCustomer">Kunde auswählen
        </v-btn>

      </v-card-actions>

      <div id="onScreenKeyboardDivCustomerSearch" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboardCustomerSearch.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboardCustomerSearch"
                            :cancel="touchKeyboardCustomerSearch"
                            :defaultKeySet="touchKeyboardCustomerSearch.keySet"
                            :input="touchKeyboardCustomerSearch.input" :layout="touchKeyboardCustomerSearch.layout"
                            :options="touchKeyboardCustomerSearch.options" class="internalWidthExpanded"/>
      </div>

    </v-card>

    <CustomerCreateDialog v-if="this.$store.getters['permissions/checkPermission']('create_cust')"
                          :pos-type="posType" :show-dialog="showCreateCustomerDialog"
                          @closeDialog="showCreateCustomerDialog = false"
                          @customerCreated="customerCreated"/>
  </v-dialog>

  -->
</template>


<style scoped>


@media (max-width: 1024px) {
  .fontTextSize {
    font-size: 0.480rem !important;
    margin: 0px !important;
  }
}

@media (min-width: 1025px) {
  .fontTextSize {
    font-size: 0.875rem !important;
  }
}
</style>

<script>
import {ENDPOINTS, KEYBOARD} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faGift, faSearch, faUserCircle, faUserCowboy} from '@fortawesome/pro-light-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import CustomerCreateDialog from "@/components/pos/CustomerCreateDialog";
import Users from "@/components/common/Users";

library.add(
    faUserCircle, faSearch, faUserCowboy, faGift
);

export default {
  name: "MobileInvoiceCustomerSearch",

  props: {
    posType: String,
    dialogOpen: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Object,
      required: true
    }
  },

  components: {
    Users,
    CustomerCreateDialog,
    'font-awesome-icon': FontAwesomeIcon,
  },

  data() {
    return {
      searchInput: "",
      projects: [],
      loading: false,
      showCustomers: [],
      showCreateCustomerDialog: false,
      selectedCustomer: null,
      showUsers: false,
      selectedProject: "",
      user: null,
      KEYBOARD,
      touchKeyboardCustomerSearch: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },

  watch: {
    showDialog(val) {
      this.$emit('input', val);
    },
    dialogOpen(val) {
      if (!val) {
        this.closeDialog();
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    searchInput(val) {
      val && val !== this.select && this.searchForItem(val)
    },
    showCreateCustomerDialog(val) {
      if (val) {
        this.$refs.customerSearch.blur();
      }
    }
  },
  computed: {
    ...mapState([
      'freetexttags',
      'touchkeyboard'
    ]),
  },
  methods: {
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardCustomerSearch.input = e.target;
      this.touchKeyboardCustomerSearch.layout = e.target.dataset.layout;

      if (!this.touchKeyboardCustomerSearch.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardCustomerSearch.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardCustomerSearch() {
      if (this.touchKeyboardCustomerSearch.visible) {
        this.touchKeyboardCustomerSearch.visible = false;
      }
    },
    dismissOnScreenKeyboardCustomerSearch: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivCustomerSearch')) {
        if (!document.getElementById('onScreenKeyboardDivCustomerSearch').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardCustomerSearch();
          }
        }
      }
    },
    setProject(p) {
      this.showUsers = true;
      this.selectedProject = p;
    },
    loadCustomer(customer_id) {
      if (customer_id == undefined || customer_id == null) return;
      this.axios.post(ENDPOINTS.CUSTOMERS.PROJECTS.GETALL, {
        ascCustomerId: customer_id
      }).then((res) => {

        if (res.data.success == true) {
          this.projects = res.data.projects;
        } else {

          /* DO NOT DISPLAY ERROR BECAUSE NO PROJECT WAS FOUND

          Events.$emit("showSnackbar", {
              message: this.$t('generic.lang_errorOccurred'),
              color: "error"
            });*/
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {

      })
    },
    closeDialog() {
      //RESET DATA
      this.resetData();

      this.$emit('input', false);
    },
    //THIS FUNCTION IS FOR GETTING ITEMS FROM AVAILABLE ITEMS
    searchForItem(searchValue) {
      this.loading = true;

      //AXIOS CALL
      this.axios.get(ENDPOINTS.ERP.CUSTOMER.GET, {
        params: {
          search: searchValue
        }
      }).then((res) => {
        this.loading = false;

        if (res.status === 200) {
          if (res.data.results.length > 0) {
            this.showCustomers = res.data.results;
          } else {
            this.showCustomers = [];
          }
        }
      }).catch(() => {

      });
    },
    setCustomer() {
      if (!this.$refs.projectFrom.validate()) return;
      this.loading = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
        customerID: this.selectedCustomer
      }).then((res) => {
        if (res.data.success) {
          this.$store.dispatch("pos/setCustomer", {
            posType: this.posType,
            customer: res.data.customer
          }).then(() => {
            this.closeDialog();
            this.resetData();
          });
        } else {
          this.loading = false;

          Events.$emit("showSnackbar", {
            color: "error",
            message: res.data.msg
          });
        }
      }).catch((err) => {
        this.loading = false;

        Events.$emit("showSnackbar", {
          color: "error",
          message: err.message
        });
      });
    },
    customerCreated(customerID) {
      this.selectedCustomer = customerID;

      this.showCreateCustomerDialog = false;
      this.setCustomer();
    },
    resetData() {
      this.loading = false;
      this.searchInput = "";
      this.selectedCustomer = null;
      this.showCustomers = [];
      this.projects = [];
      this.showUsers = false;
      this.selectedProject = "";
    }
  },
  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardCustomerSearch);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardCustomerSearch);
  }
}
</script>

<style scoped>

#search_icon__container {
  width: 27px !important;
  height: 27px !important;
  padding: 6px;
  border-radius: 100%;
}

.outlined {
  outline-color: #1F9BCF !important;
}


</style>

<style>
button:active, button:active::after, button:focus {
  outline: none !important;
}
</style>
