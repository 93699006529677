<template>
  <div class="pa-0 px-0 app-container  overflow-y-auto" fluid
       style="touch-action: manipulation;">
    <top-bar :pos-type="this.posType" :tab="tab"/>
    <v-card id="main-card" class="transparent pa-0 mx-0" elevation="0">
      <v-card-text class="pa-0 mx-0">


        <!-- this component allow us to show tables in mobile version -->
        <mobile-table v-if="this.tab==='home'" v-model="tab" :colors="this.colors" :pos-type="this.posType"
                      v-bind:ch-table="this.chTable"
                      v-bind:saved-tables="this.tables" :show-create-table="showCreateTableDialog"
                      @closeAddTable="showCreateTableDialog=false"
                      @Items="Items"
                      @getTables="getTables"
                      @tableChanged="tableChanged"></mobile-table>

        <!-- this component allow us to show items & wire groups in mobile version -->
        <mobile-grid ref="items" v-else-if="this.tab==='items'" :colors="this.colors" :pos-type="posType"
                     @redirect="redirect"/>

        <!--this component allow us to checkout-->
        <mobile-cashout v-else-if="this.tab==='payment'" :colors="this.colors" :pos-type="posType"
                        @closeDialog="tab='items'" @redirect="redirect"
                        @setPaymentResponse="setPaymentResponse"/>

        <!--this component allow us to print and send invoices after payment-->
        <send-invoice v-else-if="this.tab==='send_invoice'" :colors="this.colors"
                      :pos-type="posType" v-bind:invoice-result-data="this.invoiceResultData" v-bind:invoice-x-m-l-print-data="this.invoiceXMLPrintData"
                      @redirect="redirect"/>

        <!-- this component allow us to select customer -->
        <mobile-invoice-customer-search v-model="customer_dialog" :colors="colors" :pos-type="this.posType"
                                        v-bind:dialog-open="customer_dialog"/>
        <!--        this dialog allow us to select an option-->
        <mobile-options-dialog v-model="options_dialog" :colors="this.colors" :pos-type="this.posType"
                               v-bind:dialog="options_dialog"
                               @changeTable="changeTable"
                               @redirect="redirect"/>
      </v-card-text>
    </v-card>
    <!-- bottom navigation section -->
    <bottom-bar v-model="tab" :pos-type="this.posType" v-bind:tab="this.tab" @redirect="redirect"
                @showAddTable="showCreateTableDialog=true"/>
    <BarcodeKeyboard :pos-type="posType" include-item-booking></BarcodeKeyboard>

    <!-- CreateShiftDialog -->
    <StartShiftCounting />
  </div>
</template>

<script>
import StartShiftCounting from "@/components/pos/StartShiftCounting";

import BarcodeKeyboard from "@/mixins/barcodeKeyboard";
import 'vue-swipe-actions/dist/vue-swipe-actions.css';
import MobileGrid from "@/components/pos/mobile/wedgets/MobileGrid";
import TopBar from "@/components/pos/mobile/wedgets/TopBar";
import MobileTable from "@/components/pos/mobile/wedgets/MobileTable";
import {ENDPOINTS} from "@/config";
import BottomBar from "@/components/pos/mobile/wedgets/BottomBar";
import MobileInvoiceCustomerSearch from "@/components/pos/mobile/wedgets/MobileInvoiceCustomerSearch";
import MobileOptionsDialog from "@/components/pos/mobile/wedgets/MobileOptionsDialog";
import MobileCashout from "@/components/pos/mobile/wedgets/MobileCashout";
import {mapState} from "vuex";
import SendInvoice from "@/components/pos/mobile/wedgets/SendInvoice";
import {Events} from "@/plugins/events";

export default {
  name: "MobileGastro",
  components: {
    BarcodeKeyboard,
    SendInvoice,
    MobileCashout,
    MobileOptionsDialog,
    MobileInvoiceCustomerSearch,
    BottomBar,
    MobileTable,
    TopBar,
    MobileGrid,
    StartShiftCounting
  },
  computed: {
    ...mapState([
      'pos',
      'user',
      'api'
    ]),
  },
  methods: {
    changeTable() {
      this.chTable = true;
    },
    tableChanged() {
      this.chTable = false;
    },
    setPaymentResponse({invoiceResultData,invoiceXMLPrintData}) {
      this.invoiceResultData = invoiceResultData;
      this.invoiceXMLPrintData = invoiceXMLPrintData;
    },
    redirect(route) {
      this.tab = route;
      //this.chTable = false;
    },
    Items(val) {
      this.tab = val;
    },
    getTables() {
      this.loading = true;
      this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.INFO).then((res) => {
        if (res.status === 200) {
          this.tables = res.data.tables;
          this.loading = false;
        }
      }).catch((err) => {
        this.loading = false;
      });
    },
  },
  watch: {
    tab: function (val, old) {

      if (val === 'items' && old === 'payment') {
        if(this.$refs.items)
          this.$refs.items.getGastroTableFromAPI();
        //this.tab = "send_invoice";
      } else if (val === 'customer') {
        this.customer_dialog = true;
        this.tab = old;
      } else if (val === 'options') {
        this.options_dialog = true;
        this.tab = old;
      } else if (val === 'home') {
        //EMIT TO SOCKET SERVER THAT WE LEAVE THE TABLE NOW
        this.$socket.emit("tableplan.leaveTable", this.pos.gastro.table.name);
        this.getTables();
        // REMOVE THE reActivateSale FROM THE URL CAUSE WE STILL IN THE SAME URL
        if (this.$route.query.hasOwnProperty("reActivateSale") || this.$route.query.hasOwnProperty("notification")) {
          this.$router.replace({name: 'pos.gastro', query: null});
        }
        //CLEAR BOOKED ITEMS
        this.$store.commit("pos/gastro/setOrderedItems", {
          partyName: "Partei 1",
          orders: []
        });
      } else if (val === 'payment') {
        if (this.pos.gastro.table === null) {
          this.$bvToast.toast(this.user.name + ", " + this.$t('generic.lang_pleaseSelectTable'), {
            title: this.$t('generic.lang_noTableSelected'),
            autoHideDelay: 4000,
            variant: 'danger'
          });
          this.tab = old;
        }
      }
    }
  },

  sockets: {
    connect: function () {
      this.$socket.emit("tableplan.checkTableLock", (this.pos.gastro && this.pos.gastro.table && this.pos.gastro.table.name) ? this.pos.gastro.table.name : "");
    },
    "tableplan.checkTableLock": function (locked) {
      if (locked) {
        this.showTableLockedDialog = true;
      } else {
        this.$socket.emit("tableplan.setTable", (this.pos.gastro && this.pos.gastro.table && this.pos.gastro.table.name) ? this.pos.gastro.table.name : "");
      }
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.pos.gastro.table)
      this.$socket.emit("tableplan.leaveTable", this.pos.gastro.table.name);
    next();
  },
  beforeDestroy() {
    this.$store.dispatch("pos/setCustomer", {
      posType: this.posType,
      customer: null
    });

    Events.$off('ordering');
  },

  data: () => {
    return {
      chTable: false,
      invoiceResultData: null,
      invoiceXMLPrintData: [],
      colors: {
        primary: "#FF6969"
      },
      options_dialog: false,
      customer_dialog: false,
      tables: [],
      posType: "gastro",
      value: 0,
      enabled: true,
      room_id: 0,
      tab: 'home',
      showCreateTableDialog: false,
    }
  },

  mounted() {

    Events.$on("redirect", route => {
      if (route === 'customer' || (route === 'items')) {
        this.redirect(route)
      }
      if(route === 'home'){
        this.$store.dispatch("pos/gastro/setOpenItems", {
          party: {
            name: "Partei 1",
          },
          orders: []
        });
      }
    });
    if (this.$route.query.hasOwnProperty("reActivateSale")) {
      //emiting an event to update the order in the mobilegrid component,
      this.$root.$emit('updateTableOrders');
      this.redirect('items');
      return;
    }
    this.getTables();

  }
}
</script>

<style>
.btn {
  outline: none !important;
}

#main-bottom-nav {
  z-index: 1000;
}

#close-bottom-swiper {
  max-width: 30px !important;
}

.bottom-btns {
  width: 20px !important;
  padding: 0 !important;
}

.bottom-btns > span {
  font-size: 12px !important;
}

#main-card {
  overflow-y: auto !important;
  padding-bottom: 108px !important;
}
</style>
