<template>
  <v-dialog
    v-model="showDialog"
    persistent
    scrollable
    max-width="900"
  >
    <v-card
      :disabled="loading"
      :loading="loading"
    >
      <v-toolbar
        dense
        flat
      >
        <v-toolbar-title>{{ $t('erp.lang_open_order') }}</v-toolbar-title>

        <v-spacer />

        <v-btn
          icon
          @click="closeDialog"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0" />
      <v-card-text v-if="orders.length === 0 && !loading">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-alert
                text
                color="primary"
                class="text-center"
                >{{ $t('generic.lang_noOrdersFound') }}</v-alert
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <div
        v-if="orders.length > 0"
        style="
          text-align: right;
          margin-top: 10px;
          margin-bottom: 10px;
          height: 35px;
        "
      >
        <v-btn
          color="error"
          text
          @click="deleteSavedOrder()"
          >{{ $t('erp.lang_button_del_openorder') }}</v-btn
        >
      </div>

      <v-simple-table
        v-if="orders.length > 0"
        class="table-striped"
      >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">#</th>
              <th
                class="text-left"
                style="width: 190px"
              >
                {{ $t('generic.lang_customer') }}
              </th>
              <th class="text-left">{{ $t('settings.lang_operator') }}</th>
              <th class="text-left">{{ $t('generic.lang_date') }}</th>
              <th class="text-left">{{ $t('generic.lang_total') }}</th>
              <th class="text-left">{{ $t('erp.lang_QTY') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="order in orders"
              :key="order.id"
            >
              <td>{{ order.id }}</td>

              <!-- Customer -->
              <td v-if="order.customer === null">-</td>
              <td v-if="order.customer !== null">
                {{ order.customer.first_name }} {{ order.customer.last_name }}
              </td>

              <td>{{ order.soldBy }}</td>
              <td>{{ order.date }}</td>
              <td>{{ formatPrice(order.totalPrice) | currency }}</td>
              <td>{{ order.totalItems }}</td>
              <td>
                <v-btn
                  text
                  color="error"
                  @click="deleteSavedOrder(order)"
                  >{{ $t('generic.lang_delete') }}</v-btn
                >
                <v-btn
                  color="success"
                  @click="openSavedOrder(order)"
                  >{{ $t('generic.lang_open') }}</v-btn
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-actions>
        <v-btn
          color="primary"
          text
          @click="closeDialog"
          >{{ $t('generic.lang_cancel') }}</v-btn
        >
      </v-card-actions>
    </v-card>

    <!-- Delete Warning -->
    <v-dialog
      persistent
      v-model="showDeleteWarning"
      max-width="400"
    >
      <v-card>
        <v-toolbar
          color="#424242"
          dark
          dense
          flat
        >
          <v-toolbar-title>{{ $t('erp.lang_posDeleteOrder') }}</v-toolbar-title>

          <v-spacer />

          <v-btn
            icon
            @click="cancelDelete"
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="text-center"
              >
                <p style="font-size: 17px">
                  {{ $t('generic.lang_doYouReallyWantTo') }}
                  <span v-if="orderToDelete === null">{{ $t('generic.lang_allOperations') }}</span>
                  <span v-if="orderToDelete !== null">{{ $t('generic.lang_theProcess') }}</span>
                  {{ $t('generic.lang_delete') }}? <br /><br /><b>{{ $t('generic.lang_actionCannotbeUndone') }}</b>
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="cancelDelete"
            >{{ $t('generic.lang_cancel') }}</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :loading="loading"
            :disabled="loading"
            color="success"
            @click="deleteSavedOrder(orderToDelete, true)"
          >
            {{ $t('generic.lang_delete') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- TSE Error Dialog -->
    <v-dialog
      :value="tseErrorMessage.length > 0"
      max-width="290"
      persistent
    >
      <v-card>
        <v-card-title>{{ $t('generic.lang_tseError') }}</v-card-title>
        <v-card-text class="text-center">{{ tseErrorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="closeTSEErrorDialog"
          >
            {{ $t('generic.lang_understand') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ENDPOINTS } from '../../../config';
import { Events } from '../../../plugins/events';

export default {
  name: 'ButtonsOpenSavedOrderDialog',

  props: {
    showDialog: Boolean,
    posType: String,
    item: Object,
  },

  data() {
    return {
      loading: false,
      orders: [],
      orderToDelete: null,
      showDeleteWarning: false,
      tseErrorMessage: '',
    };
  },

  computed: {
    ...mapState(['pos']),
    ...mapGetters({
      fiscalClient: 'tse/fiscalClient',
    }),
    formatPrice() {
      return (price) => {
        return Number(price?.replace(',', '.'));
      };
    },
  },

  watch: {
    showDialog(val) {
      if (val) {
        this.orders = [];

        this.getSavedOrders();
      }
    },
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
    getSavedOrders() {
      if (this.posType === 'retail') {
        this.loading = true;

        this.axios
          .post(ENDPOINTS.POS.RETAIL.ORDERS.GET, {
            orderID: 0,
          })
          .then((res) => {
            if (res.data.success) {
              this.orders = res.data.orders;

              this.loading = false;
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    openSavedOrder(order) {
      if (this.posType === 'retail') {
        this.loading = true;

        this.axios
          .post(ENDPOINTS.POS.RETAIL.ORDER.GET, {
            orderID: order.id,
          })
          .then((res) => {
            if (res.data.success) {
              this.$store.commit('pos/retail/setItems', {
                orders: res.data.order.items,
              });

              this.$store.commit('pos/retail/openSavedOrder', res.data.order);

              //GET CUSTOMER
              if (res.data.order.customer !== null) {
                this.axios
                  .post(ENDPOINTS.CUSTOMERS.CUSTOMER.GETEDIT, {
                    customerID: res.data.order.customer.id,
                  })
                  .then((res2) => {
                    if (res2.data.success) {
                      this.$store
                        .dispatch('pos/setCustomer', {
                          posType: this.posType,
                          customer: res2.data.customer,
                        })
                        .then(() => {
                          this.closeDialog();
                          this.loading = false;
                        });
                    }
                  })
                  .catch(() => {
                    this.closeDialog();
                    this.loading = false;
                  });
              } else {
                this.closeDialog();
                this.loading = false;
              }
            }
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    deleteSavedOrder(order = null, skipWarning = false) {
      if (!skipWarning) {
        this.showDeleteWarning = true;
        this.orderToDelete = order;
        return;
      }

      this.loading = true;

      this.axios
        .post(ENDPOINTS.POS.RETAIL.ORDERS.DELETE, {
          orderID: order !== null ? order.id : 0,
        })
        .then((res) => {
          if (res.data.success) {
            this.orderToDelete = null;
            this.showDeleteWarning = false;

            this.getSavedOrders();
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    closeTSEErrorDialog() {
      this.tseErrorMessage = '';
      this.cancelDelete();
    },

    cancelDelete() {
      this.orderToDelete = null;
      this.showDeleteWarning = false;
    },
  },
};
</script>
