<template>
    <v-row v-if="template !== null">
        <v-col cols="12">
            <v-card>
                <v-card-title>{{ $t('generic.lang_template') }} {{ template.id }}</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="3">
                            <v-btn color="primary" @click="showLogoUploadDialog = true"><v-icon style="margin-right: 10px;">backup</v-icon> {{ $t('generic.lang_uploadLogo') }} </v-btn>
                            <v-btn :loading="deleteLogoLoading" :disabled="deleteLogoLoading" @click="deleteLogo" v-if="template.logo !== null" color="error"><v-icon style="margin-right: 10px;">delete</v-icon> {{ $t('generic.lang_removeLogo') }} </v-btn>
                        </v-col>

                        <v-col v-if="template.logo === null || template.logo.length === 0" cols="9" class="justify-center">
                            <v-alert text color="info" class="text-center"><b> {{ $t('generic.lang_info') }} :</b> {{ $t('settings.lang_youHaveNotUploadedLogo') }} <br> {{ $t('settings.lang_3posCashRegisterLogoUsed') }} </v-alert>
                            <v-img height="100" contain src="../../../assets/images/3pos.svg"></v-img>
                        </v-col>

                        <v-col v-else cols="9" class="justify-center">
                            <v-img height="100" contain :src="logoUrl"></v-img>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-card :disabled="loading" :loading="loading">
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="3">
                                <v-radio-group v-model="templateType" :label="$t('generic.lang_mode')">
                                    <v-radio :value="0" :label="$t('generic.lang_simple')"></v-radio>
                                    <v-radio :value="1" :label="$t('generic.lang_youtubePlayer')"></v-radio>
                                    <v-radio :value="2" :label="$t('generic.lang_slideShow')"></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col v-if="templateType === 1" cols="9">
                                <v-text-field :label="$t('generic.lang_youtubeVideoURL')" placeholder="https://www.youtube.com/watch?v=wJhAbFSiANU" v-model="template.youtubeURL" outlined></v-text-field>
                            </v-col>

                            <v-col v-if="templateType === 2" cols="9">
                                <v-btn color="primary" @click="showImageUploadDialog = true"> {{ $t('generic.lang_uploadImage') }} </v-btn>

                                <v-container fluid>
                                    <v-row>
                                        <v-col
                                                v-for="image in template.slideshow_images"
                                                :key="image"
                                                class="d-flex child-flex"
                                                cols="4"
                                        >
                                            <TemplateSlideshowImage @imageDelete="imageDeleted" :template="template" :image="image"></TemplateSlideshowImage>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="success" :loading="loading" :disabled="loading" @click="saveTemplate"> {{ $t('generic.lang_save') }} </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>

        <TemplateLogoUploadDialog :show-dialog="showLogoUploadDialog" :template="template" @imageUploaded="logoUploaded" @closeDialog="showLogoUploadDialog = false"></TemplateLogoUploadDialog>
        <TemplateImageUploadDialog :show-dialog="showImageUploadDialog" :template="template" @imageUploaded="imageUploaded" @closeDialog="showImageUploadDialog = false"></TemplateImageUploadDialog>
    </v-row>
</template>

<script>
    import {ENDPOINTS} from "../../../config";
    import TemplateSlideshowImage from "./TemplateSlideshowImage";
    import TemplateImageUploadDialog from "./TemplateImageUploadDialog";
    import TemplateLogoUploadDialog from "./TemplateLogoUploadDialog";
    import {mapState} from "vuex";

    export default {
        name: "Template",

        components: {
            TemplateLogoUploadDialog,
            TemplateImageUploadDialog,
            TemplateSlideshowImage
        },

        data() {
            return {
                template: null,
                loading: false,
                deleteLogoLoading: false,
                templateType: 0,
                showLogoUploadDialog: false,
                showImageUploadDialog: false
            }
        },

        mounted() {
            this.getTemplate();
        },

        computed: {
            ...mapState([
                'api'
            ]),
            logoUrl() {
                if(this.template === null)
                    return "";

                return "https://img.3pos.de/unsafe/billsys_"+this.api.auth.posID+"/original/"+this.template.logo+".png";
            }
        },

        methods: {
            getTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.TEMPLATE.GET, {
                    templateID: parseInt(this.$route.params.id)
                }).then((res) => {
                    if(res.data.success) {
                        this.template = res.data.template;

                        //PARSE TYPE
                        if(this.template.youtubePlayer === 1)
                            this.templateType = 1;

                        if(this.template.slideshow === 1)
                            this.templateType = 2;

                        this.loading = false;
                    }
                });
            },
            saveTemplate() {
                this.loading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAYS.TEMPLATE.UPDATE, {
                    templateID: this.template.id,
                    templateType: this.templateType,
                    youtubeURL: this.template.youtubeURL
                }).then((res) => {
                    if(res.data.success) {
                        this.$router.push('/settings/cashierSettings/customerDisplay');

                        this.$socket.emit("customerdisplay.templateUpdated");
                    }
                });
            },
            logoUploaded() {
                this.$socket.emit("customerdisplay.templateUpdated");

                this.showLogoUploadDialog = false;
                this.getTemplate();
            },
            imageUploaded() {
                this.$socket.emit("customerdisplay.templateUpdated");

                this.showImageUploadDialog = false;
                this.getTemplate();
            },
            imageDeleted() {
                this.$socket.emit("customerdisplay.templateUpdated");

                this.getTemplate();
            },
            deleteLogo() {
                this.deleteLogoLoading = true;

                this.axios.post(ENDPOINTS.SETTINGS.CUSTOMERDISPLAY.TEMPLATE.LOGO.DELETE, {
                    templateID: this.template.id
                }).then((res) => {
                    if(res.data.success) {
                        this.$socket.emit("customerdisplay.templateUpdated");

                        this.getTemplate();
                    }

                    this.deleteLogoLoading = false;
                }).catch(() => {
                    this.deleteLogoLoading = false;
                });
            }
        }
    }
</script>