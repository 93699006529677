<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="400">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_nav_voucherCards') }}</v-toolbar-title>
        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text>
        <v-form v-model="valid" lazy-validation @submit.prevent ref="giftCardForm" >
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                  @focus="showTouchKeyboard"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  v-if="giftCardID === undefined" v-model="scannedGiftCardID" outlined filled
                  :rules="[rules.required,isValideUUID(scannedGiftCardID)?rules.uuid:rules.maxNumbers(scannedGiftCardID,7)]"
                  :label="$t('erp.lang_GiftCardNo')"/>

              <v-text-field

                  v-model="voucherValue"
                  outlined
                  filled
                  type="number"
                  :rules="[rules.required]"
                  :label="$t('erp.lang_GiftCardValue')"/>
              <NumpadOnly ref="numpad" @updateValue="numpadUpdateValue" @clickCustomButton="numpadClickedCustomButton"
                          @resetField="numpadResetField" :custom-buttons="customButtons"></NumpadOnly>
            </v-col>
          </v-row>
        </v-container>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="createVoucher"
               :disabled="!valid">{{ $t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDivGiftCard" style="z-index: 299 !important;">
        <vue-touch-keyboard id="onScreenKeyboard" class="internalWidthExpanded" :options="touchKeyboardGiftCard.options"
                            :defaultKeySet="touchKeyboardGiftCard.keySet" v-if="touchKeyboardGiftCard.visible"
                            :layout="touchKeyboardGiftCard.layout" :cancel="touchKeyboardGiftCard"
                            :accept="hideTouchKeyboardGiftCard" :input="touchKeyboardGiftCard.input"/>
      </div>

    </v-card>

  </v-dialog>

</template>

<script>
import {KEYBOARD} from "../../../config";
import NumpadOnly from "../NumpadOnly";
import {mapGetters, mapState} from "vuex";
import validation from "../../../mixins/validation/validation";
import { Events } from '../../../plugins/events';

export default {
  name: "ButtonsGiftCardDialog",

  components: {
    NumpadOnly
  },

  props: {
    showDialog: Boolean,
    posType: String,
    giftCardID: String
  },

  data() {
    return {
      customButtons: [
        {
          name: '10euro',
          text: '10 '+this.$store.getters['settings/currencyData']('symbol'),
          value: 10
        },
        {
          name: '25euro',
          text: '25 '+this.$store.getters['settings/currencyData']('symbol'),
          value: 25
        },
        {
          name: '50euro',
          text: '50 '+this.$store.getters['settings/currencyData']('symbol'),
          value: 50
        }
      ],
      voucherValue: 0,
      valid: true,
      scannedGiftCardID: "",
      KEYBOARD,
      touchKeyboardGiftCard: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
      awaitingGFID: null,
    }
  },
  mixins:[validation],
  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
    ...mapGetters('pos/gastro', {
      gastroOpenItems: 'openItems',
    }),
    ...mapState(['pos']),
    computedGiftCardID() {
      if (this.giftCardID === undefined) {
        if (this.scannedGiftCardID.length > 0) {
          if (this.scannedGiftCardID.includes("GF$")) {
            let giftCardID = this.scannedGiftCardID.split("GF$");
            return giftCardID[1];
          } else {
            return this.scannedGiftCardID;
          }
        }
      } else {
        return this.giftCardID;
      }

      return "";
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.voucherValue = "";
        this.scannedGiftCardID = "";

        //CLEAR NUMPAD
        this.$refs.numpad.resetField();
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    },
    scannedGiftCardID: function () {
      clearTimeout(this.awaitingGFID);

      this.awaitingGFID = setTimeout(() => {
        if (this.scannedGiftCardID.length > 0) {
          if (this.scannedGiftCardID.includes("GF$")) {
            let scannedGF = this.scannedGiftCardID.split("GF$");
            this.scannedGiftCardID = scannedGF[1];
          }
        }
      }, 800);
    },
  },

  methods: {
    isValideUUID(uuid){
      const regex=/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return regex.test(uuid);
    },
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardGiftCard.input = e.target;
      this.touchKeyboardGiftCard.layout = e.target.dataset.layout;

      if (!this.touchKeyboardGiftCard.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardGiftCard.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardGiftCard() {
      if (this.touchKeyboardGiftCard.visible) {
        this.touchKeyboardGiftCard.visible = false;
      }
    },
    dismissOnScreenKeyboardGiftCard: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivGiftCard')) {
        if (!document.getElementById('onScreenKeyboardDivGiftCard').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardGiftCard();
          }
        }
      }
    },
    closeDialog() {
      this.$refs.giftCardForm.reset();
      this.$emit("closeDialog");
    },
    numpadUpdateValue(value) {
      this.voucherValue = value;
    },
    numpadClickedCustomButton(customButton) {
      this.voucherValue = customButton.value;
    },
    numpadResetField() {
      this.voucherValue = "";
    },
    createVoucher() {
      //SET OUR NEW VALUES ON ITEM
      if(!this.$refs.giftCardForm.validate())
        return;

      let item = {
        id: "GIFTCARD_" + this.computedGiftCardID,
        ean: "GF$" + this.computedGiftCardID,
        name: "Gutscheinkarte " + this.computedGiftCardID,
        originalSellPrice: parseFloat(this.voucherValue.toString().replace(',','.')),
        sellPrice: parseFloat(this.voucherValue.toString().replace(',','.')),
        selectedExtras: [],
        mealSizeID: 0,
        weight: null,
        taxValue: 0,
        depositgroupID: 0,
        discount: 0,
        warenameOverwrite: null,
        individualBookingNo: null
      };

      let isAlreadyOrdered = false;

      if (this.posType === "gastro") {
        if (this.gastroOpenItems.length > 0) {
          //CHECK IF ITEM ALREADY ORDERED
          isAlreadyOrdered = this.gastroOpenItems.find(cartItem => {
            if (cartItem.id === item.id) return true;

            return false;
          });
        }
      } else if (this.posType === "retail") {
         if (this.pos.retail.orderedItems.length > 0) {
          //CHECK IF ITEM ALREADY ORDERED
          isAlreadyOrdered = this.pos.retail.orderedItems.find(cartItem => {
            if (cartItem.id === item.id) return true;

            return false;
          });
        }
      }

      if (isAlreadyOrdered) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_giftCard_already_redeemed'),
          color: "warning"
        });

        this.closeDialog();
      } else {
        if (this.posType === "gastro") {
          this.$store.dispatch("pos/gastro/bookItem", item).then(() => {
            this.voucherValue = "";

            this.closeDialog();
          });
          
        } else if (this.posType === "retail") {
          this.$store.dispatch("pos/retail/bookItem", item).then(() => {
            this.voucherValue = "";

            this.closeDialog();
          });
        }
      }
    }
  },
  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardGiftCard);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);
    document.removeEventListener('click', this.dismissOnScreenKeyboardGiftCard);
  }
}
</script>
