<template>
  <div>
    <button @click="open" class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary">
      <v-icon v-if="!loading" class="btn-icon-wrapper" style="display:block !important;"> mdi-book</v-icon>
      <v-progress-circular v-else indeterminate width="2" size="25" style="margin-top: 15px;" color="primary"></v-progress-circular>
    </button>

    <CashbookCreateEntryDialog
        v-if="!loading"
        :taxes="itemTaxSelect"
        :accounts="accounts"
        :cashier-i-ds="cashierIDs"
        :show-dialog="showCreateEntryDialog"
        @closeDialog="showCreateEntryDialog = false"
        @entryCreated="showCreateEntryDialog = false"
    ></CashbookCreateEntryDialog>

    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidthExpanded"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import mixin from "@/mixins/KeyboardMixIns";
import CashbookCreateEntryDialog from "@/components/accounting/CashbookCreateEntryDialog";
import {Events} from "@/plugins/events";

export default {
  name: "ButtonCashBook",

  props: {
    posType: String
  },

  components: {
    CashbookCreateEntryDialog
  },

  data: () => {
    return {
      loading: false,
      dialog: false,
      showCreateEntryDialog: false,
      items: null,
      itemTaxSelect: [],
      accounts: null,
      cashierIDs: null,
    }
  },

  mixins: [mixin],

  computed: {
    ...mapState([
      'pos'
    ]),
    orderedItems() {
      if (this.posType === "retail") {
        return this.pos.retail.orderedItems;
      }

      return [];
    }
  },

  methods: {
    open() {
      this.showCreateEntryDialog = true;

      this.loadData();
    },
    loadData(filterEntries = false) {
      this.loading = true;

      //CHECK IF SHOULD FILTER ENTRIES
      let getPayload = {};

      if (filterEntries) {
        getPayload = {
          action: "filter",
          cashBookCashierID: this.cashierIDsVal,
          cashBookPeriod: this.periodVal,
        };
      }

      this.axios.post(ENDPOINTS.ACCOUNTING.CASHBOOK.GET, getPayload).then((result) => {
        let data = result.data;
        let items = [];
        let cashierIDsOptions = [];
        let periodOptions = [];
        let accountOptions = [];


        data.tax.forEach((row) => {
          this.itemTaxSelect.push({name: row[1], tax: parseFloat(row[0])});
        });

        if (data.cashierIDs && data.cashierIDs != null && data.cashierIDs.length > 0) {
          for (let i = 0; i < data.cashierIDs.length; i++) {
            let item = data.cashierIDs[i];
            cashierIDsOptions.push({text: item[1], value: item[0]});
          }
        }
        this.cashierIDs = cashierIDsOptions;
        if (data.cashBookPeriod && data.cashBookPeriod != null && data.cashBookPeriod.length > 0) {
          for (let i = 0; i < data.cashBookPeriod.length; i++) {
            let item = data.cashBookPeriod[i];
            periodOptions.push({text: item[1], value: item[0]});
          }
        }
        this.period = periodOptions;

        if (data.accounts && data.accounts != null && data.accounts.length > 0) {
          for (let i = 0; i < data.accounts.length; i++) {
            let item = data.accounts[i];
            accountOptions.push({text: item[1], value: item[0]});
          }
        }
        this.accounts = accountOptions;

        this.startCredit = data.formfillData.additionalText.startBarbestand;
        this.endCredit = data.formfillData.additionalText.lfdBarbestand;
        this.periodVal = data.formfillData.additionalText.cashBookActivePeriod;
        this.cashbookActiveMonth = data.formfillData.additionalText.cashBookMonth;

        let itemOptions = [];
        if (data.cashBookEntriesArr && data.cashBookEntriesArr != null && data.cashBookEntriesArr.length > 0) {
          for (let i = 0; i < data.cashBookEntriesArr.length; i++) {
            let item = data.cashBookEntriesArr[i];
            itemOptions.push({
              ENTRYUUID: item[0],
              ENTRYID: item[1],
              BUCHUNGSDATUM: item[2],
              BELEGDATUM: item[3],
              KONTO: item[4],
              EINNAHME: item[5],
              AUSGABE: item[6],
              currentCash: item[7],
              BELEGNR: item[8],
              STEUER: item[9],
              CLOSED: item[10]
            });
          }
        }

        this.items = itemOptions;
        this.loading = false;
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });

        this.loading = false;
      });
    },
    closeCreateEntryDialog() {
      this.showCreateEntryDialog = false;
      this.loadData();
    },
    closeDialog() {
      this.dialog = false;

      this.$root.$emit("barcodeScanner.unpause");
    },

  }
}
</script>

<style scoped>
.btn-icon-vertical .btn-icon-wrapper {
  font-size: 42px;
}

@media (min-width: 900px) {
  .btn-icon-vertical {
    padding: 0 !important;
  }
}
@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>