<template>
  <div class="ma-1">
    <v-row no-gutters style="height: 70px;">
      <v-card v-for="(user,key) in allUsers" :key="user.userID"
              :class="{'bg-success':(activeUser.userID===user.userID),'bg-danger':!(activeUser.userID===user.userID),'mx-1':true,'user-block':true}"
              elevation="0">
        <swipe-list
            v-if="isUser(key)"
            ref="list"
            :items="[user]"
            item-key="userID"
            class="card"

        >
          <template v-slot="{item}">
            <!-- item is the corresponding object from the array -->
            <!-- index is clearly the index -->
            <!-- revealLeft is method which toggles the left side -->
            <!-- revealRight is method which toggles the right side -->
            <!-- close is method which closes an opened side -->
            <v-card @click="setUser(item.userID)" elevation="0">
              <v-row no-gutters justify="space-between" align="center">
                <v-col cols="4" class="pa-2">
                  <v-avatar>
                    <img
                        :src="item.avatarURI"
                    />
                  </v-avatar>
                </v-col>
                <v-col cols="">
                                    <span>
                                        {{ item.name }} {{ item.last_name }}
                                    </span>
                </v-col>

              </v-row>
            </v-card>
          </template>

          <template v-slot:right="{item}">
            <v-card style="height: 100%;width:100px;" class="d-flex justify-center bg-danger" elevation="0" :tile="true"
                    @click="removeUser(item.userID)">
              <!-- place icon here or what ever you want -->
              <v-icon size="25" class="align-center text-center white--text">
                mdi-minus
              </v-icon>
            </v-card>
          </template>

        </swipe-list>
      </v-card>
      <v-card v-if="allUsers.length<max" class="bg-info d-flex justify-center" @click="openDialog" min-width="20%">
        <v-icon size="25" class="white--text align-center text-center ">
          mdi-plus
        </v-icon>
      </v-card>
    </v-row>
    <div id="onScreenKeyboardDiv">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet" :input="touchKeyboard.input"
                          :layout="touchKeyboard.layout" :options="touchKeyboard.options"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
    <v-dialog persistent v-model="dialog" min-width="300" width="500" max-width="100%">
      <v-card>
        <v-card-title>
          {{ $t('generic.lang_addUser') }}
        </v-card-title>
        <v-card-text>
          <v-form ref="loginForm" v-model="valid">
            <v-container>
              <v-row style="margin-top: -20px;">
                <v-col cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                      :disabled="loading"
                      :label="$t('settings.lang_userid')"
                      :loading="loading"
                      @focus="showTouchKeyboard"
                      type="number"
                      min="0"
                      v-model="userID"
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NORMAL"
                      :disabled="loading"
                      :error="errors.pw.error"
                      :error-messages="errors.pw.msg"
                      :label="$t('generic.lang_password')"
                      :loading="loading"
                      @focus="showTouchKeyboard"
                      type="password"
                      v-model="pw"
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                      :disabled="loading"
                      :label="$t('settings.lang_userToken') + 'ID'"
                      :loading="loading"
                      @focus="showTouchKeyboard"
                      type="text"
                      v-model="token"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center" no-gutters>
            <v-btn large color="red" class="white--text" @click="closeDialog" :loading="loading">
              {{ $t('generic.lang_cancel') }}
            </v-btn>
            <v-btn large color="primary" :disabled="loading || userID === '' || pw.length<3 " :loading="loading"
                   @click="addUser">
              {{ $t('generic.lang_login') }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="loadingLogin" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <p class="white--text text-center" style="margin-bottom: 5px;">
                  {{ this.$t('datatables.lang_Processing') }}</p>

                <v-progress-linear
                    indeterminate
                    color="white"
                    class="mb-0"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import {mapGetters} from "vuex"
import {SwipeList} from 'vue-swipe-actions';
import {Events} from '../../plugins/events';
import sha512 from "js-sha512";
import mixin from "../../mixins/KeyboardMixIns";
import "vue-swipe-actions/dist/vue-swipe-actions.min.css"

export default {
  name: "MultiUserComponent",
  components: {SwipeList},
  mixins: [
    mixin
  ],
  data() {
    return {
      dialog: false,
      max: 7,
      apiAuthToken: "",
      valid: false,
      loadingLogin: false,
      loading: false,
      timer: null,
      userID: "",
      pw: "",
      errors: {
        user: "",
        pw: {
          error: false,
          msg: "",
        },
      },
      token: "",
    }
  },
  watch: {},
  computed: {
    ...mapGetters({
      activeUser: 'multiUser/activeUser',
      allUsers: 'multiUser/allUsers',
      parentUser: 'user/user',
    }),
  },
  methods: {
    addToToken(e) {
      this.token += String.fromCharCode(e.keyCode);

      if (e.which === 13) {
        if (this.dialog) {
          if (!!this.password && !!this.userID) {
            e.preventDefault();
            this.login(2);
          }
        }
      }
    },
    setUser(id) {
      for (let i = 0, l = this.allUsers.length; i < l; i++) {
        if (this.allUsers[i].userID === parseInt(id)) {
          let selected = this.allUsers[i];
          this.$store.commit('multiUser/setActiveUser', {
            userID: selected.userID,
            name: selected.name,
            last_name: selected.last_name,
            avatarURI: selected.avatarURI,
          });
          break;
        }
      }
    },
    userExist(id) {
      let user = this.allUsers.some(el => el.userID === parseInt(id))
      return user;
    },
    isUser(index) {
      return (index < this.allUsers.length);
    },
    login(mode) {
      this.loading = true;
      this.loadingLogin = true;
      let data = {};
      let key = "";
      key = sha512(this.pw);
      if (mode === 1) {
        data = {
          user_id: parseInt(this.userID),
          password: key,
        }
      } else {
        data = {
          tokenID: this.token,
        }
      }


      this.axios.post('get/checkCredentials/', data).then((res) => {
        this.loading = false;
        this.loadingLogin = false;
        if (res.data.status === "SUCCESS") {
          let id = parseInt(this.userID);
          if (!this.userExist(id)) {
            this.$store.commit('multiUser/addUser', {
              userID: id,
              name: res.data.preName,
              last_name: res.data.lastName,
              avatarURI: res.data.avatar
            });
          }
        } else if (res.data.status === "ERROR") {
          this.errors.pw.error = true;
          this.errors.pw.msg = res.data.msg;
        } else {
          Events.$emit("showSnackbar", {
            color: "error",
            message: this.$t('generic.lang_errorOccurred')
          });
        }
        this.closeDialog();
        this.clearDialog();

      }).catch((err) => {

        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_errorOccurred')
        });

        this.loadingLogin = false;
        this.loading = false;
      })

    },
    openDialog() {
      this.dialog = true;
      const self = this;
      window.addEventListener("keypress", self.addToToken);
      this.timer = setInterval(() => {
        self.token = ""
      }, 5000);
    },
    closeDialog() {
      this.dialog = false;
      window.removeEventListener('keypress', this.addToToken);
      clearInterval(this.timer);
    },
    addUser() {
      if (!this.$refs.loginForm.validate()) {
        return;
      }

      if (this.userExist(this.userID)) {
        Events.$emit("showSnackbar", {
          color: "warning",
          message: this.$t('generic.lang_userAlreadyLoggedIn')
        });
        this.clearDialog()
        return;
      }
      this.login(1)

    },
    clearDialog() {
      this.userID = "";
      this.pw = "";
      this.token = "";
      this.errors.pw.error = false;
      this.errors.pw.msg = "";
    },
    removeUser(id) {
      if (this.userExist(id)) {
        if (this.activeUser.userID === id) {
          this.$store.commit('multiUser/clearActiveUser');
        }
        this.$store.commit('multiUser/removeUser', id);
      }
    },
    itemClick() {
      this.$refs.list.closeActions();
    }
  },
  mounted() {
    //window.addEventListener("keypress", this.addToToken);

  },
  destroyed() {
    //window.removeEventListener('keypress', this.addToToken);
  }
}
</script>

<style scoped>
.user-block {
  width: 20%;
}
</style>