<template>
  <div>
    <button @click="openDialog"
            class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-primary">
      <i class="btn-icon-wrapper" style="display:block !important;">
        <img class="ma-auto" width="60%"
             src="@/assets/images/pos-buttons/kiel-gutschein.svg"/>
        <!--        <font-awesome-icon :icon="['fal', 'gift-card']"/>-->
      </i><!--<span v-if="showButtonText">Gutschein-karte</span>-->
    </button>

    <buttons-zmyle-dialog v-if="$store.getters['permissions/checkModule'](58)" :pos-type="posType"
                          :show-dialog="showDialog" @closeDialog="showDialog = false"></buttons-zmyle-dialog>

  </div>
</template>

<script>
import ButtonsZmyleDialog from "@/components/pos/buttons/ButtonsZmyleDialog";


export default {
  name: "ButtonsZmyle",

  props: {
    posType: String,
    showButtonText: Boolean
  },

  components: {
    ButtonsZmyleDialog,
  },

  data() {
    return {
      showDialog: false
    }
  },
  methods: {
    openDialog() {
      this.showDialog = true;
    }
  }
}
</script>

<style scoped>
@media (min-width: 900px) {
  .btn-icon-vertical {
    padding: 0 !important;
  }
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>
