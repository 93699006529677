<template>
  <v-dialog v-model="dialogOpen" max-width="600px" scrollable>
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('customers.lang_create_NewCust') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider class="ma-0"/>

      <v-tabs dark>
        <v-tab :disabled="step === 2">{{ $t('customers.lang_cust_create') }}</v-tab>
      </v-tabs>

      <v-card-text v-if="step === 1" style="margin-top: 30px;">
        <v-form ref="createCustomerForm" v-model="valid" lazy-validation>
          <v-container>
            <v-row>
              <!-- Vorname -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="firstName"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('customers.lang_cust_prename')"
                              :loading="loading"
                              :rules="firstNameRules"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <!-- Nachname -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="lastName"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('customers.lang_cust_name')"
                              :loading="loading"
                              :rules="lastNameRules"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <!-- Company -->
              <v-col cols="12" >
                <v-text-field v-model="company"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_company')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="street"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_street')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>

              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="streetno"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('customers.lang_number')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="zip"
                              :data-layout="KEYBOARD.KEYSETS.NUMERIC"
                              :disabled="loading"
                              :label="$t('customers.lang_cust_zip')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="city"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('settings.lang_city')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="text"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
              <!-- E-Mail -->
              <v-col cols="12" sm="12" md="6">
                <v-text-field v-model="email"
                              :data-layout="KEYBOARD.KEYSETS.NORMAL"
                              :disabled="loading"
                              :label="$t('generic.lang_emailAdresse')"
                              :loading="loading"
                              dense
                              outlined
                              required
                              type="email"
                              @focus="showTouchKeyboard"
                              @keyup.enter="createCustomer"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>

      <v-divider class="ma-0"/>

      <v-card-actions>
        <v-btn color="error" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer/>
        <v-btn :disabled="loading || !valid" :loading="loading" :class="$vuetify.breakpoint.smAndDown ? 'text-white mx-auto' : ''" :color="($vuetify.breakpoint.smAndDown ? colors.primary : 'success')" :rounded="$vuetify.breakpoint.smAndDown" @click="proceed">
          {{ $t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDivCreateCustomer" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboardCreateCustomer.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboardCreateCustomer"
                            :cancel="hideTouchKeyboardCreateCustomer"
                            :defaultKeySet="touchKeyboardCreateCustomer.keySet"
                            :input="touchKeyboardCreateCustomer.input" :layout="touchKeyboardCreateCustomer.layout"
                            :options="touchKeyboardCreateCustomer.options" class="internalWidthExpanded"/>
      </div>

    </v-card>
  </v-dialog>
</template>

<script>
import {ENDPOINTS, KEYBOARD} from "../../config";
import {mapState} from "vuex";

export default {
  name: "CustomerCreateDialog",
  props: {
    showDialog: Boolean,
    posType: String,
    colors: Object
  },

  data() {
    return {
      dialogOpen: false,
      loading: false,
      step: 1,
      valid: true,
      firstName: "",
      firstNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      lastName: "",
      lastNameRules: [
        v => !!v || this.$t('generic.lang_requiredField'),
      ],
      email: "",
      company: "",
      street: "",
      streetno: "",
      zip: "",
      city: "",
      KEYBOARD,
      touchKeyboardCreateCustomer: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
      createdCustomerID: 0,
      customerGroups:[],
      customerGroupID:0,
    }
  },

  computed: {
    ...mapState([
      'touchkeyboard'
    ]),
  },

  watch: {
    showDialog(val) {
      this.dialogOpen = val;
    },
    dialogOpen(val) {
      if (!val) {
        this.closeDialog();
      }
    }
  },

  mounted() {
    this.dialogOpen = this.showDialog;
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardCreateCustomer);
  },

  destroyed() {
    clearInterval(this.clearIntervalID);

    document.removeEventListener('click', this.dismissOnScreenKeyboardCreateCustomer);
  },

  methods: {
    showTouchKeyboard(e) {
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardCreateCustomer.input = e.target;
      this.touchKeyboardCreateCustomer.layout = e.target.dataset.layout;

      if (!this.touchKeyboardCreateCustomer.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardCreateCustomer.visible = true;
        }, 150);
      }
    },
    hideTouchKeyboardCreateCustomer() {
      if (this.touchKeyboardCreateCustomer.visible) {
        this.touchKeyboardCreateCustomer.visible = false;
      }
    },
    dismissOnScreenKeyboardCreateCustomer: function (e) {
      if (this.touchkeyboard.settings.enabled && document.getElementById('onScreenKeyboardDivCreateCustomer')) {
        if (!document.getElementById('onScreenKeyboardDivCreateCustomer').contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hideTouchKeyboardCreateCustomer();
          }
        }
      }
    },
    closeDialog() {
      this.$emit("closeDialog");
      this.resetData();
    },
    resetData() {
      this.loading = false;
      this.email = "";
      this.firstName = "";
      this.lastName = "";
      this.street = "";
      this.streetno = "";
      this.zip = "";
      this.city = "";
      this.company = "";

      this.step = 1;
    },
    createCustomer() {
      if (!this.$refs.createCustomerForm.validate()) {
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMER.CREATE, {
        first_name: this.firstName,
        last_name: this.lastName,
        customerGroup: this.customerGroupID,
        email: this.email,
        street: this.street,
        street_no: this.streetno,
        zip: this.zip,
        city: this.city,
        company: this.company,
      }).then((res) => {
        this.loading = false;

        if (res.data.success) {
          this.createdCustomerID = res.data.customerID;
          this.$emit("customerCreated", this.createdCustomerID);
        }
      }).catch(() => {
        this.loading = false;
      });
    },
    getCustomerGroups(){
      this.axios.post(ENDPOINTS.CUSTOMERS.CUSTOMERGROUPS.GETALL,{

      }).then(res=>{
        if(res.data.success){
          this.customerGroups=res.data.data
        }
      }).catch(err=>{

      }).finally(()=>{

      })
    },
    proceed() {
      if(this.step === 1)
        this.createCustomer();
    },
  }
}
</script>