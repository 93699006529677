<template>
  <v-dialog v-model="showDialog" persistent scrollable max-width="400">
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>{{ $t('erp.lang_voucher_add') }}</v-toolbar-title>

        <v-spacer/>

        <v-btn icon @click="closeDialog">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider class="ma-0"/>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="voucherValue" :rules="[rules.required]" outlined filled
                            :label="$t('erp.lang_VoucherValue')" type="number"></v-text-field>

              <NumpadOnly ref="numpad" @updateValue="numpadUpdateValue" @clickCustomButton="numpadClickedCustomButton"
                          @resetField="numpadResetField" :custom-buttons="customButtons"></NumpadOnly>
            </v-col>
            <v-col cols="12">
              <v-textarea outlined filled v-model="freeText" :label="$t('generic.lang_Freitext')"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-btn color="blue darken-1" text @click="closeDialog">{{ $t('generic.lang_cancel') }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click="createVoucher" :disabled="voucherValue === ''">{{ $t('erp.lang_voucher_add') }}
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>

</template>

<script>
import randomString from "randomstring";
import NumpadOnly from "../NumpadOnly";
import {Events} from "@/plugins/events";
import validation from "../../../mixins/validation/validation";


export default {
  name: "ButtonsVoucherDialog",

  props: {
    showDialog: Boolean,
    posType: String
  },
  mixins:[validation],
  components: {
    NumpadOnly
  },
  data() {
    return {
      customButtons: [
        {
          name: '10euro',
          text: '10'+this.$store.getters['settings/currencyData']('symbol'),
          value: 10
        },
        {
          name: '25euro',
          text: '25'+this.$store.getters['settings/currencyData']('symbol'),
          value: 25
        },
        {
          name: '50euro',
          text: '50'+this.$store.getters['settings/currencyData']('symbol'),
          value: 50
        }
      ],
      voucherValue: "",
      freeText: null,
    }
  },

  watch: {
    showDialog(val) {
      if (!val) {
        this.voucherValue = "";

        //CLEAR NUMPAD
        this.$refs.numpad.resetField();
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause')
      } else {
        this.$root.$emit('barcodeScanner.unpause')
      }
    }
  },

  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    numpadUpdateValue(value) {
      this.voucherValue = value;
    },
    numpadClickedCustomButton(customButton) {
      this.voucherValue = customButton.value;
    },
    numpadResetField() {
      this.voucherValue = "";
    },
    createVoucher() {
      //SET OUR NEW VALUES ON ITEM
      if (parseFloat(this.voucherValue.toString().replace(',', '.')) <= 0) {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_sorryNoBalance'),
          color: "warning"
        });
        this.voucherValue = "";
        this.closeDialog();
        return
      }
      let item = {
        id: "VOUCHER_" + randomString.generate(15),
        ean: "V$newVoucher",
        name: "Gutschein " + this.$options.filters.currency(Number(this.voucherValue.toString().replace(',', '.'))),
        originalSellPrice: parseFloat(this.voucherValue.toString().replace(',', '.')),
        sellPrice: parseFloat(this.voucherValue.toString().replace(',', '.')),
        selectedExtras: [],
        mealSizeID: 0,
        weight: null,
        taxValue: 0,
        depositgroupID: 0,
        discount: 0,
        warenameOverwrite: null,
        individualBookingNo: null
      };

      if (this.posType === "gastro") {
        this.$store.dispatch("pos/gastro/bookItem", item).then(() => {
          this.voucherValue = "";

          this.closeDialog();
        });
      } else if (this.posType === "retail") {
        this.$store.dispatch("pos/retail/bookItem", item).then(() => {
          this.voucherValue = "";

          this.closeDialog();
        });
      }
    }
  }
}
</script>
