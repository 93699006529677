<template>
    <v-layout wrap>
        <v-card color="#FAFBFC" class="flex" flat>
            <v-card-text style="padding: 0 !important;">
                <v-container fluid style="padding: 0 !important;">
                  <v-row wrap no-gutters>
                    <v-col cols="4"
                           sm="4"
                           md="4"
                           xs="4">
                      <button @click.stop="drawer = !drawer" class="btn-icon-vertical btn-square btn-transition btn btn-outline-dark active"
                               style="text-transform: uppercase;height: 75px;width:100%;">
                                            <span>
                                                <font-awesome-icon icon="receipt" size="2x"/>
                                              <br>
                                               {{countOrderedItems}} / {{countOpenItems}}
                                            </span>
                      </button>
                    </v-col>
                    <v-col cols="4"
                           sm="4"
                           md="4"
                           xs="4">
                      <button  class="btn-icon-vertical btn-square btn-transition btn btn-outline-info active"
                               style="text-transform: uppercase;height: 75px;width:100%;">
                                            <span>
                                                <font-awesome-icon icon="users" size="2x"/>
                                                <br>
                                            </span>
                      </button>
                    </v-col>
                    <v-col cols="4"
                           sm="4"
                           md="4"
                           xs="4">
                      <v-container fill-height fluid style="padding-bottom:0px !important;padding-left:0px !important;padding-right:0px !important;">
                        <v-layout>
                          <v-flex class="text-xs-right">
                            <p class="totalPrice">{{ $t('generic.lang_total') }}: {{ totalPrice | currency }}</p>
                            <p class="totalPrice">{{ $t('generic.lang_party') }}: {{ totalPrice | currency }}</p>
                          </v-flex>
                        </v-layout>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>
            </v-card-text>
        </v-card>





      <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
          style="min-height:600px;"
      >
        <Invoice :pos-type="posType"/>
      </v-navigation-drawer>



        <CashoutDialog :show-dialog="showCashoutDialog" :pos-type="posType"
                       @closeDialog="showCashoutDialog = false"/>

    </v-layout>
</template>

<style scoped>
    .totalPrice {
        font-family: ANTON;
        color: #D92550;
        letter-spacing: 1px;
        text-align: center;
        margin-bottom:5px !important;
    }

    @media (max-width: 1024px) {
        .totalPrice {
            font-size: 12px;
        }
    }

    @media (min-width: 1025px) {
       .totalPrice {
           font-size: 23px;
       }
    }
</style>

<script>

    import {library} from '@fortawesome/fontawesome-svg-core'
    import {ENDPOINTS} from "../../config";

    import {
        faReceipt,
        faUsers,
        faEuroSign
    } from '@fortawesome/free-solid-svg-icons'

    import {
        faConciergeBell
    } from '@fortawesome/pro-light-svg-icons'

    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

    library.add(
        faReceipt,
        faEuroSign,
        faUsers,
        faConciergeBell
    );
    import Invoice from "../../components/pos/Invoice";
    import CashoutDialog from "./cashout/CashoutDialog";
    import {mapGetters, mapState} from "vuex";

    export default {
        name: "Footer",

        props: {
            posType: String
        },

        components: {
            CashoutDialog,
            Invoice,
            'font-awesome-icon': FontAwesomeIcon
        },

        data() {
            return {
                showCashoutDialog: false,
                showPartyAddDialog: false,
                table: {},
                drawer: false,
            }
        },

        computed: {
            ...mapGetters({
                gastroTotalPrice: 'pos/gastro/totalPrice',
                countOrderedItems: 'pos/gastro/countOrderedItems',
                countOpenItems: 'pos/gastro/countOpenItems',
                gastroTable:'pos/gastro/tableName',
                retailTotalPrice: 'pos/retail/totalPrice',
                user:'user/user',
            }),
            ...mapState({
                pos: 'pos'
            }),
            showOrderButton() {
                if(this.posType !== "gastro") {
                    return false;
                }

                if(this.pos.gastro.table === null) {
                    return true;
                }

                if(this.pos.gastro.table.name !== 0) {
                    return true;
                }

                return false;
            },
            showCashoutButton() {
                if(this.posType === "retail") {
                    if(this.pos.retail.orderedItems.length > 0) {
                        return true;
                    }
                }

                if(this.posType === "gastro") {
                    if(this.pos.gastro.table !== null) {
                        if(this.pos.gastro.table.name === 0) {
                            return true;
                        }
                        else {
                            if(this.pos.gastro.party !== null) {
                                if(this.pos.gastro.orderedItems.hasOwnProperty(this.pos.gastro.party.name)) {
                                    if(this.pos.gastro.orderedItems[this.pos.gastro.party.name].length > 0 ) {
                                        //return (this.table.soldBy === this.user.userID);
                                        return true;
                                    }
                                }
                            }
                        }
                    }
                }

                return false;
            },
            totalPrice() {
                if(this.posType === "gastro") {
                    return this.gastroTotalPrice;
                }

                if(this.posType === "retail") {
                    return this.retailTotalPrice;
                }

                return 0;
            },

        },

        methods: {
            paginateDown() {
                this.$root.$emit("pos." + this.posType + ".paginateDown");
            },
            paginateUp() {
                this.$root.$emit("pos." + this.posType + ".paginateUp");
            },
            getTableOrders(){
                if(this.posType==="gastro"){
                    if(this.gastroTable!==null){
                        if(this.gastroTable!==0){
                            this.axios.post(ENDPOINTS.POS.GASTRO.TABLES.GET).then((res) => {
                                if(res.status===200) {
                                    this.table = this.getTable(this.gastroTable,res.data.tables);
                                }
                            }).catch((err) => {

                            });
                        }
                    }

                }
            },
            getTable(name,from){
                for(let i=0,l=from.length;i<l;i++){
                    if(from[i].name.toString()===name.toString()){
                        return from[i];
                    }
                }
                return null;
            }
        },

        mounted() {
            this.getTableOrders();
        }
    }
</script>
