/
<template>
  <div>
    <button @click="openDialog" class="btn-icon-vertical btn-square  br-tr btn-transition btn btn-outline-focus">
      <i class="btn-icon-wrapper">
        <font-awesome-icon :icon="['fal', 'inbox-out']"/>
      </i>
      <span v-if="showButtonText">{{ $t('erp.lang_open_order') }}</span>
    </button>

    <ButtonsOpenSavedOrderDialog :pos-type="posType" :show-dialog="showDialog"
                                 @closeDialog="showDialog = false"></ButtonsOpenSavedOrderDialog>
  </div>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {faInboxOut} from '@fortawesome/pro-light-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import {mapState} from "vuex";

import ButtonsOpenSavedOrderDialog from "./ButtonsOpenSavedOrderDialog";

library.add(
    faInboxOut
);

export default {
  name: "ButtonsOpenSavedOrder",

  props: {
    posType: String,
    showButtonText: Boolean
  },

  components: {
    ButtonsOpenSavedOrderDialog,
    'font-awesome-icon': FontAwesomeIcon
  },

  data() {
    return {
      showDialog: false
    }
  },

  computed: {
    ...mapState([
      'pos'
    ])
  },

  methods: {
    openDialog() {
      this.showDialog = true;
    }
  }
}
</script>

<style scoped>
.btn-icon-vertical {
  padding: 0 !important;
}

@media only screen and (max-height: 850px) {
  .btn-icon-vertical .btn-icon-wrapper {
    font-size: 150% !important;
  }
}
</style>