<template>
  <div>
    <button class='btn-icon-vertical btn-square pa-0 br-tr border-right btn-transition btn btn-outline-primary' @click="dialog=true">
      <i class="btn-icon-wrapper">
        <font-awesome-icon style="font-size: 25px" :icon="['fad','map-marker-edit']"/>
      </i>
    </button>
    <v-dialog v-model="dialog" persistent max-width="60%" no-click-animation>
      <v-card>
        <v-toolbar dense flat>
          <v-toolbar-title>{{ $t('generic.lang_zipCodePicker') }}</v-toolbar-title>

          <v-spacer/>

          <v-btn icon @click="dialog=false">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider class="ma-0"/>
        <v-card-text class="overflow-auto" style="height: 350px;">
          <v-container fluid class="px-0">
            <v-row justify="center">
              <v-col cols="10">
                <v-menu v-if="dialog"
                    v-model="focus"
                    :close-on-content-click="false"
                    :nudge-right="0"
                    transition="scroll-y-transition"
                    offset-y style="width:100%;"
                    bottom nudge-top="10"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="search"
                        v-on="on"
                        outlined
                        dense
                        prepend-inner-icon="mdi-map-marker-radius-outline"
                        :data-layout="KEYBOARD.KEYSETS.NORMAL"
                        @blur="focus=false;"
                        :loading="loading"
                        aria-autocomplete="none"
                        @focus="showTouchKeyboard" >
                    </v-text-field>
                  </template>
                  <v-card max-height="300" width="100%" max-width="100%">
                    <v-list>
                      <template v-if="zipCodes && zipCodes.length>0">
                        <v-list-item v-for="(zipCode,i)  in zipCodes" :key="i" @click="selectDeliveryArea(zipCode)" two-line>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{zipCode.zipCode}}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ $t('generic.lang_min') }}:{{zipCode.minOrder|currency}}/ {{ $t('generic.lang_cost') }}:{{zipCode.deliveryCost|currency}}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle>
                              <v-icon>mdi-car</v-icon>{{zipCode.carDeliveryTime }}{{ $t('generic.lang_min') }}, <v-icon>mdi-moped</v-icon>{{zipCode.motorcycleDeliveryTime }}{{ $t('generic.lang_min') }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                        </v-list-item>
                      </template>

                      <template v-else>
                        <v-list-item >
                          <v-list-item-content>
                            {{ $t('generic.lang_no_data') }}
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                    </v-list>
                  </v-card>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider/>
        <v-card-actions>
          <v-spacer/>
          <v-btn color="success" @click="setDeliveryInfo()" :disabled="!selectedDeliveryArea||(selectedDeliveryArea&&selectedDeliveryArea.zipCode!==search)">
            {{ $t('generic.lang_select') }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <div ref="onScreenKeyboardDivZipCode" style="z-index: 299 !important;">
        <vue-touch-keyboard v-if="touchKeyboardZipCode.visible" id="onScreenKeyboard"
                            :accept="hidetouchKeyboardZipCode"
                            :cancel="hidetouchKeyboardZipCode" :defaultKeySet="touchKeyboardZipCode.keySet"
                            :input="touchKeyboardZipCode.input" :layout="touchKeyboardZipCode.layout"
                            :options="touchKeyboardZipCode.options" class="internalWidthExpanded"/>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome"
import {library} from "@fortawesome/fontawesome-svg-core"
import {
  faMapMarkerEdit
} from "@fortawesome/pro-duotone-svg-icons";


library.add(faMapMarkerEdit)

import {mapState,mapGetters} from "vuex"
import {ENDPOINTS, KEYBOARD} from "@/config";
import randomString from "randomstring";

export default {
  name: "ButtonsZipCode",
  components:{
    'font-awesome-icon':FontAwesomeIcon,
  },
  data(){
    return{
      search:'',
      dialog:false,
      focus:false,
      loading:false,
      awaitingSearch:null,
      zipCodes:null,
      selectedDeliveryArea:null,
      KEYBOARD,
      touchKeyboardZipCode: {
        visible: false,
        layout: "normal",
        input: null,
        options: {
          useKbEvents: false,
          preventClickEvent: false
        }
      },
      clearIntervalID: null,
    }
  },
  watch:{
    dialog(val) {
      //SET ITEM FREETEXT IF AVAILABLE
      if (val) {
        document.addEventListener('click', this.dismissOnScreenKeyboardZipCode);
        this.search=this.deliveryInfo?this.deliveryInfo.zipCode:(this.pos.gastro.customer?this.pos.gastro.customer.zip:(this.$route.query.hasOwnProperty('hasMultipleArea')&&this.$route.query.hasMultipleArea)?this.$route.query.hasMultipleArea:'');
        this.searchZipCode()
      } else {
        clearInterval(this.clearIntervalID);
        document.removeEventListener('click', this.dismissOnScreenKeyboardZipCode);
        this.focus=false;
      }

      //CHECK IF WE SHOULD PAUSE BARCODE SCANNER
      if (val) {
        this.$root.$emit('barcodeScanner.pause');

      } else {
        this.focus=false;
        this.selectedDeliveryArea=null,
        this.$root.$emit('barcodeScanner.unpause');
      }
    },
    search: function (val) {
      clearTimeout(this.awaitingSearch);

      this.awaitingSearch = setTimeout(() => {
          this.searchZipCode();
      }, 500);
    },
  },
  computed:{
    ...mapState([
      'touchkeyboard',
      'pos'
    ]),
    ...mapGetters({
      delivery:'pos/gastro/getDelivery',
      deliveryInfo:'pos/gastro/getDeliveryInfo',
    })
  },
  methods:{
    showTouchKeyboard(e) {
      this.focus=true;
      if (!this.touchkeyboard.settings.enabled)
        return;

      this.touchKeyboardZipCode.input = e.target;
      this.touchKeyboardZipCode.layout = e.target.dataset.layout;

      if (!this.touchKeyboardZipCode.visible) {
        //TIMEOUT BECAUSE OUR OUTSIDE CLICK LISTENER IS CLOSING THE WINDOW INSTEAD
        this.clearIntervalID = setTimeout(() => {
          this.touchKeyboardZipCode.visible = true;
        }, 150);
      }
    },
    hidetouchKeyboardZipCode() {

      this.focus=false;
      if (this.touchKeyboardZipCode.visible) {
        this.touchKeyboardZipCode.visible = false;
      }
    },
    dismissOnScreenKeyboardZipCode: function (e) {
      if (this.touchkeyboard.settings.enabled && this.$refs.onScreenKeyboardDivZipCode) {
        if (!this.$refs.onScreenKeyboardDivZipCode.contains(e.target)) {
          if (e.target.tagName !== "INPUT") {
            this.hidetouchKeyboardZipCode();
          }
        }
      }
    },
    searchZipCode(){
      this.loading = true;
      this.zipCodes=[];
      this.axios.post(ENDPOINTS.DELIVERY.SETTINGS.DELIVERYAREA.SEARCH, {
        zip:this.search
      }).then((res) => {
        if(res.data && res.data.length>0){
          if(res.data && res.data.length>0){
            this.zipCodes=res.data;
          }
        }
        this.focus=true;
      }).catch((err) => {
        console.log(err)
      }).finally(()=>{
        this.loading = false;
      })
    },
    selectDeliveryArea(zipCode){
      this.search=zipCode.zipCode
      this.selectedDeliveryArea=zipCode
      this.focus = false;
    },
    setDeliveryInfo(){
      this.$store.commit('pos/gastro/setDeliveryInfo',this.selectedDeliveryArea);
      this.$store.dispatch('pos/gastro/setDelivery',true);
      this.dialog=false;
    },

  },

  mounted() {
    //LISTEN FOR OUTSIDE CLICK
    document.addEventListener('click', this.dismissOnScreenKeyboardZipCode);
    this.$root.$on('showDeliveryAreaDialog',()=>{
      this.dialog=true;
    })
  },

  destroyed(){
    clearInterval(this.clearIntervalID);
    this.$root.$off('showDeliveryAreaDialog')
    document.removeEventListener('click',this.dismissOnScreenKeyboardZipCode);
  }
}
</script>

<style scoped>

</style>
