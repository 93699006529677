import { render, staticRenderFns } from "./MobileOrderedItems.vue?vue&type=template&id=61660054&scoped=true&"
import script from "./MobileOrderedItems.vue?vue&type=script&lang=js&"
export * from "./MobileOrderedItems.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61660054",
  null
  
)

export default component.exports