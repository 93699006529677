<template>
  <v-dialog max-width="700" v-model="visible">
    <v-card>
      <v-card-title>{{$t('erp.lang_scanSerialNo')}}</v-card-title>

      <v-card-text class="pb-0 mb-0">
        <v-text-field autofocus :disabled="this.loading" :loading="this.loading" @focus="showTouchKeyboard"
                      :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="serialNo"
                      @keypress.enter="checkSerialNo" outlined dense :label="$t('erp.lang_serialnumber')"/>
      </v-card-text>

      <v-card-actions class="pt-0 px-6 mb-0">
        <v-btn large :disabled="this.loading" :loading="this.loading" @click="checkSerialNo" block depressed
               class="mx-auto"
               color="primary">
          {{ this.$t('generic.lang_next') }}
        </v-btn>
      </v-card-actions>

      <div id="onScreenKeyboardDiv">
        <vue-touch-keyboard v-if="touchKeyboard.visible" id="onScreenKeyboard"
                            :accept="hideTouchKeyboard"
                            :cancel="hideTouchKeyboard" :defaultKeySet="touchKeyboard.keySet"
                            :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                            :options="touchKeyboard.options" class="internalWidthExpanded"/>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>


import mixin from "../../../mixins/KeyboardMixIns"
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import {mapState} from "vuex";

export default {
  name: "SerialNoScannerComponent",
  mixins: [mixin],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    item: Object,
    posType: String
  },
  data() {
    return {
      serialNo: "",
      loading: false
    }
  },
  computed: {
    ...mapState(['pos']),
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val)
      }
    },
  },
  watch: {
    visible(val) {
      if (!val) this.serialNo = ""
    }
  },
  methods: {
    checkSerialNo() {

      this.loading = true;

      //check if not already booked
      let openSerialNos = [];
      if (this.posType === "gastro") {

        if (Array.isArray(this.pos.gastro.openItems) && this.pos.gastro.openItems.length > 0)
          openSerialNos = this.pos.gastro.openItems.map((item) => item.serialNo)

        if (Array.isArray(this.pos.gastro.orderedItems) && this.pos.gastro.orderedItems.length > 0)
          openSerialNos = openSerialNos.concat(this.pos.gastro.orderedItems.map((item) => item.serialNo))

      } else if (this.posType === "retail") {
        if (Array.isArray(this.pos.retail.orderedItems) && this.pos.retail.orderedItems.length > 0)
          openSerialNos = openSerialNos.concat(this.pos.retail.orderedItems.map((item) => item.serialNo))
      }


      if (openSerialNos.indexOf(this.serialNo) > -1) {
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_serialnumberAlreadyScanned'),
          color: "error"
        });
        this.loading = false
        this.$emit("failed");
        return;
      }


      this.axios.post(ENDPOINTS.ERP.ITEM.SERIALNUMBERS.CHECK, {
        serialNumber: this.serialNo,
        itemId: this.item.id
      }).then((res) => {
        if (res.data.success === true) {
          this.$emit("success", this.serialNo)

        } else if (res.data.success === false) {

          let errormsgSerialReq = "";
          if(res.data.code == "serial_found_in_order"){
            errormsgSerialReq = this.$t('erp.lang_serialNumberInOtherOrder');
          }else{
            errormsgSerialReq = this.$t('erp.lang_serialNotFound');
          }

          Events.$emit("showSnackbar", {
            message: res.data.code,
            color: "error"
          });
          this.$emit("failed")
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
          this.$emit("failed")
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
        this.$emit("failed");
      }).finally(() => {
        this.loading = false;
      });
    }

  }
}
</script>

<style scoped>

</style>
